import React from 'react'
import { connect } from 'react-redux'
import { RaisedButton, CircularProgress } from 'components'
import { ActionInput } from 'components/icons'
import { Form, TextField } from 'components/form'
import { API_CALL, FIRST_ACCOUNT, FIRST_ACCOUNT_DATA } from 'actions'
/* globals grecaptcha */

const init = {
    email: null,
    errors: null,
    loading: false,
}

export const reducer = (state=init, action) => {
    switch (action.type) {
        case FIRST_ACCOUNT.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FIRST_ACCOUNT.SUCCESS:
            return init
        case FIRST_ACCOUNT.FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.error.message,
            }
        default:
            return state
    }
}

const onSubmit = (body, captcha, history) => ({
    type: FIRST_ACCOUNT.action,
    [API_CALL]: {
        request: {
            url: '/accounts/first',
            body: {
                ...body,
            }
        },
        onsuccess: ({ dispatch, payload }, success) => {
            dispatch({
                type: FIRST_ACCOUNT_DATA,
                account: payload.account,
            })
            history.push(`/login/first/confirm`)
            success()
        },
        onfailure: () =>
            grecaptcha.reset(captcha)
    },
})

const FirstAccountForm = ({ errors, email, loading, onSubmit, history }) =>
    <Form
        errors={errors}
        defaults={email === null ? {} : { email }}
        popup={false}
        captcha
        validated={!loading}
        disabled={loading}
        onSubmit={(data, captcha) => onSubmit(data, captcha, history)}>
        <div className='text-center'>
            Entrez ici l'addresse email qui sera associée à votre compte.
            Un lien de confirmation y sera alors envoyé pour continuer
            le processus d'enregistrement.
        </div>
        <TextField
            type='email'
            fullwidth
            autoComplete='home email'
            name='email'
            check={email =>
                email.length > 0 ? '' : 'Ce champ ne peut être vide'}
            label='Entrez votre adresse email' /><br/>
        <RaisedButton
            disabled={loading}
            startIcon={
                loading ?
                <CircularProgress
                    size={24}
                    mode='indeterminate' /> :
                <ActionInput
                    size={24} />
            }
            fullWidth
            type='submit'
            label='Suivant'
            primary />
    </Form>

const ConnectedFirstAccountForm = connect(
    state => state.login.first.account,
    { onSubmit },
)(FirstAccountForm)

export default ConnectedFirstAccountForm
