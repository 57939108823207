import { API_CALL, ALIAS_ADD } from 'actions'

export const onClose = (history, domain) => () => history.push(`/${domain}`)

export const onAdd = (history, domain, alias) => dispatch => dispatch({
    type: ALIAS_ADD.action,
    [API_CALL]: {
        request: {
            url: `/mails/${domain}/alias`,
            body: {
                ...alias,
            },
        },
        onsuccess: () =>
            history.push(`/${domain}/mail/alias/${alias.from_field}/activate`)
    }
})
