import React from 'react'
import { connect } from 'react-redux'
import { Paper, CircularProgress } from 'components'
import { LOADING_START, LOADING_END } from 'actions'
import css from './loading.module.css'

export const reducer = (state={displayed: false}, action) => {
    switch (action.type) {
        case LOADING_START:
            return {
                ...state,
                displayed: true,
            }
        case LOADING_END:
            return {
                ...state,
                displayed: false,
            }
        default:
            return state;
    }
}

class Loading extends React.Component {
    state={ showing: false }
    timeout=null
    reset= () => {
        !!this.timeout && clearTimeout(this.timeout)
        this.timeout = null
    }

    componentDidUpdate(props) {
        if (props.show !== this.props.show) {
            clearTimeout(this.timeout)
            this.timeout = setTimeout(
                () => {
                    this.timeout = null
                    this.setState({ showing: this.props.show })
                },
                200,
            )
            this.setState({
                showing: !this.props.show
            })
        }
    }

    componentWillUnmount() {
        this.reset()
    }

    render() {
        const { show, children } = this.props
        const { showing } = this.state
        const progress =
            <Paper
                elevation={5}
                id={css['loading-progress']}
                className={
                    show ? '' :
                    css['loading-show'] + (
                        showing ? '' : ' ' + ['loading-hide']
                    )
                }>
                <CircularProgress
                    mode='indeterminate' />
            </Paper>
        return (
            <div>
                {show && progress}
                {!show &&
                <div
                    className={
                        css['loading-show'] +
                        (showing ? ' ' + css['loading-hide'] : '')}>
                    {children}
                </div>}
            </div>
        )
    }
}

export default connect(
    state => ({
        show: state.loading.displayed
    }),
)(Loading)
