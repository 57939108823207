import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Grid, Alert, Paper, LinearProgress } from 'components'
import { useCart, useAccount } from './hooks'
import { CartContent } from './payment'
import { API_CALL, RIB, PAYMENT_CONFIRM, DISPLAY_NOTIFICATION } from 'actions'
import { D, fmt } from './utils'

export const FinalizationComponent = () => {
    const cart = useCart()
    const account = useAccount()
    const [loading, setLoading] = useState(true)
    const [rib, setRib] = useState(null)
    const contact = useSelector(state => state.app.contacts.facturation)
    const dispatch = useDispatch()
    useEffect(
        () => {
            let cancelled = false
            if (cart.due_date) {
                dispatch({
                    type: RIB.action,
                    [API_CALL]: {
                        request: {
                            url: '/billing/payment/rib',
                        },
                        onsuccess: ({ payload }) => {
                            if (!cancelled) {
                                setRib(payload.rib)
                                setLoading(false)
                            }
                        },
                        onfailure: ({ payload }) => {
                            if (!cancelled) {
                                setLoading(false)
                            }
                        }
                    }
                })
            } else if (cart.amount === 0) {
                dispatch({
                    type: PAYMENT_CONFIRM.action,
                    [API_CALL]: {
                        request: {
                            url: '/billing/payment/confirm',
                            body: {id: 'redeem'},
                        },
                        onsuccess: () => {
                            if(!cancelled) {
                                setLoading(false)
                            }
                        },
                        onfailure: ({ payload }) => {
                            if(!cancelled) {
                                dispatch({
                                    type: DISPLAY_NOTIFICATION,
                                    message: payload.message,
                                    severity: 'error',
                                })
                            }
                        },
                    },
                })
            } else {
                setLoading(false)
            }
            return () => { cancelled = true }
        }, [ dispatch, cart.due_date, cart.amount ]
    )
    return (
        loading ?
            <Paper>
                <LinearProgress />
            </Paper> :
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <CartContent
                        title={cart.due_date ? "Votre facture est en attente de paiement" : cart.amount >= 0 ? "Commande validée" : "Vous avez été remboursé"}
                        total={cart.due_date ? "Montant dû" : cart.amount >= 0 ? "Montant facturé" : "Montant de votre avoir"}
                        items={cart.items}
                        amount={cart.amount} />
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Alert>
                            Votre facture {cart.amount < 0 && "d'avoir "}n°<Link to={`/account/bill/${cart.bill}`}>{cart.bill}</Link> est maintenant disponible dans votre compte.
                        </Alert>
                    </Paper>
                    {cart.due_date && (
                        rib !== null ?
                        <Paper>
                            <Alert severity='warning'>
                                Vous avez jusqu'au {D(cart.due_date)} pour effectuer votre virement sur le compte suivant:
                            </Alert>
                            <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    border: 'dashed 3px rgb(0, 0, 0, 0.6)',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    width: 'fit-content',
                                    padding: '10px',
                                }}>
                                <div style={{
                                        display: 'flex',
                                        fontWeight: 'bold',
                                        flexDirection: 'column',
                                        textAlign: 'right',
                                        paddingRight: '1em',
                                    }}>
                                    <div>TITULAIRE</div>
                                    <div>LIBELL&Eacute;</div>
                                    <div>IBAN</div>
                                    <div>BIC</div>
                                </div>
                                <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'left',
                                    }}>
                                    <div>:&nbsp;{rib.owner}</div>
                                    <div>:&nbsp;{cart.bill}</div>
                                    <div>:&nbsp;{rib.iban}</div>
                                    <div>:&nbsp;{rib.bic}</div>
                                </div>
                            </div>
                        </Paper> :
                        <Paper>
                            <Alert severity='warning'>
                                Vous avez jusqu'au {D(cart.due_date)} pour régler votre facture.<br/>
                                Pour obtenir les informations bancaires, contactez le support:
                                <ul>
                                    <li>par mail via <a href={`mailto:${contact.email}`}>{contact.email}</a>,</li>
                                    <li>par téléphone au <a href={`tel:${contact.phone}`}>{contact.phone}</a>.</li>
                                </ul>
                            </Alert>
                        </Paper>
                    )}
                </Grid>
                {!cart.due_date &&
                    <Grid item xs={12}>
                        <Alert>
                            {cart.amount >= 0 ?
                                `Votre date de renouvellement est maintenant définie au ${D(account.renew_date)}` :
                                `Vous bénéficiez à présent d'un avoir total de ${fmt(account.credit / 100)}`}
                        </Alert>
                    </Grid>}
            </Grid>
    )
}

export default FinalizationComponent
