import React, { useState } from 'react'
import { NavigationArrowDown, NavigationChevronRight } from 'components/icons'
import { Popover, FlatButton } from 'components'
import colors from 'components/colors'
import * as apps from '../apps'
import css from './breadcrumb.module.css'

const active = {
    color: colors.grey800,
}

const inactive = {
    color: colors.grey400,
}

const Crumb = ({ name, leaf, crumb, push }) => {
    const { target, setTarget } = useState(null)
    const { open, setOpen } = useState(false)
    return (
        <div>
            <FlatButton
                style={leaf ? active : inactive}
                disabled={leaf && !crumb.menu}
                label={
                    <>
                        <span>{name}</span>
                        {leaf && !!crumb.menu &&
                            <NavigationArrowDown
                                style={{
                                    color: active.color,
                                    marginLeft: '8px',
                                }} />}
                    </>
                }
                onClick={
                    event => {
                        event.preventDefault()
                        event.stopPropagation()
                        if (leaf) {
                            setOpen(true)
                            setTarget(event.currentTarget)
                        } else {
                            push(crumb.path)
                        }
                    }} />
            {leaf && !!crumb.menu &&
                <Popover
                    anchorEl={target}
                    open={open}
                    onClose={() => setOpen(false)}>
                    {React.cloneElement(
                        crumb.menu, {
                            onClose: () =>
                                setOpen(false)})}
                </Popover>}
            {!leaf &&
                <NavigationChevronRight
                    style={{color: inactive.color}} />}
        </div>
    )
}

const walk = (crumb, info, push, display, crumbs=[]) => {
    if (typeof(crumb) !== 'undefined') {
        const leaf = !(crumb.child(info).exists && crumb.child(info).displayed)
        const name =
            typeof(crumb.name) === 'string' ?
            crumb.name : crumb.name(info)
        const that =
            <Crumb
                key={`crumb-${crumbs.length}`}
                display={display}
                name={name}
                leaf={leaf}
                crumb={crumb}
                back={() => push(crumbs[crumbs.length - 1].props.crumb.path)}
                first={crumbs.length === 0}
                push={push} />
        const result = [...crumbs, that ]
        return leaf ?
            result :
            walk(crumb.child(info).crumb, info, push, display, result)
    } else {
        return crumbs
    }
}

class Breadcrumb {
    constructor(props) {
        this.init(props)
    }

    init({ location, params, push }) {
        const app = apps.apps[Object.keys(apps.apps).filter(app =>
            apps.apps[app] !== apps.default &&
            location.pathname.startsWith(apps.apps[app].path)
        )[0]] || apps.default
        this.crumbs = walk(app.crumb, { location, params }, push)
        this.leaf = this.crumbs[this.crumbs.length - 1]
        this.component =
            <div className={css.breadcrumb}>
                {this.crumbs}
            </div>
    }
}

export default Breadcrumb
