import React from 'react'
import { connect } from 'react-redux'
import { Snackbar, Alert } from 'components'
import { DISPLAY_NOTIFICATION, DISMISS_NOTIFICATION } from 'actions'

const Notification = ({open, message, handleClose, severity}) => (
    <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={2000}>
        <Alert
            elevation={6}
            variant='filled'
            style={{whiteSpace: 'pre'}}
            severity={severity}>
            {message}
        </Alert>
    </Snackbar>
)

export function reducer(state={open: false, message: '', severity: 'success'}, action) {
    switch (action.type) {
        case DISPLAY_NOTIFICATION:
            return {
                open: true,
                message: action.message,
                severity: action.severity || "success",
            }
        case DISMISS_NOTIFICATION:
            return {
                ...state,
                open: false,
                message: '',
            }
        default:
            return state
    }
}

export default connect(
    state => state.notification,
    dispatch => ({
        handleClose: () => dispatch({type: DISMISS_NOTIFICATION})
    })
)(Notification)
