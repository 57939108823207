import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import dayjs from 'dayjs'
import { List, ListItem, Subheader, Divider, LinearProgress, makeStyles } from 'components'
import { Button } from 'components'
import { humanSize } from 'components/quota'
import { SocialSentimentSatisfied } from 'components/icons'
import { Loading } from 'containers'
import { QUOTA_REQUEST, API_CALL } from 'actions'
import css from './component.module.css'
import * as add from './add'

const colors = {
    mails: [41, 126, 230],
    clouds: [243, 44, 44],
    websites: [243, 44, 44],
    databases: [251, 209, 41],
    'default': [0, 150, 136],
}

const rgba = (color, alpha=1.0) =>
    `rgba(${[
        ...colors[color] ? colors[color] : colors['default'],
        alpha,
    ].map(x => x.toString()).join()})`

const names = {
    mails: count =>
        `${count} ${count > 1 ? 'boîtes' : 'boîte'} mails`,
    websites: count =>
        `${count} ${count > 1 ? 'sites' : 'site'} web`,
    clouds: count =>
        `${count} ${count > 1 ? 'Comptes' : 'Compte'} FTP`,
    others: count => 'Divers',
    databases: count =>
        `${count} ${count > 1 ? 'Bases' : 'Base'} de données`,
}

const useStyles = makeStyles({
    colorPrimary: {
        backgroundColor: props => props.backgroundColor,
    },
    bar: {
        backgroundColor: props => props.bar,
    },
})

const QuotaBar = ({ used, allocated, total, height, color }) => {
    const bar1 = useStyles({
        backgroundColor: rgba(color, 0.50),
        bar: rgba(color),
    })
    const bar2 = useStyles({
        backgroundColor: rgba(color, 0.30),
    })
    return (
        <div
            style={{display: 'flex'}}>
            <div style={{
                    width: `${(!!allocated ? allocated : used) * 100.0 / total}%`,
                }}>
                <LinearProgress
                    variant='determinate'
                    style={{
                        height: `${height}px`,
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                    }}
                    classes={bar1}
                    value={Math.min(100, used * 100.0 / (!!allocated ? allocated : used))} />
            </div>
            <div
                style={{
                    width: `${
                        (total - (!!allocated ? allocated : used)) * 100.0 / total
                    }%`
                }}>
                <LinearProgress
                    variant='determinate'
                    style={{
                        height: `${height}px`,
                        borderTopLeftRadius: '0px',
                        borderBottomLeftRadius: '0px',
                    }}
                    value={0}
                    classes={bar2} />
            </div>
        </div>
    )
}

const Quotas = ({ quotas, display, children, account, domains, history }) => {
    if (!account || !quotas) return null
    const child = quotas.map((quota, index) => {
        const keys =
            Object.keys(quota.usage).sort((a, b) =>
                quota.usage[b].used - quota.usage[a].used
            ).filter(category =>
                quota.usage[category].used > 0 ||
                quota.usage[category].allocated > 0,
            )
        const total =
            quota.quota ? quota.quota :
            Object.keys(quota.usage).reduce((total, category) => {
                const usage = quota.usage[category]
                return total + (
                    usage.allocated > usage.used ?
                    usage.allocated : usage.used
                )
            }, 0)
        return (
            <List
                key={`quota-${quota.login}`}>
                {quotas.length > 1 &&
                <div>
                    <Subheader>
                        {quota.login}
                    </Subheader>
                    <Divider />
                </div>}
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap-reverse',
                        justifyContent: 'space-between',
                        backgroundColor: 'white',
                    }}>
                    <ListItem
                        component="div"
                        style={{
                            width: 'initial',
                        }}
                        primaryText={
                            quota.quota ?
                            humanSize(quota.quota) + ' disponibles' :
                            <span
                                style={{
                                    display: 'inline-flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                <span>Quota illimité</span>
                                <SocialSentimentSatisfied
                                    style={{marginLeft: '8px'}} />
                            </span>
                        }
                        secondaryText={
                            `${humanSize(quota.used)} utilisés${
                                quota.allocated > 0 ?
                                ` et ${
                                    humanSize(quota.allocated)
                                } alloués` : ''
                            }`
                        } />
                </div>
                {quota.available !== null &&
                    <div
                        style={{
                            padding: '0px 16px 16px 16px',
                            backgroundColor: 'white',
                        }}>
                        <QuotaBar
                            height={24}
                            used={quota.used}
                            allocated={quota.allocated}
                            total={
                                quota.quota ?
                                quota.quota :
                                quota.allocated > quota.used ?
                                quota.allocated : quota.used
                            } />
                    </div>}
                <Divider />
                {keys.reduce((children, category) => [
                    ...children,
                    <div
                        key={`usage-${category}`}
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap-reverse',
                            justifyContent: 'space-between',
                        }}>
                        <ListItem
                            style={{
                                width: 'initial',
                            }}
                            primaryText={
                                humanSize(quota.usage[category].used)
                            }
                            secondaryText={
                                'Utilisés' + (
                                !!quota.usage[category].allocated ?
                                ' sur ' +
                                humanSize(
                                    quota.usage[category].allocated
                                ) + ' alloués' : '')
                            }
                            />
                        <ListItem
                            style={{
                                width: 'initial',
                            }}
                            primaryText={
                                names[category](quota.usage[category].count)
                            }
                            textComponent="div"
                            secondaryText={
                                <div style={{textAlign: 'right'}}>
                                    {quota.quota === 0 ?
                                        humanSize(total) :
                                        humanSize(quota.quota)}
                                </div>
                            } />
                    </div>,
                    <div
                        key={`bar-${category}`}
                        style={{padding: '0px 16px'}}>
                        <QuotaBar
                            height={8}
                            color={category}
                            used={quota.usage[category].used}
                            allocated={quota.usage[category].allocated}
                            total={quota.quota === 0 ? total : quota.quota}
                            />
                    </div>,
                ], [])}
            </List>
        )})
    const used = quotas.reduce((used, quota) => used + quota.used, 0)
    const allocated = quotas.reduce((allocated, quota) =>
        allocated + quota.allocated, 0
    )
    const renew = dayjs(account.renew_date).diff(dayjs(), 'days') <= 15
    const total = account.quota === 0 ?
        quotas.reduce((allocated, quota) =>
            allocated + (
                quota.allocated < quota.used ?
                quota.used : quota.allocated
            ), 0
        ) : account.quota
    return (
        <>
            <List className={css.quota}>
                {quotas.length > 1 &&
                <div>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap-reverse',
                            justifyContent: 'space-between',
                        }}>
                        <ListItem
                            primaryText={`${humanSize(total)} disponibles`}
                            secondaryText={
                                `${humanSize(used)} utilisés et ${
                                    humanSize(allocated)
                                } alloués`
                            } />
                        <ListItem
                            primaryText={`${
                                new Intl.NumberFormat(
                                    undefined,
                                    {maximumFractionDigits: 2},
                                ).format(
                                    Math.floor(
                                        total / Math.pow(1024, 3)
                                    ) * 1.20
                                )
                            } € / an`} />
                    </div>
                    <div
                        style={{padding: '0px 16px 16px 16px'}}>
                        <QuotaBar
                            allocated={allocated}
                            used={used}
                            total={total}
                            height={24} />
                    </div>
                </div>}
                <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                    }}>
                    <ListItem
                        style={{
                            width: "33%"
                        }}
                        component="div"
                        primaryText={"Date de renouvellement"}
                        secondaryText={
                            !!account.renew_date ?
                            new Intl.DateTimeFormat(undefined, {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                            }).format(new Date(account.renew_date)) :
                            <i>Non définie</i>
                        } />
                    <div style={{
                            width: '33%',
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                        }}>
                        {renew &&
                            <Button
                                raised
                                primary
                                label={"Renouveler"}
                                onClick={() => !!account.billing ? history.push('/renew') : history.push('/account/edit')}
                                />}
                    </div>
                    {!!quotas[0] &&
                        <ListItem
                            component="div"
                            style={{
                                width: '33%',
                                textAlign: 'right',
                            }}
                            primaryText={
                                new Intl.NumberFormat(
                                    undefined,
                                    {maximumFractionDigits: 2}
                                ).format(
                                    ( Math.floor(
                                        quotas[0].quota /
                                        Math.pow(1024, 3)
                                      + account.mailboxes
                                      + account.websites
                                      + account.domains ) ) * 1.20,
                                ) + ' € / an'
                            }
                            secondaryText={
                                `${Math.floor(quotas[0].quota / Math.pow(1024, 3))} Go d'espace`
                                + (
                                    account.mailboxes > 0 ?
                                    `, ${names.mails(account.mailboxes)}` :
                                    ''
                                ) + (
                                    account.websites > 0 ?
                                    `, ${names.websites(account.websites)}` :
                                    ''
                                ) + (
                                    `, ${account.domains} nom${account.domains > 1 ? 's' : ''} de domaine`
                                )
                            } />}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap-reverse',
                            justifyContent: 'space-between',
                            backgroundColor: 'white',
                        }}>
                        <ListItem
                            component="div"
                            style={{
                                width: 'initial',
                            }}
                            primaryText={
                                `${names.mails(account.mailboxes - quotas[0].usage.mails.count)} disponible${account.mailboxes - quotas[0].usage.mails.count > 1 ? 's' : ''}`
                            }
                            secondaryText={
                                `${names.mails(quotas[0].usage.mails.count)} créée${quotas[0].usage.mails.count > 1 ? 's' : ''}`
                            } />
                    </div>
                    <div
                        style={{
                            padding: '0px 16px 16px 16px',
                            backgroundColor: 'white',
                        }}>
                        <QuotaBar
                            height={10}
                            used={quotas[0].usage.mails.count}
                            total={account.mailboxes}
                            color={'mails'} />
                    </div>
                    <Divider />
                {child}
            </List>
            {add.route()}
        </>
    )
}

const ConnectedQuotas = connect(
    state => ({
        ...state.storage,
        account: state.app.user,
        display: state.app.display,
        domains: state.app.domains,
    }),
)(withRouter(Quotas))

class QuotaLoader extends React.Component {
    state = { loading: true }
    componentDidMount() {
        this.props.requestQuota(() => this.setState({ loading: false }))
    }

    render = () =>
        <Loading>
            <ConnectedQuotas />
        </Loading>
}

const ConnectedQuotaLoader = connect(undefined, {
    requestQuota: cb => ({
        type: QUOTA_REQUEST.action,
        [API_CALL]: {
            request: {
                url: '/accounts/storage',
                loading: true,
            },
            onsuccess: () => cb()
        }
    })
})(QuotaLoader)

export default ConnectedQuotaLoader
