import { API_CALL, MAIL_RECEIVE, MAIL_CHECK } from 'actions'
import { addInterval, removeInterval, resetInterval } from '.'

class PendingCheck {
    pending = []
    interval = null
    check = (dispatch, domain) =>
        dispatch({
            type: MAIL_CHECK.action,
            [API_CALL]: {
                request: {
                    url: `/mails/${domain}/check`,
                    body: {
                        pending: this.pending,
                    },
                },
                onsuccess: ({ dispatch, payload }) => {
                    this.pending = this.pending.filter(pending =>
                        !payload.installed.some(installed =>
                            installed.id === pending
                        )
                    )
                    const end = this.pending.length === 0
                    if (end) {
                        removeInterval(this.interval)
                    } else {
                        resetInterval(this.interval)
                    }
                }
            }
        })
    start = (dispatch, domain) => {
        if (this.pending.length > 0) {
            if (this.interval === null) {
                this.interval = addInterval(
                    () => this.check(dispatch, domain), 1000, this.clear,
                )
                resetInterval(this.interval)
            }
        }
    }
    clear = () => {
        this.interval = null
        this.pending = []
    }
    add = (mailboxes, dispatch, domain) => {
        const pending = mailboxes.filter(
            mailbox => !mailbox.installed,
        ).map(
            mailbox => mailbox.id
        )
        this.pending = [...this.pending, ...pending]
        this.start(dispatch, domain)
    }
    remove = mailbox => {
        this.pending = this.pending.filter(pending => pending === mailbox)
    }
}

export const pendingCheck = new PendingCheck()

export const select = ({ params }) => ({
    type: MAIL_RECEIVE.action,
    [API_CALL]: {
        request: {url: '/mails/' + params.domain},
        onsuccess: ({ dispatch, payload }) => {
            pendingCheck.add(payload.mailboxes, dispatch, params.domain)
        }
    }
})
