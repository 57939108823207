import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { RaisedButton, CircularProgress } from 'components'
import { ActionInput } from 'components/icons'
import { withTheme } from 'components'
import { Form, TextField } from 'components'
import {
    DISPLAY_NOTIFICATION, API_CALL,
    LOGIN, RESET, RECALL, LOGOUT,
    LOGIN_REMEMBER_ME,
} from 'actions'
import css from './form.module.css'

const init = {
    errors: null,
    next: null,
    email: null,
    remember: false,
    loading: false,
}

export const checkProfile = user => dispatch => {
    if (!window.location.pathname.startsWith('/account')) {
        if (user.billing === null || typeof(user.billing) === 'undefined' ) {
            dispatch({
                type: DISPLAY_NOTIFICATION,
                message: "Veuillez compléter votre profil",
                severity: "error",
            })
        }
        if (user.to_confirm !== null && typeof(user.to_confirm) !== 'undefined') {
            dispatch({
                type: DISPLAY_NOTIFICATION,
                message: `Veuillez confirmer l'adresse ${user.to_confirm}`,
                severity: "error",
            })
        }
    }
}

export const reducer = (state=init, action) => {
    switch (action.type) {
        case LOGIN.SUCCESS:
        case RECALL.SUCCESS:
        case RESET.SUCCESS:
            return {
                ...init,
                remember: state.remember,
            }
        case LOGIN_REMEMBER_ME:
            return {
                ...state,
                remember: !state.remember,
            }
        case LOGIN.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case LOGIN.FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.error.message,
                next:
                    action.params && action.params.startsWith('/login') ?
                    state.next :
                    action.params,
                email: action.error.first,
            }
        default:
            return state
    }
}

const onLogout = (push, notification) => dispatch => {
    dispatch({
        type: LOGOUT.action, [API_CALL]: {
            request: {url: '/accounts/logout'},
            onsuccess: ({ dispatch }) => {
                push('/login')
                dispatch({
                    type: DISPLAY_NOTIFICATION,
                    message: notification || 'Déconnecté',
                    severity: "warning",
                })
            }
        }
    })
}

const onLogin = ({login, password}, next, remember, push) => {
    const request = {
        url: '/accounts/login',
        body: {login, password, remember}
    }
    return {
        type: LOGIN.action,
        [API_CALL] : {
            request,
            onsuccess: ({ dispatch, payload }) => {
                push(
                    typeof(next) !== 'undefined' &&
                    next !== null &&
                    !next.startsWith('/login') &&
                    next !== '/' ?
                    next : payload.domains.length === 1 ?
                    `/${payload.domains[0].name}` :
                    '/'
                )
                checkProfile(payload.user)(dispatch)
            },
            onfailure: ({ dispatch, payload }) => {
                if (payload.first) {
                    push('/login/first')
                }
            }
        },
        params: next,
    }
}

export const actions = {
    login: onLogin,
    logout: onLogout,
}

const LoginForm = ({ onLogin, onReset, errors, next, children, remember, loading, theme, history }) =>
    <div className={css.form}>
        <Form
            errors={errors}
            validated={!loading}
            onSubmit={data =>
                onLogin(data, next, remember, history.push)
            }>
                <TextField
                    fullwidth={true}
                    autoComplete='username'
                    type='email'
                    label="Identifiant"
                    name="login" /><br/>
                <TextField
                    type="password"
                    fullwidth={true}
                    label="Mot de passe"
                    autoComplete='current-password'
                    name="password" /><br/>
                <RaisedButton
                    style={{
                        marginTop: '8px',
                    }}
                    primary={true}
                    startIcon={
                        loading ?
                        <CircularProgress
                            color="primary"
                            size={24} /> :
                        <ActionInput
                            size={24} />
                    }
                    disabled={loading}
                    fullWidth={true}
                    type="submit"
                    id="submit"
                    label="Entrer" />
        </Form>
        {children}
    </div>

const ConnectedLoginForm = connect(
    state => state.login.form, {
        onLogin,
    },
)(LoginForm)

const ThemedLoginForm = withTheme(ConnectedLoginForm)

export const route = () =>
    <Route
        exact
        path='/login'
        key='form'
        component={ThemedLoginForm} />

export default ThemedLoginForm
