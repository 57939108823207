import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { List, ListItem } from 'components'
import { LinearProgress } from 'components'
import { Icon, Alert } from 'components'
import { IconButton, Radio } from 'components'
import { API_CALL, PAYMENT_METHODS, PAYMENT_METHOD_UPDATE } from 'actions'
import { RemoveCard, CardIcon } from './card'

const PaymentMethods = ({ display }) => {
    const [ loading, setLoading ] = useState(true)
    const [ remove, setRemove ] = useState(false)
    const [ stripeUnavailable, setStripeUnavailable ] = useState(false)
    const methods = useSelector(state => state.cart.methods)
    const error = useSelector(state => state.cart.error)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({
            type: PAYMENT_METHODS.action,
            [API_CALL]: {
                request: {
                    url: '/billing/payment/methods',
                },
                onsuccess: ({ payload }) => {
                    setStripeUnavailable(payload.stripe_unavailable)
                    setLoading(false)
                },
            }
        })
    }, [dispatch])

    if (loading) {
        return (
            <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px',
                }}>
                <div style={{
                        width: '100%',
                        textAlign: 'center',
                    }}>
                    Chargement de vos méthodes de paiement...
                </div>
                <div style={{
                        width: '20%',
                        height: '20px',
                        paddingTop: '5px',
                    }}>
                    <LinearProgress />
                </div>
            </div>
        )
    } else {
        return (
            <>
                <List dense>
                    {methods.map(
                        method =>
                            <div
                                key={method.id}
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}>
                                <Radio
                                    size='medium'
                                    onChange={
                                        () => dispatch({
                                            type: PAYMENT_METHOD_UPDATE,
                                            method: method.id,
                                        })
                                    }
                                    checked={method.selected} />
                                <ListItem
                                    key={method.id}
                                    ContainerProps={{style:{width:'100%'}}}
                                    icon={<CardIcon type={method.icon} />}
                                    primaryText={method.label}
                                    secondaryText={method.secondary}
                                    onClick={
                                        () => dispatch({
                                            type: PAYMENT_METHOD_UPDATE,
                                            method: method.id,
                                        })
                                    }
                                    action={
                                        method.id !== 'transfer' && method.id !== 'new_card' && method.saved &&
                                        <IconButton
                                            edge="end"
                                            onClick={() => setRemove(method)}>
                                            <Icon>delete</Icon>
                                        </IconButton>} />
                            </div>)}
                    {error &&
                        <Alert severity="error">
                            {error}
                        </Alert>}
                </List>
                {!!remove &&
                <RemoveCard
                    open={!!remove}
                    method={remove}
                    onClose={() => setRemove(false)}
                    display={display} />}
                {stripeUnavailable &&
                <Alert severity="error">
                    Notre fournisseur de paiement par carte est actuellement indisponible.<br/>
                    Veuillez réessayer plus tard pour utiliser ce moyen de paiement ou <a href="mailto:support@soeasyto.com">contactez le support</a>.
                </Alert>}
            </>
        )
    }
}

export default PaymentMethods
