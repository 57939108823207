import React from 'react'
import { connect } from 'react-redux'
import { AutoComplete, ListItem, MaterialTextField as TextField } from 'components'
import {
    LOGOUT, SEARCH_FILTER, SEARCH_QUERY, SEARCH_INIT,
} from 'actions'
import { app } from '../apps'

const init = {
    filter: null,
    query: '',
}

export const reducer = (state=init, action) => {
    switch (action.type) {
        case LOGOUT.REQUEST:
        case SEARCH_INIT:
            return init
        case SEARCH_FILTER:
            return {
                ...state,
                filter: action.filter,
            }
        case SEARCH_QUERY:
            return {
                ...state,
                query: action.query,
            }
        default:
            return state;
    }
}

class SearchInput extends React.Component {
    state = { option: null }

    componentDidMount() {
        if (this.props.display === 'mobile') {
            document.getElementById('search').focus()
        }
        this.setState({
            option: this.props.filter,
        })
    }

    render() {
        const {
            act, location, filter,
            params, display, query, history
        } = this.props
        const { option } = this.state
        const { filters } = app({ location, params, history })
        const value = display === 'desktop' ?
            `${filter ? filter.text + ' ' : ''}${query}` :
            query
        const source =
            filters || []
        return (
            <AutoComplete
                openOnFocus
                freeSolo
                fullWidth
                disableClearable
                size="small"
                value={option}
                inputValue={value}
                getOptionSelected={
                    (option, value) =>
                        option.text === value
                }
                forcePopupIcon={!filter ? 'auto' : false}
                onChange={( event, option ) => {
                    this.setState({ option })
                }}
                onInputChange={(event, search, source) => {
                    let query = search
                    if (!!filter) {
                        if (display === 'desktop' &&
                            !search.includes(filter.text)) {
                            act({
                                type: SEARCH_FILTER,
                                filter: null,
                            })
                        } else {
                            if (display === 'desktop') {
                                query = search.replace(
                                    new RegExp(`${filter.text} ?`),
                                    '',
                                )
                            }
                        }
                    }
                    const newFilter = filters.find(filter =>
                        search.toLowerCase().includes(filter.text)
                    )
                    if (!!newFilter) {
                        if (!filter || newFilter.text !== filter.text) {
                            act({
                                type: SEARCH_FILTER,
                                filter: newFilter,
                            })
                        }
                        query = search.replace(
                            new RegExp(`${newFilter.text} ?`, 'i'),
                            '',
                        )
                    }
                    if (source === "input") {
                        act({
                            type: SEARCH_QUERY,
                            query,
                        })
                    }
                }}
                options={source}
                getOptionLabel={
                    item => item.text
                }
                renderOption={
                    item =>
                        <ListItem
                            component="div"
                            icon={item.icon}
                            primaryText={item.label} />}
                renderInput={
                    props =>
                        <TextField
                            name="search"
                            label="Rechercher..."
                            variant="outlined"
                            {...props} />
                }
                name='search'
                id='search' />
        )
    }
}

const ConnectedSearchInput = connect(
    state => ({
        filter: state.search.filter,
        query: state.search.query,
        display: state.app.display,
    }),
    { act: action => action },
)(SearchInput)

export { ConnectedSearchInput as SearchInput }
