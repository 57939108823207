import React from 'react'
import { Route } from 'react-router'
import MailAdd from './add'
import MailRemove from './remove'

export const route = () => [
    <Route
        key='mail-add'
        path="add"
        component={MailAdd} />,
    <Route
        key='mail-update-password'
        path=":mailbox/update"
        exact
        component={MailAdd} />,
    <Route
        key='mail-update-password'
        path=":mailbox/update/password"
        component={props => <MailAdd password {...props} />} />,
    <Route
        key='mail-update-quota'
        path=":mailbox/update/quota"
        component={props => <MailAdd quota {...props} />} />,
    <Route
        key='mail-remove'
        path=":mailbox/remove"
        component={MailRemove} />,
]
