import { combineReducers } from 'redux'
import { reducer as add } from './add'
import { reducer as activate } from './activate'
import { reducer as remove } from './remove'
import { reducer as password } from './password'
import {
    LOGOUT, MAIL_RECEIVE,
    ALIAS_REMOVE, ALIAS_ADD, ALIAS_ACTIVATE, ALIAS_PASSWORD, ALIAS_RESEND,
} from 'actions'

const init = {
    aliases: [],
}

const root = (state=init, action) => {
    switch(action.type) {
        case LOGOUT.REQUEST:
            return {
                ...init
            }
        case MAIL_RECEIVE.SUCCESS:
            return {
                ...state,
                aliases: action.payload.mailaliases.map(alias => ({
                    ...alias,
                    from_field:
                        alias.from_field.length === 0 ? '*' :
                        alias.from_field,
                }))
            }
        case ALIAS_ADD.SUCCESS: {
            return {
                ...state,
                aliases: [{
                        ...action.payload,
                        from_field:
                            action.payload.from_field.length === 0 ? '*' :
                            action.payload.from_field,
                    },
                    ...state.aliases,
                ]
            }
        }
        case ALIAS_RESEND.SUCCESS:
        case ALIAS_ACTIVATE.SUCCESS:
            return {
                ...state,
                aliases: state.aliases.map(alias => (
                        alias.from_field === '*' &&
                        '' === action.payload.from_field
                    ) || alias.from_field === action.payload.from_field ?
                    action.payload :
                    alias
                ).map(alias => ({
                    ...alias,
                    from_field:
                        alias.from_field.length === 0 ? '*' :
                        alias.from_field,
                }))
            }
        case ALIAS_PASSWORD.SUCCESS:
            return {
                ...state,
                aliases: state.aliases.map(alias =>
                    alias.id === action.payload.id ?
                    action.payload :
                    alias
                ),
            }
        case ALIAS_REMOVE.SUCCESS:
            return {
                ...state,
                aliases: state.aliases.filter(alias =>
                    alias.from_field === '*' ?
                    action.payload.from_field !== '' :
                    action.payload.from_field !== alias.from_field
                )
            }
        default:
            return state
    }
}

export const reducer = combineReducers({
    root,
    add,
    remove,
    activate,
    password,
})
