import React from 'react'
import { route } from './app'
import { ThemeProvider, createMuiTheme, StylesProvider } from 'components'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import { DISPLAY_CHANGE, GRECAPTCHA_READY } from 'actions'
import DayJsUtils from '@date-io/dayjs'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'bootstrap/dist/css/bootstrap.css'
import './index.css'
import { store } from './store'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2775d5',
        },
        secondary: {
            main: '#f22c2c',
        },
    }
})

const history = createBrowserHistory()

global.grecaptchaOnload = () => store.dispatch({ type: GRECAPTCHA_READY })

export default class Root extends React.Component {
    componentDidMount() {
        window.onresize = () => {
            const root = document.documentElement
            if (root) {
                const { clientWidth: width, clientHeight: height } = root
                const display = width <= 490 ? 'mobile' : 'desktop'
                if (display !== this.display ||
                    this.width !== width ||
                    this.height !== height) {
                    this.display = display
                    store.dispatch({
                        type: DISPLAY_CHANGE,
                        display,
                        width,
                        height,
                    })
                }
                root.style.setProperty('--vh', `${height*0.01}px`)
            }
        }
        window.onresize()
    }

    render() {
        return (
            <StylesProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={DayJsUtils}>
                        <Provider store={store}>
                            <Router history={history}>
                                {route(store.dispatch)}
                            </Router>
                        </Provider>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </StylesProvider>
        )
    }
}
