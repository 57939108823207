import {
    LOGOUT,
    ALIAS_ACTIVATE,
    ALIAS_RESEND,
} from 'actions'

const init = {
    errors: {},
    loading: false,
}

export const reducer = (state=init, action) => {
    switch(action.type) {
        case LOGOUT.REQUEST:
        case ALIAS_RESEND.SUCCESS:
        case ALIAS_ACTIVATE.SUCCESS:
            return init
        case ALIAS_RESEND.REQUEST:
        case ALIAS_ACTIVATE.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ALIAS_RESEND.FAILURE:
        case ALIAS_ACTIVATE.FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.error.message,
            }
        default:
            return state
    }
}
