import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { RaisedButton } from 'components'
import { Form, TextField } from 'components/form'
import {
    DISPLAY_NOTIFICATION, API_CALL, RESET, LOGIN, RESET_PASSWORD
} from 'actions'

const init = {
    errors: null,
    reset_key: null,
    email: null,
    firstname: null,
    lastname: null,
    loading: false,
}

export const reducer = (state=init, action) => {
    switch (action.type) {
        case RESET_PASSWORD.SUCCESS:
            return init
        case RESET_PASSWORD.FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.error.message,
            }
        case RESET.SUCCESS:
            return {
                ...state,
                loading: false,
                reset_key: action.params,
                email: action.payload.account.email,
                firstname: action.payload.account.firstname,
                lastname: action.payload.account.lastname,
            }
        case RESET.FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.error.message,
            }
        case RESET_PASSWORD.REQUEST:
        case RESET.REQUEST:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}

const onReset = (body, history) => ({
    type: RESET_PASSWORD.action,
    [API_CALL] : {
        request : {
            url: `/accounts/password`,
            body,
        },
        onsuccess: ({ dispatch, payload }) => {
            history.push('/')
            dispatch({
                type: LOGIN.SUCCESS,
                payload,
            })
            dispatch({
                type: DISPLAY_NOTIFICATION,
                message: 'Mot de passe réinitialisé avec succès',
            })
        }
    }
})

const checkReset = (key, replace, callback) => ({
    type: RESET.action,
    params: key,
    [API_CALL] : {
        request : {
            url: `/accounts/reset`,
            body: { key },
        },
        onsuccess: () => {
            replace('/login/reset/confirm')
            callback()
        },
        onfailure: ({ dispatch, payload }) => {
            replace('/login')
            dispatch({
                type: DISPLAY_NOTIFICATION,
                message: payload.message,
            })
            callback()
        }
    }
})

class ResetForm extends React.Component {
    state = { checking: false }

    componentDidMount() {
        if (!!this.props.match.params.key) {
            this.setState({ checking: true })
            this.props.checkReset(
                this.props.match.params.key,
                this.props.history.replace,
                () => this.setState({ checking: false })
            )
        } else if (!(this.props.reset_key && this.props.email)) {
             this.props.history.replace('/login/reset')
        }
    }

    render() {
        const { email, reset_key, errors, onReset, loading, history } = this.props
        return (
            email && reset_key && !this.state.checking &&
            <Form
                validated={!loading}
                errors={errors}
                onSubmit={data =>
                    onReset({...data, email, key: reset_key}, history)}>
                <TextField
                    label='Nouveau mot de passe'
                    type='password'
                    fullwidth
                    check={password =>
                        password.length < 6 ?
                        'Mot de passe trop court' :
                        ''
                    }
                    autoComplete='new-password'
                    name='password' /><br/>
                <TextField
                    label='Vérifiez le mot de passe'
                    type='password'
                    fullwidth
                    autoComplete='new-password'
                    check={(value, state) =>
                        state.password !== value ?
                        'Mot de passe différent' :
                        ''
                    }
                    name='passwordcheck' /><br />
                <RaisedButton
                    type='submit'
                    fullWidth
                    primary
                    label="Mettre à jour" />
            </Form>
        )
    }
}

const ConnectedResetForm = connect(
    state => state.login.reset,
    { onReset, checkReset },
)(withRouter(ResetForm))

export const route = () =>
    <Route
        exact
        path={["/login/reset/confirm", "/login/reset/confirm/:key"]}>
        <ConnectedResetForm />
    </Route>

export default ConnectedResetForm
