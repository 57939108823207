import React from 'react'
import { connect } from 'react-redux'
import { Form, QuotaField, TextField } from 'components/form'
import { Popup } from 'containers'
import { RaisedButton, ListItem, Dialog, FlatButton, CircularProgress } from 'components'
import { CommunicationEmail, ActionShoppingCart, NavigationCheck } from 'components/icons'
import SoEasyToIcon from 'root/icon'
import * as creators from './creators'
import colors from 'components/colors'

const MailAdd = ({
    errors, onAdd, onClose, quotas, history, onCartAdd,
    match, existing, mailboxes, onUpdate, limit, usage,
    onStorage, loading, password=false, quota=false,
}) => {
    const { params } = match
    const updated = mailboxes.find(mailbox =>
        mailbox.username === params.mailbox
    )
    const defaults = typeof(updated) !== 'undefined' ?
        {username: updated.username,
         quota: {user: updated.user.id, value: updated.quota}} :
        {quota: {
            user: quotas[0].id,
            value: quotas[0].quota === 0 ?
                10 * Math.pow(1024, 3) : quotas[0].available}}
    return (
        !updated &&
        quotas.every(quota => quota.quota !== 0 && quota.available < Math.pow(1024, 3)) ?
        <Dialog
            open={true}
            title={"Oooooops..."}
            onClose={() => onClose(history, params.domain)}
            actions={
                <>
                    <FlatButton
                        label={"Annuler"}
                        secondary={true}
                        onClick={() => onClose(history, params.domain)} />
                    <RaisedButton
                        label={"Augmenter"}
                        startIcon={<ActionShoppingCart />}
                        primary={true}
                        onClick={() => onStorage(history)} />
                </>
            }>
            <p>
                Vous n'avez plus d'espace disponible pour créer une
                boîte mail.
            </p>
            <p>
                Si vous souhaitez continuer, augmentez d'abord votre espace
                de stockage ou réduisez l'allocation de l'une de vos
                boîtes mails.
            </p>
        </Dialog> :
        !updated &&
        usage >= limit ?
        <Dialog
            open
            title={"Ooooooops..."}
            onClose={() => onClose(history, params.domain)}
            actions={
                <>
                    <FlatButton
                        label={"Annuler"}
                        secondary
                        onClick={() => onClose(history, params.domain)} />
                    <RaisedButton
                        label="Augmenter"
                        startIcon={<ActionShoppingCart />}
                        primary
                        onClick={() => onCartAdd(history, params.domain)} />
                </>
            }>
            <p>
                Vous n'avez pas assez de boîtes mails disponibles.
            </p>
            <p>
                Afin d'en créer de nouvelles, achetez d'abord plus de boîtes
                mails ou supprimez-en d'autres.
            </p>
        </Dialog> :
        <Popup
            title={
                typeof(updated) === 'undefined' ?
                "Créer une boîte mail" :
                `${updated.username}@${params.domain}`
            }
            onClose={() => onClose(history, params.domain)}
            icon={<CommunicationEmail />}
            color='#297ee6'>
            <Form
                validated={!loading}
                defaults={defaults}
                onSubmit={mailboxData =>
                    typeof(updated) === 'undefined' ?
                    onAdd(history, params.domain, mailboxData) :
                    onUpdate(history, params.domain, updated, mailboxData)
                }
                errors={errors}>
                {(password === quota || quota) &&
                <QuotaField
                    name='quota'
                    label={(quota ? 'Nouvelle a' : 'A') + 'llocation'}
                    users={
                        typeof(updated) === 'undefined' ||
                        updated.user.quota === 0 ?
                        quotas :
                        quotas.map(quota =>
                            quota.id === updated.user.id ? {
                                ...quota,
                                available: quota.available + updated.quota,
                            } :
                            quota
                        )}
                    min={
                        Math.max(
                            Math.pow(1024, 3),
                            typeof(updated) !== 'undefined' ? updated.used : 0,
                        )
                    } />}
                {typeof(updated) === 'undefined' && !password && !quota &&
                <TextField
                    name='username'
                    label={`Identifiant (@${params.domain})`}
                    autoComplete='username'
                    transform={value => value.trim().toLowerCase()}
                    check={[
                        /^[-_a-z0-9.]+$/i,
                        value =>
                            typeof(updated) === 'undefined' &&
                            existing.some(
                                identifier =>
                                    value.replace(/\./g, '') ===
                                    identifier
                            ) ? 'Identifiant déjà existant' : '']}
                    hidden={typeof(updated) !== 'undefined'} />}
                {typeof(updated) !== 'undefined' && !!updated.linked_account &&
                (password === quota || password) &&
                <ListItem
                    style={{textAlign: "center"}}
                    primaryText="Cette boîte mail est utilisée pour vous identifier sur votre compte"
                    secondaryText={
                        <span style={{
                                color: colors.red400,
                                fontStyle: 'italic',
                            }}>
                            Changer son mot de passe changera aussi celui
                            utilisé pour vous identifier
                        </span>
                    }
                    icon={
                        <SoEasyToIcon
                            size={40}
                            disabled={true} />} />}
                {(password === quota || password) &&
                <TextField
                    type='password'
                    name='password'
                    label={(password ? 'Nouveau m' : 'M') + 'ot de passe'}
                    autoComplete='new-password'
                    check={value =>
                        (typeof(updated) === 'undefined' ||
                            value.length > 0) &&
                            value.length < 6 ?
                            'Mot de passe trop court ' +
                            '(min. 6 caractères)' : ''} />}
                {(password === quota || password) &&
                <TextField
                    type='password'
                    name='passwordcheck'
                    label='Retapez le mot de passe'
                    autoComplete='new-password'
                    check={(value, state) =>
                        state.password && state.password !== value ?
                        'Les mots de passe sont différents' :
                        ''} />}
                <RaisedButton
                    label={
                        typeof(updated) === 'undefined' ?
                        'Créer' : 'Mettre à jour'
                    }
                    startIcon={
                        loading ?
                        <CircularProgress
                            size={24}
                            mode='indeterminate' /> :
                        <NavigationCheck
                            size={24} />
                    }
                    disabled={loading}
                    primary={true}
                    fullWidth={true}
                    type='submit' />
            </Form>
        </Popup>
    )
}

const ConnectedMailAdd = connect(
    state => ({
        ...state.domains.mail.mailbox.add,
        limit: state.app.user.mailboxes,
        usage: state.storage.quotas.reduce(
            (total, quota) => total + quota.usage.mails.count, 0
        ),
    }),
    creators,
)(MailAdd)

export default ConnectedMailAdd
