import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SESSIONS_LIST, API_CALL } from 'actions'
import { useTheme, LinearProgress, Alert, Paper, Icon, Tooltip } from 'components'
import { ListItem } from 'components'
import ResponsiveTable from 'containers/table'
import Platform from 'platform'

const init = []

export const reducer = (state=init, action) => {
    switch(action.type) {
        case SESSIONS_LIST.SUCCESS:
            return action.payload.sessions.map(
                session => ({
                    ...session,
                    platform: Platform.parse(session.useragent),
                    updated: new Date(session.updated),
                    current: session.id === action.payload.current,
                })
            )
        default:
            return state
    }
}

const getStyle = theme => (session, index) => {
    const style = {
        textAlign: 'center',
    }
    switch(index) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case undefined:
            return {
                ...index === undefined ? {} : style,
                ...(session.current ? { color: theme.palette.info.main } : {}),
            }
        default:
            return style
    }
}

const getSecondaryText = datefmt => (session, index) => {
    switch(index) {
        case undefined:
            return `${datefmt(session.updated)} @ ${session.ip}`
        default:
            return null
    }
}

const timefmt = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
}

const datefmt = display => new Intl.DateTimeFormat(
    undefined, {
        ...(
            display === 'desktop' ? {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
            } : {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
            }
        ),
        ...timefmt,
    }
).format

const getPrimaryText = datefmt => (session, index) => {
    switch(index) {
        case 0:
            return datefmt(session.updated)
        case 1:
            return session.ip
        case 2:
        case undefined:
            if (session.geolocation.city
                || session.geolocation.region
                || session.geolocation.country
                || session.geolocation.continent)
                return `${
                    session.geolocation.city || 'Ville inconnue'
                }, ${
                    session.geolocation.region || 'Region inconnue'
                }, ${
                    session.geolocation.country || 'Pays inconnu'
                }, ${
                    session.geolocation.continent || 'Continent inconnu'
                }`
            else
                return "Inconnue"
        case 3:
            return `${session.platform.name || 'Navigateur inconnu'} ${session.platform.version || ''}`
        case 4:
            return session.platform.os ? session.platform.os.toString() : 'OS inconnu'
        default:
            return null
    }
}

const getIcon = theme => (session, index) => {
    switch(index) {
        case 0:
        case undefined:
            if (session.current === true) {
                return (
                    <Tooltip
                        title="Session courante">
                        <Icon
                            style={{
                                color: theme.palette.info.main,
                            }}>
                            play_circle_filled
                        </Icon>
                    </Tooltip>
                )
            } else {
                return (
                    <Icon
                        style={{
                            opacity: 0,
                        }}>
                        play_circle_filled
                    </Icon>
                )
            }
        default:
            return null
    }
}

export const SessionList = ({ displayed }) => {
    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState("")
    const sessions = useSelector(state => state.account.sessions)
    const display = useSelector(state => state.app.display)
    const theme = useTheme()
    const [primaryText, secondaryText] = useCallback(
        () => {
            const fmt = datefmt(display)
            return [ getPrimaryText(fmt), getSecondaryText(fmt) ]
        },
        [ display ],
    )()
    const style = useCallback(
        () => getStyle(theme),
        [ theme ],
    )
    const icon = useCallback(
        () => getIcon(theme),
        [ theme ],
    )
    const dispatch = useDispatch()
    useEffect(() => {
        let cancelled = false
        if (displayed === true) {
            setLoading(true)
            setError("")
            dispatch({
                type: SESSIONS_LIST.action,
                [API_CALL]: {
                    request: {
                        url: '/accounts/sessions',
                    },
                    onsuccess: () => !cancelled && setLoading(false),
                    onfailure: () => {
                        if (cancelled) return
                        setLoading(false)
                        setError("Impossible de charger les sessions")
                    }
                },
            })
        }
        return () => { cancelled = true }
    }, [displayed, dispatch])
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}>
            {loading ?
                <LinearProgress /> :
                error.length > 0 ?
                    <Paper>
                        <Alert
                            severity="error">
                                {error}
                        </Alert>
                    </Paper> :
                    <>     
                        <ListItem
                            primaryText="Sessions actives" />
                        <ResponsiveTable
                            dataset={sessions}
                            styles={style()}
                            icon={icon()}
                            add={false}
                            primaryText={primaryText}
                            secondaryText={secondaryText}
                            headers={['Dernière activité', 'Adresse IP', 'Localisation', 'Navigateur', 'Plateforme']}
                            cols={1} />
                    </>}
        </div>
    )
}