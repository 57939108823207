import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { Popup } from 'containers'
import { ListItem, List, Divider, RaisedButton } from 'components'
import { ContentForward } from 'components/icons'
import { ActionDelete } from 'components/icons'
import { ActionLock } from 'components/icons'
import { creators as remove } from './remove'
import css from './details.module.css'

const Details = ({ match, history, aliases, onFinalize, onRemove, onPassword, onClose }) => {
    const { params } = match
    const alias = aliases.find(some => some.from_field === params.alias)
    return (
        typeof(alias) !== 'undefined' &&
        <Popup
            title={`${alias.from_field}@${params.domain}`}
            icon={<ContentForward />}
            color='#297ee6'
            onClose={() => onClose(history, params.domain)}>
            <List id={css.popup}>
                <div
                    className={css.item}>
                    <ListItem
                        primaryText='Destination' />
                    <ListItem
                        primaryText={alias.to} />
                </div>
                {alias.active ?
                    <div className={css.item}>
                        <ListItem
                            primaryText='Activée' />
                        <ListItem
                            primaryText={
                                alias.activation_date ?
                                'le ' + new Intl.DateTimeFormat(undefined, {
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric',
                                }).format(new Date(alias.activation_date)) :
                                'inconnue'} />
                    </div> :
                    <div className={css.item}>
                        <ListItem
                            primaryText='Activation envoyée' />
                        <ListItem
                            primaryText={
                                alias.activation_sent ?
                                'le ' + new Intl.DateTimeFormat(undefined, {
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric',
                                }).format(new Date(alias.activation_sent)) :
                                'inconnue'} />
                    </div>}
                <Divider />
                {!alias.active &&
                    <RaisedButton
                        secondary={true}
                        fullWidth={true}
                        label={'Finaliser'}
                        onClick={event => {
                            event.preventDefault()
                            event.stopPropagation()
                            onFinalize(history, params.domain, alias)
                        }} />}
                <ListItem
                    primaryText="Configurer l'authentification"
                    icon={<ActionLock />}
                    button
                    onClick={event => {
                        event.preventDefault()
                        event.stopPropagation()
                        onPassword(history, params.domain, alias)
                    }} />
                <ListItem
                    primaryText={'Supprimer'}
                    icon={<ActionDelete />}
                    button
                    onClick={event => {
                        event.preventDefault()
                        event.stopPropagation()
                        onRemove(history, params.domain, alias)
                    }} />
            </List>
        </Popup>
    )
}

const ConnectedDetails = connect(undefined, {
    onRemove: remove.remove,
    onPassword: (history, domain, alias) => () =>
        history.push(`/${domain}/mail/alias/${alias.from_field}/password`),
    onFinalize: (history, domain, alias) => () =>
        history.push(`/${domain}/mail/alias/${alias.from_field}/activate`),
    onClose: (history, domain) => () =>
        history.push(`/${domain}`),
})(Details)

export const route = () =>
    <Route
        path='alias/:alias'
        key="alias-details-route"
        component={ConnectedDetails} />

export const path = alias =>
    `alias/${alias.from_field}`
