import React from 'react'
import { AvWeb } from 'components/icons'
import { API_CALL, WEB_RECEIVE } from 'actions'
export { reducer } from './reducer'

export const name = 'Sites web'

export const types = {
    website: {
        icon: <AvWeb style={{color:'#f32c2c'}} />,
        label: 'Un site web',
        name: item => item.name,
        selector: dataset => dataset.websites,
        search: {
            select: () => null,
            result: (domain, item) => item.name,
            filter: 'Sites web',
        }
    }
}

export const select = ({ params, cb }) => ({
    type: WEB_RECEIVE.action,
    [API_CALL]: {
        request: {url: '/web/' + params.domain},
    }
})

export const route = {
    route: () => [],
    enter: select,
    selector: state => state.domains.web,
}
