import { combineReducers } from 'redux'
import { reducer as add } from './add'
import { reducer as remove } from './remove'
import {
    LOGOUT, MAIL_RECEIVE,
    MAIL_ADD, MAIL_UPDATE, MAIL_REMOVE, MAIL_CHECK,
} from 'actions'

const init = {
    mailboxes: [],
}

const root = (state=init, action) => {
    switch(action.type) {
        case MAIL_RECEIVE.SUCCESS:
            return {
                ...state,
                mailboxes: action.payload.mailboxes,
            }
        case MAIL_ADD.SUCCESS:
            return {
                ...state,
                mailboxes: [
                    action.payload,
                    ...state.mailboxes,
                ]
            }
        case MAIL_UPDATE.SUCCESS:
            return {
                ...state,
                mailboxes: state.mailboxes.map(mailbox =>
                    mailbox.id === action.payload.id ?
                    action.payload :
                    mailbox
                )
            }
        case MAIL_CHECK.SUCCESS:
            return {
                ...state,
                mailboxes: state.mailboxes.map(mailbox => {
                    const installed = action.payload.installed.find(
                        installed => installed.id === mailbox.id
                    )
                    if (typeof(installed) === 'undefined') {
                        return mailbox
                    } else {
                        return installed
                    }
                })
            }
        case MAIL_REMOVE.SUCCESS:
            return {
                ...state,
                mailboxes: state.mailboxes.filter(mailbox =>
                    mailbox.username !== action.payload.username
                )
            }
        case LOGOUT.REQUEST:
            return {
                ...init
            }
        default:
            return state
    }
}

export const reducer = combineReducers({
    root,
    add,
    remove,
})
