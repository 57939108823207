import React from 'react'
import { render } from 'react-dom'
import Root from './root'
import register from './worker'
import reportWebVitals from './reportWebVitals'

render(
    <Root />,
    document.getElementById('root'),
)
register()
reportWebVitals()
