import React from 'react'
import dayjs from 'dayjs'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ActionAddShoppingCart } from 'components/icons'
import { Divider, Icon, IconButton, Tooltip, withTheme } from 'components'
import { Size } from 'components/quota'
import Storage from './component'
import { path as addPath } from './add'
import { AppMenu, AppMenuItem } from 'containers/menu'
export { reducer } from './reducer'

export const name = 'Mon usage'

export const path = '/usage'

export const crumb = {
    name,
    child: () => ({ exists: false }),
    path,
}

const StorageMenu = ({ location, closeMenu, usage, account, history, theme }) =>
    <div>
        <Divider />
        <AppMenu
            path={path}
            active={location.pathname.startsWith(path)}
            closeMenu={closeMenu}
            icon={<Icon>data_usage</Icon>}
            name={name}
            indicator={
                new Date(account.renew_date) <= dayjs().add(15, 'day').toDate() ?
                <Tooltip title={
                        new Date(account.renew_date) <= new Date() ?
                        "Services expirés" :
                        "Renouvelez vos services"
                    }>
                    <IconButton
                        onClick={() => !!account.billing ? history.push('/renew') : history.push('/account/edit')}>
                        <Icon
                            style={{
                                color: new Date(account.renew_date) <= new Date() ?
                                    theme.palette.error.main :
                                    theme.palette.warning.main
                            }}>
                            warning
                        </Icon>
                    </IconButton>
                </Tooltip> : null
            }
            history={history}>
            <AppMenuItem
                label={
                    <span>
                        <Size value={account.used} />
                        &nbsp;utilisés&nbsp;{
                            account.quota > 0 ?
                            <span>
                                {"sur "}
                                {Math.round(account.quota / Math.pow(1024, 3), 2)}
                            </span> : null
                        }
                    </span>
                }
                active={location.pathname.startsWith(path)}
                icon={<Icon>storage</Icon>}
                path={path} />
            <AppMenuItem
                label={
                    account.mailboxes > 0 ?
                        `${usage.mails.count} boîte${usage.mails.count > 1 ? 's' : ''} mails sur ${account.mailboxes}` :
                        'Aucune boîte mail'
                }
                icon={<Icon>mail</Icon>}
                path={path}
                active={location.pathname.startsWith(path)} />
            <AppMenuItem
                label={
                    account.websites > 0 ?
                        `${usage.websites.count} site${usage.websites.count > 1 ? 's' : ''} web` :
                        'Aucun site web'
                }
                icon={<Icon>web</Icon>}
                path={path}
                active={location.pathname.startsWith(path)} />
            <AppMenuItem
                label={
                    account.domains > 0 ?
                        `${account.domains} domaine${account.domains > 1 ? 's' : ''}` :
                        'Aucun domaine'
                }
                icon={<Icon>domain</Icon>}
                path={path}
                active={location.pathname.startsWith(path)} />
        </AppMenu>
    </div>

export const add = ({ history }) => ({
    text: 'Adapter',
    action: () => () => history.push(addPath),
    icon: <ActionAddShoppingCart />,
})

const ConnectedStorageMenu = connect(
    state => ({
        account: state.app.user,
        usage: state.storage.quotas.length > 0 ? state.storage.quotas[0].usage : { mailboxes : 0, websites : 0, domains : 0 },
    }),
)(withTheme(withRouter(StorageMenu)))

export const route = () =>
    <Route
        path={path}>
        <Storage />
    </Route>

export { ConnectedStorageMenu as menu }
