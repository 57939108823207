import React from 'react'
import PropTypes from 'prop-types'
import {
    MaterialTable, TableBody, TableHeader, TableHeaderColumn,
    TableRow, TableRowColumn, TableContainer, Paper
} from 'components'
import { SocialSentimentDissatisfied } from 'components/icons'
import colors from 'components/colors'
import { withTheme } from 'components'

export const Table = ({ keys, dataset }) =>
    <TableContainer component={Paper}>
        <MaterialTable>
            <TableHeader>
                <TableRow>
                    {keys.map(key => {
                        if (typeof(key) === "object") {
                            key = Object.keys(key)[0]
                        }
                        return (
                            <TableHeaderColumn
                                key={`header-${key}`}
                                align={'center'}>
                                {key.charAt(0).toUpperCase()
                                    + key.slice(1).toLowerCase()}
                            </TableHeaderColumn>
                        )
                    })}
                </TableRow>
            </TableHeader>
            <TableBody>
            {dataset && dataset.length > 0 ?
                dataset.map((row, rindex) => (
                    <TableRow
                            key={`${row.type ? `${row.type}-` : ''}${row.id ? `row-${row.id}` : `row-${rindex}`}`}>
                        {keys.map((key, column) => {
                            let cell = null
                            let keyname = null
                            if (typeof(key) === "object") {
                                keyname = Object.keys(key)[0]
                                const cb = key[keyname]
                                cell = cb(row)
                            } else {
                                keyname = key
                                cell = row[key]
                            }
                            return (
                                <TableRowColumn
                                    key={`cell-${keyname}`}
                                    style={{padding: '0'}}>
                                    {cell}
                                </TableRowColumn>
                            )
                        })}
                    </TableRow>
                )) : (
                    <TableRow key='empty-row' hover={false}>
                        <TableRowColumn key='empty-cell' colSpan={keys.length}>
                            <div style={{
                                    color: colors.grey400,
                                    textAlign: 'center',
                                    display: 'inline-flex',
                                    whiteSpace: 'pre',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    fontStyle: 'italic'
                                }}>
                                <span>
                                    Pas de données trouvées{' '}
                                </span>
                                <SocialSentimentDissatisfied
                                    style={{color: colors.grey400}} />
                            </div>
                        </TableRowColumn>
                    </TableRow>
            )}
            </TableBody>
        </MaterialTable>
    </TableContainer>

Table.propTypes = {
    keys: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]).isRequired).isRequired,
    dataset: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.object),
    ]),
}

export default withTheme(Table)
