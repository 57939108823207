import { GRECAPTCHA_READY } from 'actions'
import Loading from './loading'
import Notification from './notification'
import Version from './version'
import Popup from './popup'
import * as loading from './loading'
import * as notification from './notification'
import * as version from './version'
import * as popup from './popup'

export const actions = {}
export const reducers = {}

const mods = { loading, notification, version, popup }

Object.keys(mods).forEach(
    mod => {
        if (!!mods[mod].reducer) reducers[mod] = mods[mod].reducer
        if (!!mods[mod].actions) actions[mod] = mods[mod].actions
    }
)

reducers.grecaptcha = (state={ready: false}, action) =>
    action.type === GRECAPTCHA_READY ?
    {...state, ready: true} : state

export { Loading, Notification, Version, Popup }
