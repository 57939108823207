import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Form, TextField, RaisedButton } from 'components'
import { withTheme, CircularProgress } from 'components'
import { Popup } from 'containers'
import { NavigationCancel, ActionCheckCircle, SocialDomain, ActionUpdate } from 'components/icons'
import { DOMAIN_CHECK, DOMAIN_ADD } from 'actions'

const init = { available: false, displayed: false }

export const reducer = (state=init, action) => {
    switch(action.type) {
        case DOMAIN_ADD:
            return {
                ...state,
                displayed: !!action.displayed,
            }
        case DOMAIN_CHECK.SUCCESS:
            return {
                ...state,
                available: true,
            }
        case DOMAIN_CHECK.FAILURE:
            return {
                ...state,
                available: false,
            }
        default:
            return state
    }
}

const DomainAdd = connect(
    state => ({...state.domains.app.add}), {
        addDomain: (body, onDone) => ({
            body
        }),
        onClose: () => ({
            type: DOMAIN_ADD,
        })
    },
)(withTheme(({ errors, addDomain, available, theme, displayed, onClose }) => {
    const [ checking, setChecking ] = useState(false)
    const [ init, setInit ] = useState(true)
    return (
        !!displayed &&
        <Popup
            open
            icon={<SocialDomain />}
            title="Ajout d'un domaine"
            onClose={onClose}>
            <Form
                errors={errors}
                validated={!checking}
                disabled={checking}
                onSubmit={data => {
                    setChecking(true)
                    setInit(false)
                    addDomain(data, () => setChecking(false))
                }}>
                <TextField
                    name='domain'
                    label='Nom de domaine (.com, .fr, .net, .org)'
                    check={/^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]\.(com|fr|net|org)/i}
                    required
                    endAdornment={
                        init ? null :
                        checking ?
                            <CircularProgress variant="indeterminate" /> :
                            available ?
                                <ActionCheckCircle
                                    style={{
                                        color: theme.palette.success.main,
                                    }} /> :
                                <NavigationCancel
                                    style={{
                                        color: theme.palette.error.main,
                                    }} />


                    }
                    />
                <RaisedButton
                    primary
                    fullWidth
                    startIcon={<ActionUpdate />}
                    label='Vérifier la disponibilité'
                    disabled={checking}
                    type='submit' />
            </Form>
        </Popup>
    )
}))

export { DomainAdd as Component }
