import React from 'react'
import PropTypes from 'prop-types'
import { TextField as MaterialTextField, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    underline: {
        "&:before": {
            borderColor: theme.palette.success.main,
        },
        "&:hover:not(:disabled):before": {
            borderColor: theme.palette.success.main,
        },
        "&:after": {
            borderColor: theme.palette.success.main,
        },
    },
    label: {
        "&.Mui-focused": {
            color: theme.palette.success.main,
        },
    }
}))

export const TextField = ({
    name, label, onChange, error, value, type='text', disabled=false,
    fullwidth=true, hidden=false, autoComplete='', endAdornment=undefined, required=false,
}) => {
    const classes = useStyles()
    return (
        !hidden ?
        <MaterialTextField
            id={name}
            name={name}
            type={type}
            fullWidth={fullwidth}
            value={value}
            InputProps={{
                ...!error && !!value ? { classes: { underline: classes.underline }} : {},
                ...!!endAdornment ? { endAdornment } : {},
            }}
            InputLabelProps={!error && !!value ? { classes: { focused: classes.label }} : {}}
            disabled={disabled}
            error={!!error}
            helperText={error}
            margin='dense'
            label={label}
            onChange={event => onChange(event.target.value)}
            autoComplete={autoComplete}
            required={required} /> : null
    )
}


TextField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    type: PropTypes.oneOf(['text', 'password', 'email']),
    error: PropTypes.string,
    transform: PropTypes.func,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    check: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.func,
                PropTypes.instanceOf(RegExp),
                PropTypes.string,
            ])
        ),
        PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.string,
            PropTypes.instanceOf(RegExp),
        ]),
    ]),
}

export default TextField
