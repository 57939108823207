import React from 'react'
import MaterialDialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
export { default as DialogActions } from '@material-ui/core/DialogActions'

export const Dialog = ({
    title, open, onClose, children, actions, dense=false, ...props
}) =>
    <MaterialDialog
        open={open}
        onClose={onClose}
        {...props}>
        {!!title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent
            style={dense ? {padding: '0'} : {}}>{children}</DialogContent>
        {!!actions && <DialogActions>{actions}</DialogActions>}
    </MaterialDialog>

export default Dialog
