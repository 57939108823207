import React from 'react'
import { connect } from 'react-redux'
import { Paper, SpeedDial, SpeedDialAction } from 'components'
import { ContentAdd } from 'components/icons'
import { Switch, withRouter } from 'react-router-dom'
import * as apps from '../apps'
import css from './home.module.css'

const routes = Object.keys(apps.apps)
    .filter(
        app =>
            apps.apps[app] !== apps.default &&
            !!apps.apps[app].route
    )
    .map(app =>
        React.cloneElement(
            apps.apps[app].route(),
            { key: app }
        )
    ).concat([
        apps.default.route(),
    ])

class Home extends React.Component {
    state = { popup: false }

    componentDidUpdate(props) {
        if (!this.props.user && !!props.user) {
            this.props.history.push('/login')
        }
    }

    render() {
        const {
            user, display, match, location, act, history
        } = this.props
        const { params } = match
        const { popup } = this.state
        const { add } = apps.app({ params, location, history })
        return (
            !!user &&
            <Paper id={css.home} className={css[display]}>
                <div id={css.homeContent}>
                    <div id={css.appContent}>
                        {apps.default.index()}
                        <Switch>
                            {routes}
                        </Switch>
                    </div>
                    {display === 'mobile' && !!add &&
                        <SpeedDial
                            id={css.fab}
                            icon={
                                !!add.icon ? add.icon : <ContentAdd />
                            }
                            FabProps={{color: "secondary"}}
                            open={popup}
                            ariaLabel={add.text}
                            onOpen={() => {
                                if (!!add.items) {
                                    this.setState({ popup: true })
                                } else {
                                    act(add.action())
                                }
                            }}
                            onClose={() =>
                                this.setState({ popup: false })
                            }>
                            {!!add.items && add.items.filter(add =>
                                add !== 'divider')
                                .map((add, index) =>
                                    <SpeedDialAction
                                        key={`action-${index}`}
                                        tooltipTitle={add.text}
                                        tooltipOpen
                                        tooltipPlacement="left"
                                        classes={{staticTooltipLabel: css.action}}
                                        onClick={() => {
                                            this.setState({ popup: false })
                                            act(add.action())
                                        }}
                                        icon={add.icon} />)}
                        </SpeedDial>}
                </div>
            </Paper>
        )
    }
}

const ConnectedHome = connect(
    state => ({ user: state.app.user, display: state.app.display }),
    { act: action => action },
)(withRouter(Home))

export default ConnectedHome
