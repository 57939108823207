import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Elements, CardElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { useTheme, MaterialTextField, Typography } from 'components'
import { Dialog, Button, ListItem, CircularProgress, Checkbox } from 'components'
import { Transition, Icon, SvgIcon, Alert, LinearProgress, Paper } from 'components'
import { API_CALL, DISPLAY_NOTIFICATION, PAYMENT_VALIDATE } from 'actions'
import { PAYMENT_METHOD_REMOVE, PAYMENT_METHODS, PAYMENT_CONFIRM } from 'actions'
import { ReactComponent as visa } from 'payment-icons/min/flat/visa.svg'
import { ReactComponent as amex } from 'payment-icons/min/flat/amex.svg'
import { useAccount, useDisplay } from './hooks'
import css from './card.module.css'

export const methods = { visa, amex }

const StripeWrapper = ({ children }) =>
    <div className={css.stripeElement}>
        <div className={css.stripeTab}>
            <div className={css.stripeTabContent}>
                <Icon
                    fontSize={'small'}
                    classes={{
                        fontSizeSmall: css.stripeTabIcon
                    }}>
                    lock
                </Icon>
                Sécurisé par&nbsp;<a href="https://stripe.com">Stripe</a>
            </div>
            <div className={css.stripeTabSpace}>&nbsp;</div>
        </div>
        <div className={css.stripeContent}>
            {children}
        </div>
    </div>

export const CardIcon = ({ type }) => {
    if (typeof(methods[type]) === 'undefined') {
        return <Icon>{type}</Icon>
    } else {
        return <SvgIcon component={methods[type]} viewBox="0 0 750 471" />
    }
}

export const RemoveCard = ({ open, display, onClose, method }) => {
    const [ disabled, setDisabled ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const dispatch = useDispatch()
    return (
        <Dialog
            title="Confirmez la suppression ?"
            open={open}
            fullWidth
            TransitionComponent={Transition}
            actions={[
                <Button
                    key="cancel"
                    onClick={() => onClose()}
                    label="Annuler"
                    secondary
                    outlined />,
                <Button
                    key="validate"
                    primary
                    disabled={disabled}
                    startIcon={
                        loading ?
                        <CircularProgress size={20} /> :
                        <Icon>delete</Icon>}
                    raised
                    onClick={() => {
                        setLoading(true)
                        setDisabled(true)
                        dispatch({
                            type: PAYMENT_METHOD_REMOVE.action,
                            [API_CALL]: {
                                request: {
                                    url: '/billing/payment/methods/remove',
                                    body: { id: method.id },
                                },
                                onsuccess: () => {
                                    dispatch({
                                        type: DISPLAY_NOTIFICATION,
                                        message: 'Carte supprimée',
                                    })
                                    setDisabled(false)
                                    setLoading(false)
                                    onClose()
                                },
                                onfailure: ({ payload }) => {
                                    dispatch({
                                        type: DISPLAY_NOTIFICATION,
                                        message: payload.message,
                                        severity: 'error',
                                    })
                                    setLoading(false)
                                    setDisabled(false)
                                }
                            }
                        })
                    }}
                    label="Supprimer" />
            ]}>
            Voulez-vous vraiment supprimer la carte suivante :<br/>
            <ListItem
                component="div"
                key={method.id}
                icon={<CardIcon type={method.icon} />}
                primaryText={method.label}
                secondaryText={method.secondary} />
        </Dialog>
    )
}

export const ValidatePayment = ({ changeStep }) => {
    const dispatch = useDispatch()
    const stripeKey = useSelector(state => state.app.keys.stripe)
    const open = useSelector(state => state.cart.validation)
    const [ stripe, setStripe ] = useState(null)
    const confirmation = useSelector(state => state.cart.confirmation)
    const handleError = useCallback((confirmation, message) => {
        dispatch({
            type: PAYMENT_VALIDATE,
            validation: false,
            remove: confirmation.saved === false ? confirmation.id : undefined,
            error: message,
        })
    }, [ dispatch ])
    useEffect(
        () => {
            let isCancelled = false
            if (stripe === null && stripeKey) {
                loadStripe(stripeKey).then(
                    stripe => {
                        !isCancelled && setStripe(stripe)
                    }
                ).catch(error => {
                    console.warn("impossible de joindre Stripe", error)
                    !isCancelled && handleError("impossible de joindre notre fournisseur de paiement")
                })
            }
            return () => { isCancelled = true }
        },
        [ stripe, stripeKey, handleError ],
    )
    const confirmAction = useCallback((body, onsuccess, onfailure) => dispatch({
        type: PAYMENT_CONFIRM.action,
        [API_CALL]: {
            request: {
                url: '/billing/payment/confirm',
                body,
            },
            onsuccess,
            onfailure,
        },
    }), [ dispatch ])
    useEffect(
        () => {
            stripe && confirmAction(confirmation, ({ payload }) => {
                if (payload.needs_confirmation) {
                    stripe.handleCardAction(
                        payload.secret,
                    ).then(result => {
                        if (result.error) {
                            handleError(confirmation, result.error.message)
                        } else {
                            confirmAction({
                                id: confirmation.id, confirmed: true,
                            }, () => {
                                dispatch({
                                    type: PAYMENT_VALIDATE,
                                    validation: false,
                                })
                                changeStep("finalized")
                            }, ({ payload }) => {
                                handleError(confirmation, payload.message)
                            })
                        }
                    })
                }
            }, ({ payload }) => {
                handleError(confirmation, payload.message)
            })
        },
        [ changeStep, confirmAction, dispatch, confirmation, handleError, stripe ]
    )
    return (
        <Dialog
            TransitionComponent={Transition}
            title="Validation de votre paiement..."
            open={open}>
            <Paper>
                <Alert severity="info">
                    <Typography>
                        Ne rafraîchissez pas la page.
                    </Typography>
                </Alert>
                <LinearProgress />
            </Paper>
        </Dialog>
    )
}

export const StripeButton = ({ onClick, ...props }) => {
    const elements = useElements()
    const stripe = useStripe()
    return (
        <Button
            onClick={
                () => onClick && onClick(stripe, elements)
            }
            {...props} />
    )
}

export const AddCard = ({ open, validatePayment }) => {
    const account = useAccount()
    const [ disabled, setDisabled ] = useState(true)
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(false)
    const [ name, setName ] = useState(
        `${account.firstname.toUpperCase()} ${account.lastname.toUpperCase()}`
    )
    const [ save, setSave ] = useState(false)
    const dispatch = useDispatch()
    const theme = useTheme()
    const stripeKey = useSelector(state => state.app.keys.stripe)
    const [ stripe, setStripe ] = useState(null)
    useEffect(
        () => {
            let isCancelled = false
            if (stripe === null && stripeKey) {
                loadStripe(stripeKey).then(
                    stripe => {
                        !isCancelled && setStripe(stripe)
                    }
                ).catch(error => {
                    console.warn("impossible de joindre Stripe", error)
                    !isCancelled && setError("impossible de joindre notre fournisseur de paiement")
                })
            }
            return () => { isCancelled = true }
        },
        [ stripe, stripeKey ],
    )
    return (
        <Elements stripe={stripe}>
            <Dialog
                title="Entrez les informations de votre carte"
                open={open}
                fullWidth
                TransitionComponent={Transition}
                actions={[
                    <Button
                        key="cancel"
                        onClick={() => validatePayment({ id: null })}
                        label="Annuler"
                        secondary
                        outlined />,
                    <StripeButton
                        key="validate"
                        primary
                        raised
                        startIcon={
                            !stripe || loading ?
                            <CircularProgress size={20} /> :
                            <Icon>add</Icon>}
                        disabled={!stripe || disabled}
                        onClick={(stripe, elements) => {
                            setDisabled(true)
                            setLoading(true)
                            stripe.createPaymentMethod({
                                type: 'card',
                                card: elements.getElement(CardElement),
                                billing_details: {
                                    name,
                                    email: account.email,
                                    phone: account.billing.phone_number,
                                    address: {
                                        postal_code: account.billing.zipcode,
                                        city: account.billing.town,
                                        country: 'FR',
                                        line1: account.billing.first_line,
                                        line2: account.billing.second_line || null,
                                    },
                                },
                            }).then(
                                result => {
                                    if (result.error) {
                                        setError(result.error.message)
                                        setLoading(false)
                                        setDisabled(false)
                                    } else {
                                        setError(false)
                                        dispatch({
                                            type: PAYMENT_METHODS.action,
                                            [API_CALL]: {
                                                request: {
                                                    url: '/billing/payment/methods',
                                                    body: {
                                                        id: result.paymentMethod.id,
                                                        save,
                                                    },
                                                },
                                                onsuccess: ({ payload }) => {
                                                    const method = payload.methods.find(method => method.id === result.paymentMethod.id)
                                                    validatePayment({
                                                        id: result.paymentMethod.id,
                                                        save: method.save,
                                                        saved: false,
                                                    })
                                                },
                                                onfailure: ({ payload }) => {
                                                    setError(payload.message)
                                                    setLoading(false)
                                                    setDisabled(false)
                                                }
                                            },
                                        })
                                    }

                                }
                            )
                        }}
                        label="Valider" />
                ]}>
                {stripe === null ?
                    "chargement" :
                    <>
                        {error &&
                        <Alert
                            severity="error"
                            onClose={() => setError(false)}>
                            {error}
                        </Alert>}
                        <StripeWrapper>
                            <CardElement
                                options={{
                                    style: {
                                        base: {
                                            fontSize: `${theme.typography.htmlFontSize}px`,
                                        },
                                    },
                                    hidePostalCode: true,
                                }}
                                disabled={loading}
                                onChange={
                                    event => {
                                        if (event.complete) {
                                            setDisabled(false)
                                        } else if (event.error) {
                                            setError(event.error.message)
                                        } else {
                                            setError(false)
                                        }
                                    }
                                }
                                onReady={
                                    element => element.focus()
                                } />
                        </StripeWrapper>
                        <MaterialTextField
                            fullWidth
                            required
                            disabled={loading}
                            value={name}
                            onChange={event => setName(event.target.value)}
                            label={"Nom du porteur de la carte"}
                            autoComplete={"cc-name"}
                            />
                        <Checkbox
                            label={"Mémoriser cette carte pour vos futurs paiements"}
                            checked={save}
                            disabled={loading}
                            onClick={() => setSave(!save)} />
                    </>}
            </Dialog>
        </Elements>
    )
}

export const ConfirmCvc = ({ open, onClose, amount, cgvDate, method, validatePayment, changeStep }) => {
    const [ disabled, setDisabled ] = useState(true)
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(false)
    const theme = useTheme()
    const display = useDisplay()
    const stripeKey = useSelector(state => state.app.keys.stripe)
    const [ stripe, setStripe ] = useState(null)
    useEffect(
        () => {
            let isCancelled = false
            if (stripe === null && stripeKey) {
                loadStripe(stripeKey).then(
                    stripe => {
                        !isCancelled && setStripe(stripe)
                        console.log("stripe loaded")
                    }
                ).catch(error => {
                    console.warn("impossible de joindre Stripe", error)
                    !isCancelled && setError("impossible de joindre notre fournisseur de paiement")
                })
            }
            return () => { isCancelled = true }
        },
        [ stripe, stripeKey ],
    )
    return (
        <Elements stripe={stripe}>
            <Dialog
                title="Confirmer l'utilisation de cette carte bancaire"
                open={open}
                fullWidth
                TransitionComponent={Transition}
                actions={[
                    <Button
                        key="cancel"
                        onClick={() => onClose(false)}
                        label="Annuler"
                        secondary
                        outlined />,
                    <StripeButton
                        key="validate"
                        primary
                        raised
                        startIcon={
                            !stripe || loading ?
                            <CircularProgress size={20} /> :
                            <Icon>payment</Icon>}
                        disabled={!stripe || disabled}
                        onClick={(stripe, elements) => {
                            setDisabled(true)
                            setLoading(true)
                            stripe.createToken(
                                'cvc_update',
                                elements.getElement(CardCvcElement),
                            ).then(
                                result => {
                                    if (result.error) {
                                        setError(result.error.message)
                                        setLoading(false)
                                        setDisabled(false)
                                    } else {
                                        setError(false)
                                        onClose()
                                        validatePayment({
                                            id: method.id,
                                            cvc_token: result.token.id,
                                            cgv_date: cgvDate,
                                            saved: true,
                                        })
                                    }
                                }
                            )
                        }}
                        label={`Payer ${amount}`} />
                ]}>
                {stripe === null ?
                    "chargement" :
                    <>
                        {error &&
                        <Alert
                            severity="error"
                            onClose={() => setError(false)}>
                            {error}
                        </Alert>}
                        <div style={{
                                marginTop: '10px',
                                display: 'flex',
                                flexDirection: display === 'mobile' ? 'column' : 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <ListItem
                                component="div"
                                key={method.id}
                                icon={<CardIcon type={method.icon} />}
                                primaryText={method.label}
                                secondaryText={method.secondary} />
                            <StripeWrapper>
                                <CardCvcElement
                                    disabled={disabled}
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: `${theme.typography.htmlFontSize}px`,
                                            },
                                        },
                                    }}
                                    onChange={
                                        event => {
                                            if (event.complete) {
                                                setDisabled(false)
                                            } else if (event.error) {
                                                setError(event.error.message)
                                            } else {
                                                setError(false)
                                            }
                                        }
                                    }
                                    onReady={
                                        element => {
                                            element.focus()
                                        }
                                    } />
                            </StripeWrapper>
                        </div>
                    </>}
            </Dialog>
        </Elements>
    )
}
