import React from 'react'
import { connect } from 'react-redux'
import { RaisedButton } from 'components'
import { Form, TextField } from 'components/form'
import { API_CALL, FIRST_PASSWORD, FIRST_ACCOUNT_DATA } from 'actions'

const init = {
    errors: null,
    loading: false,
    email: null,
    confirmation_key: null,
}

export const reducer = (state=init, action) => {
    switch (action.type) {
        case FIRST_PASSWORD.SUCCESS:
            return init
        case FIRST_ACCOUNT_DATA:
            return {
                ...state,
                email: action.account.email,
                confirmation_key: action.key,
            }
        case FIRST_PASSWORD.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FIRST_PASSWORD.FAILURE:
            return {
                ...state,
                errors: action.error.message,
                loading: false,
            }
        default:
            return state
    }
}

const onSubmit = (email, key, data, push) => ({
    type: FIRST_PASSWORD.action,
    [API_CALL]: {
        request: {
            url: `/accounts/setpassword`,
            body: {
                ...data,
                key,
                email,
            }
        },
        onsuccess: ({ dispatch, payload }, success) => {
            push('/login/first/identity')
            dispatch({
                type: FIRST_ACCOUNT_DATA,
                account: payload.account,
                key: key,
            })
            success()
        },
    }
})

const PasswordForm = ({ errors, loading, onSubmit, email, confirmation_key, history }) =>
    <Form
        errors={errors}
        popup={false}
        validated={!loading}
        disabled={loading}
        onSubmit={data => onSubmit(email, confirmation_key, data, history.push)}>
        <div className='text-center'>
            Merci d'indiquer le mot de passe que vous utiliserez pour
            accéder à votre compte.
        </div>
        <TextField
            type='password'
            fullwidth
            autoComplete='new-password'
            check={value =>
                value.length >= 6 ? '' : 'Mot de passe trop court'}
            name='password'
            label='Entrez un mot de passe' /><br/>
        <TextField
            type='password'
            fullwidth
            autoComplete='new-password'
            name='passwordcheck'
            check={(value, state) =>
                value && value !== state.password ?
                'Mot de passe différent' : ''}
            label='Vérifiez le mot de passe' /><br/>
        <RaisedButton
            type='submit'
            label='Suivant'
            disabled={loading}
            primary
            fullWidth />
    </Form>

const ConnectedPasswordForm = connect(
    state => state.login.first.password,
    { onSubmit },
)(PasswordForm)

export default ConnectedPasswordForm
