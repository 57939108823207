import {
    LOGOUT,
    ALIAS_ADD,
} from 'actions'

const init = {
    errors: {},
    alias: null,
    loading: false,
}

export const reducer = (state=init, action) => {
    switch(action.type) {
        case LOGOUT.REQUEST:
        case ALIAS_ADD.SUCCESS:
            return init
        case ALIAS_ADD.REQUEST:
            return {
                ...state,
                loading: false,
            }
        case ALIAS_ADD.FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.error.message
            }
        default:
            return state
    }
}
