import React from 'react'
import MaterialList from '@material-ui/core/List'
import MaterialListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

export { default as ListItemText } from '@material-ui/core/ListItemText'
export { default as ListItemIcon } from '@material-ui/core/ListItemIcon'

export const ListItem = ({
    icon, avatar, primaryText, secondaryText, action,
    textComponent='p', iconStyle={}, textStyle={}, textClasses={}, ...props
}) =>
    <MaterialListItem
        {...props}>
        {!!avatar &&
            <ListItemAvatar>
                {avatar}
            </ListItemAvatar>}
        {!!icon &&
            <ListItemIcon
                style={iconStyle}>
                {icon}
            </ListItemIcon>}
        <ListItemText
            classes={textClasses}
            primary={primaryText}
            primaryTypographyProps={{component: textComponent, style: textStyle}}
            secondary={secondaryText}
            secondaryTypographyProps={{component: textComponent, style: textStyle}} />
        {!!action &&
            <ListItemSecondaryAction>
                {action}
            </ListItemSecondaryAction>}
    </MaterialListItem>

export const List = ({
    children, ...props
}) =>
    <MaterialList
        {...props}>
        {children}
    </MaterialList>
