import React from 'react'
import { Route } from 'react-router'
import AliasAdd from './add'
import AliasActivate from './activate'
import AliasRemove from './remove'
import AliasPassword from './password'

export const route = () => [
    <Route
        key='alias-add'
        path="alias/add"
        component={AliasAdd} />,
    <Route
        key='alias-validate-no-key'
        exact
        path="alias/:alias/activate"
        component={AliasActivate} />,
    <Route
        key='alias-activate'
        path="alias/:alias/activate/:key"
        component={AliasActivate} />,
    <Route
        key='alias-remove'
        path="alias/:alias/remove"
        component={AliasRemove} />,
    <Route
        key='alias-password'
        path="alias/:alias/password"
        component={AliasPassword} />,
]
