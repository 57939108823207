import * as domains from './domains'
import * as storage from './storage'
import * as account from './account'

const apps = { domains, storage, account }

export { apps, domains as default }

export const add = {}
export const reducers = {}

Object.keys(apps).forEach(app => {
    if (!!apps[app].add) add[app] = apps[app].add
    if (!!apps[app].reducer) reducers[app] = apps[app].reducer
})

class App {
    constructor(context) {
        this.id =
            Object.keys(apps).find(app =>
                apps[app] !== domains &&
                context.location.pathname.startsWith(apps[app].path)
            ) || 'domains'
        this.app = apps[this.id]
        this.context = context
    }

    get add() {
        if (this.app.add) {
            return this.app.add(this.context)
        } else {
            return false
        }
    }

    get filters() {
        if (this.app.filters(this.context)) {
            return this.app.filters(this.context)
        } else {
            return []
        }
    }

    get search() {
        return !!this.app.filters &&
            !!this.app.filters(this.context) &&
            !!this.app.searches &&
            !!this.app.searches(this.context)
    }
}

export const app = context => new App(context)
