import React from 'react'
import { connect } from 'react-redux'
import { ResendButton } from 'components'
import {
    API_CALL,
    DISPLAY_NOTIFICATION,
    FIRST_CONFIRM,
    FIRST_ACCOUNT_DATA,
    FIRST_RESEND,
} from 'actions'

const init = {
    errors: null,
    email: null,
    loading: false,
    sent: null,
    step: null,
}

export const reducer = (state=init, action) => {
    switch (action.type) {
        case FIRST_ACCOUNT_DATA:
            return {
                ...state,
                email: action.account.email,
                sent: action.account.confirmation_sent,
                step: action.account.step,
            }
        case FIRST_RESEND.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FIRST_RESEND.SUCCESS:
            return {
                ...state,
                loading: false,
                email: action.payload.account.email,
                sent: action.payload.account.confirmation_sent,
            }
        case FIRST_RESEND.FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.error.message,
            }
        case FIRST_CONFIRM.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FIRST_CONFIRM.SUCCESS:
            return init
        case FIRST_CONFIRM.FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.error.message,
            }
        default:
            return state
    }
}

const onResend = email => ({
    type: FIRST_RESEND.action,
    [API_CALL]: {
        request: {
            url: `/accounts/resend`,
            body: {
                email,
            }
        },
    }
})

export const checkKey = (key, replace, callback) => ({
    type: FIRST_CONFIRM.action,
    [API_CALL]: {
        request: {
            url: `/accounts/confirm`,
            body: { key },
        },
        onsuccess: ({ dispatch, payload }, success) => {
            const step = payload.account.step || 'password'
            dispatch({
                type: FIRST_ACCOUNT_DATA,
                account: payload.account,
                key,
            })
            replace(`/login/first/${step}`)
            success()
            callback()
        },
        onfailure: ({ dispatch, payload }) => {
            replace('/login')
            dispatch({
                type: DISPLAY_NOTIFICATION,
                message: payload.message,
                severity: "error",
            })
        }
    },
})

const ConfirmForm = ({
    loading, email, sent, onResend, step
}) => (
    email &&
    <div className='text-center'>{
        step === 'confirm' ?
        <span>
            Un lien de confirmation vous a été envoyé à
            l'adresse {email}.
            <br />
            Veuillez cliquer dessus afin de poursuivre votre
            inscription.
            <br />
            Si vous ne l'avez pas reçu, vous pouvez cliquer sur le
            bouton ci-dessous afin de le renvoyer.
        </span> :
        <span>
            Afin de reprendre votre enregistrement, merci de bien
            vouloir réutiliser le lien fourni dans le mail de
            confirmation que vous avez reçu à l'adresse {email}.
            <br />
            Si vous ne le retrouvez pas, vous pouvez cliquer sur le
            bouton ci-dessous afin d'en renvoyer un nouveau.
        </span>}
        <br />
        <ResendButton
            label='Renvoyer le mail'
            onClick={() => onResend(email)}
            disabled={loading}
            wait={sent} />
    </div>
)

const ConnectedConfirmForm = connect(
    state => state.login.first.confirm,
    { onResend, reset: push => () => push('/login/first') },
)(ConfirmForm)

export default ConnectedConfirmForm
