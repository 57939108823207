import React from 'react'
import { Route } from 'react-router-dom'
import { combineReducers } from 'redux'
import { connect } from 'react-redux'
import {
    FlatButton, Checkbox, Paper, Avatar, IconButton, ListItemText,
    FormControl, FormControlLabel,
} from 'components'
import { SocialPerson, NavigationArrowBack } from 'components/icons'
import { API_CALL, LOGIN, LOGIN_REMEMBER_ME } from 'actions'

import Icon from '../icon'
import * as Form from './form'
import * as First from './first'
import * as Recall from './recall'
import * as Reset from './reset'

export const reducer = combineReducers({
    form: Form.reducer,
    first: First.reducer,
    recall: Recall.reducer,
    reset: Reset.reducer,
})

const checkSession = (history, location, callback) => ({
    type: LOGIN.action, params: location.pathname, [API_CALL]: {
    request: {url: '/accounts/login', loading: false},
    onsuccess: ({ dispatch, params, payload }) => {
        if (params.startsWith('/login')) {
            if (payload.domains.length === 1) {
                history.replace(`/${payload.domains[0].name}`)
            } else {
                history.replace('/')
            }
        } else if (params === '/') {
            if (payload.domains.length === 1) {
                history.replace(`/${payload.domains[0].name}`)
            }
        }
        Form.checkProfile(payload.user)(dispatch)
        callback()
    },
    onfailure: ({ dispatch, params }) => {
        if (!params.startsWith('/login')) {
            history.replace('/login')
        }
        callback()
    },
}})

export const actions = {
    ...Form.actions,
    session: checkSession,
}

export const Login = ({
    params, location, children, onFirst, onReset, onBack,
    onRememberMe, form, reset, display, history,
}) => {
    const title =
        location.pathname.startsWith('/login/first') ?
        'Créez votre Cloud avec Soeasyto' :
        'Gérez votre Cloud avec Soeasyto'
    const subtitle =
        location.pathname.startsWith('/login/first') ?
        "Indiquez les informations demandées pour vous inscrire." :
        'Connectez-vous pour accéder à votre compte.'
    return (
        <div>
            <div className='text-center' style={{
                    marginTop: display === 'desktop' ? '32px' : '16px',
                    marginBottom: display === 'desktop' ? '32px' : '16px',
                    lineHeight: display === 'desktop' ? '64px' : '32px',
                }}>
                <Icon />
                {display === 'desktop' ?
                <div>
                    <h1>
                        {title}
                    </h1>
                    <div>
                        {subtitle}
                    </div>
                </div> :
                <div>
                    {title}
                </div>}
            </div>
            <div className='container'>
                <Paper
                    style={{
                        ...display === 'desktop' ? {
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        } : {
                            marginLeft: '20px',
                            marginRight: '20px',
                        },
                        display: 'flex',
                        flexDirection: 'column',
                        ...display === 'mobile' ? {
                            width: 'calc(100vw - 40px)',
                        } : {},
                    }}>
                    <div style={{
                            height: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '4px',
                            paddingTop: '16px',
                            backgroundColor: '#f0f0f0',
                        }}>
                        {location.pathname !== '/login' &&
                            <IconButton
                                onClick={() => history.push('/login')}>
                                <NavigationArrowBack
                                    style={{color:'#a0a0a0'}} />
                            </IconButton>}
                    </div>
                    <div
                        className='container-content'
                        style={{
                            paddingBottom:
                                location.pathname !== '/login' ?
                                '32px' : null,
                        }}>
                        {!location.pathname.startsWith('/login/first') &&
                        <Avatar
                            style={{
                                marginBottom: '16px',
                                marginTop: '32px',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '80px',
                                height: '80px',
                            }}>
                            <SocialPerson
                                style={{
                                    width: '48px',
                                    height: '48px',
                                }}/>
                        </Avatar>}
                        {location.pathname === '/login/reset/confirm' &&
                        reset.email &&
                        <ListItemText
                            style={{textAlign: 'center'}}
                            primary={
                                reset.firstname[0].toUpperCase() +
                                reset.firstname.slice(1).toLowerCase() +
                                ' ' +
                                reset.lastname[0].toUpperCase() +
                                reset.lastname.slice(1).toLowerCase()
                            }
                            secondary={reset.email.toLowerCase()} />}
                        {Form.route()}
                        {Recall.route()}
                        {Reset.route()}
                        {First.route()}
                        {!location.pathname.startsWith('/login/reset') &&
                            !location.pathname.startsWith('/login/first') &&
                        <div style={{
                                display: 'flex',
                                paddingBottom: '32px',
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                flexDirection: display === 'desktop' ? 'row' : 'column',
                                width: '100%'
                            }}>
                            <FormControl
                                style={{
                                    width: display === 'desktop' ? '50%' : '100%',
                                }}>
                                <FormControlLabel
                                    style={{
                                        margin: '0',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    control={
                                        <Checkbox
                                            key='checkbox'
                                            checked={form.remember}
                                            onChange={
                                                event =>
                                                    onRememberMe(
                                                        event.target.checked
                                                    )
                                            } />}
                                    label="Rester connecté" />
                            </FormControl>
                            <FlatButton
                                style={{
                                    width: display === 'desktop' ? '50%' : '100%',
                                    textTransform: "none"
                                }}
                                label={"Mot de passe oublié ?"}
                                onClick={() => history.push('/login/reset')}
                                secondary />
                        </div>}
                    </div>
                </Paper>
            </div>
            {false && !location.pathname.startsWith('/login/first') && !location.pathname.startsWith('/login/reset') &&
            <div className='text-center' style={{
                    marginTop: '16px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}>
                <FlatButton
                    label='Créer un compte'
                    onClick={() => history.push('/login/first')} />
            </div>}
        </div>
    )
}

const ConnectedLogin = connect(
    state => ({...state.login, display: state.app.display }), {
        onRememberMe: value => ({ type: LOGIN_REMEMBER_ME, value })
    },
)(Login)

export const route = () =>
    <Route path="/login" component={ConnectedLogin} />

export default ConnectedLogin
