import React from 'react'
import { RaisedButton } from 'components'
import { ContentSend } from 'components/icons'

class ResendButton extends React.Component {
    state = { disabled: true, remaining: 0 }

    updateRemaining() {
        if (this.props.wait) {
            this.start = Date.now()
            if (this.start - new Date(this.props.wait) > 10 * 60 * 1000) {
                this.setState({ disabled: false })
            } else {
                this.setState({
                    remaining: 10 * 60 * 1000 -
                        (this.start - new Date(this.props.wait)),
                    disabled: true,
                })
            }
        }
    }

    componentDidUpdate(prev) {
        if (prev.wait !== this.props.wait) {
            this.updateRemaining()
        }
    }

    componentDidMount() {
        this.updateRemaining()
        if (this.props.wait && this.state.disabled) {
            this.timeout = setTimeout(() => {
                this.timeout = null
                this.setState({disabled: false})
            }, 10 * 60 * 1000 - (Date.now() - new Date(this.props.wait)))
            this.interval = setInterval(() =>
                this.setState({
                    remaining: 10 * 60 * 1000 -
                        (Date.now() - new Date(this.props.wait))
                }), 1000)
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
        clearInterval(this.interval)
    }

    render() {
        const minutes = Math.floor(this.state.remaining / 60000)
        const secondes = Math.floor(this.state.remaining / 1000) % 60
        const retry = `${minutes} minute${minutes > 1 ? 's' : ''} ` +
            `${secondes} seconde${secondes > 1 ? 's' : ''}`
        return (
            <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: '4px',
                }}>
                <RaisedButton
                    label={
                        this.props.wait ?
                        this.state.disabled ?
                        <span style={{
                                fontStyle: 'italic'
                            }}>
                            Réessayez dans {retry}
                        </span> :
                        <span>Renvoyer le mail d'activation</span> :
                        <span>Terminé</span>
                    }
                    secondary
                    startIcon={<ContentSend />}
                    disabled={(this.props.wait && this.state.disabled) || this.props.disabled}
                    onClick={event => {
                        event.stopPropagation()
                        event.preventDefault()
                        this.props.onClick()
                    }} />
            </div>
        )
    }
}

export default ResendButton
