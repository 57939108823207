import React from 'react'
import { combineReducers } from 'redux'
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router'
import { Stepper, Step, StepContent, StepIcon, StepLabel, CircularProgress, withTheme } from 'components'
import colors from 'components/colors'
import * as account from './account'
import * as confirm from './confirm'
import * as password from './password'
import * as identity from './identity'
import * as billing from './billing'
import * as services from './services'
import { DISPLAY_NOTIFICATION } from 'actions'

export const reducer = combineReducers({
    account: account.reducer,
    confirm: confirm.reducer,
    password: password.reducer,
    identity: identity.reducer,
    billing: billing.reducer,
    services: services.reducer,
})

const steps = ['account', 'confirm', 'password', 'identity', 'billing', 'services']
const labels = ['Email', 'Confirmation', 'Mot de passe', 'Identité', 'Facturation', 'Services']

const components = [
    account.default, confirm.default, password.default, identity.default, billing.default, services.default,
]

const LoadingIcon = () =>
    <CircularProgress
        size={24}
        variant="indeterminate" />

const Steps = withTheme(({ step, params, email, history, loading, theme }) =>
    <Stepper
            orientation="vertical"
            activeStep={steps.indexOf(step)}>
            {steps.map((that, index) =>
                <Step
                    key={that}>
                    <StepLabel
                        StepIconComponent={
                            loading && step === that ?
                            LoadingIcon :
                            StepIcon}>
                        {labels[index]}
                        {loading && step === that ?
                            <div style={{flexGrow: 1, fontWeight: "normal"}}>
                                <i>Chargement...</i>
                            </div> :
                            <>
                                {index < steps.indexOf(step) &&
                                <div style={{
                                        flexGrow: 1,
                                        alignItems: 'center',
                                        textAlign: 'right',
                                        fontWeight:
                                            that === 'account' ? 'bold' : 'normal',
                                        fontStyle:
                                            that === 'account' ? 'normal' : 'italic',
                                        color:
                                            that === 'account' ? undefined : that === 'confirm' && params.key ? colors.orange500 : colors.greenA400,
                                    }}>
                                    {that === 'account' ? email :
                                        that === 'confirm' ?
                                            params.key ? 'En cours' : 'Validée !' :
                                        that === 'password' ? 'Créé !' :
                                        null}
                                </div>}
                            </>}
                    </StepLabel>
                    {!loading ?
                        <StepContent>
                            {React.createElement(
                                components[steps.indexOf(step)] ||
                                (() => <div>{step}</div>),
                                { params, history }
                            )}
                        </StepContent> : null}
                </Step>)}
    </Stepper>
)

class First extends React.Component {
    state = { loading: true }

    componentDidMount() {
        const { match, history, checkKey } = this.props
        const { params } = match
        const { step, key } = params
        if (!!key) {
            checkKey(
                key, history.replace, () => this.setState({ loading: false }),
            )
        } else if (typeof(step) === 'undefined') {
            this.setState({ loading: false })
        } else if (this.props[step]) {
            const data = this.props[step]
            if (!data.email && (step === 'confirm' || !data.confirmation_key)) {
                this.setState({ loading: false })
                this.props.history.push('/login/first')
                this.props.act({
                    type: DISPLAY_NOTIFICATION,
                    message: "Veuillez recommencer...",
                    severity: "warning",
                })
            }
        }
    }

    render() {
        const { match } = this.props
        const { params } = match
        const step = typeof(params.step) === 'undefined' ?
            steps[0] : params.step
        return (
            <Steps
                step={step}
                params={params}
                history={this.props.history}
                loading={this.state.loading}
                email={
                    typeof(this.props[step]) === 'undefined' ?
                    null : this.props[step].email
                } />
        )
    }
}

const ConnectedFirst = connect(
    state => state.login.first, {
        checkKey: confirm.checkKey,
        act: action => action,
    },
)(withRouter(First))

export const route = () =>
    <Route
        path={[
            "/login/first",
            "/login/first/:step",
            "/login/first/:step/:key"
        ]}
        exact>
        <ConnectedFirst />
    </Route>

export default ConnectedFirst
