import { LOGOUT, MAIL_ADD, MAIL_UPDATE } from 'actions'

const init = {
    errors: {},
    loading: false,
}

export const reducer = (state=init, action) => {
    switch(action.type) {
        case LOGOUT.REQUEST:
        case MAIL_ADD.SUCCESS:
        case MAIL_UPDATE.SUCCESS:
            return init
        case MAIL_ADD.REQUEST:
        case MAIL_UPDATE.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case MAIL_UPDATE.FAILURE:
        case MAIL_ADD.FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.error.message
            }
        default:
            return state
    }
}
