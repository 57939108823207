import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    EDIT_PASSWORD, UPDATE_PASSWORD, API_CALL, DISPLAY_NOTIFICATION,
} from 'actions'
import { Dialog, DialogActions } from 'components'
import { Button, RaisedButton, CircularProgress } from 'components'
import { Form, TextField } from 'components/form'
import { ActionInput } from 'components/icons'

const init = { loading: false }

export const reducer = (state=init, action) => {
    switch(action.type) {
        case UPDATE_PASSWORD.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_PASSWORD.SUCCESS:
        case UPDATE_PASSWORD.FAILURE:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}

class PasswordEditor extends React.Component {
    componentDidMount() {
        this.props.onEditPassword()
    }

    componentWillUnmount() {
        this.props.onEditPasswordEnd()
    }

    render() {
        const { history, errors, loading, onEdit, user } = this.props
        return (
            <Dialog
                open
                title="Modification du mot de passe"
                onClose={() => history.push('/account/security')}>
                <Form
                    defaults={{email: user.email}}
                    onSubmit={body => onEdit(body, history)}
                    validated={!loading}
                    errors={errors}>
                    <TextField
                        name='email'
                        type='email'
                        label='Compte'
                        hidden
                        autoComplete='email' />
                    <TextField
                        name='new_password'
                        label='Votre nouveau mot de passe'
                        autoComplete='new-password'
                        type='password'
                        required
                        check={value =>
                            !!value && value.length < 6 ?
                            'Veuillez mettre au moins 6 caractères' :
                            ''
                        } />
                    <TextField
                        name='confirm_password'
                        label='Confirmez votre mot de passe'
                        required
                        autoComplete='new-password'
                        type='password'
                        check={(value, state) =>
                            !!value && value !== state['new_password'] ? 'Les mots de passes diffèrent' : ''
                        } />
                    <DialogActions>
                        <Button
                            label="Annuler"
                            secondary
                            onClick={() => history.push('/account/security')}
                            disabled={loading}
                            outlined />
                        <RaisedButton
                            label='Modifier'
                            disabled={loading}
                            type='submit'
                            startIcon={
                                loading ?
                                    <CircularProgress
                                        variant="indeterminate" /> :
                                    <ActionInput />
                            }
                            primary />
                    </DialogActions>
                </Form>
            </Dialog>
        )
    }
}

export default connect(
    state => ({
        ...state.account.password,
        user: state.app.user,
    }), {
        onEditPassword: () => ({ type: EDIT_PASSWORD, editing: true }),
        onEditPasswordEnd: () => ({ type: EDIT_PASSWORD, editing: false }),
        onEdit: (body, history) => ({
            type: UPDATE_PASSWORD.action,
            [API_CALL]: {
                request: {
                    url: '/accounts/update/change_password',
                    body,
                },
                onsuccess: ({ dispatch }) => {
                    history.push('/account')
                    dispatch({
                        type: DISPLAY_NOTIFICATION,
                        message: 'Mot de passe mis à jour avec succès',
                    })
                },
            }
        })
    }
)(withRouter(PasswordEditor))
