import {
    DISPLAY_NOTIFICATION,
    API_CALL,
    ACCOUNT_UPDATE,
} from 'actions'

const notify = (dispatch, payload, onDone) => {
    onDone()
    if (payload.account.to_confirm === null || typeof payload.account.to_confirm === 'undefined') {
        dispatch({
            type: DISPLAY_NOTIFICATION,
            message: 'Informations mises à jour avec succès'
        })
    } else {
        dispatch({
            type: DISPLAY_NOTIFICATION,
            message: `Confirmation envoyée à ${payload.account.to_confirm}`,
            severity: "warning",
        })
    }
}

export const onUpdate = (body, onDone) => ({
    type: ACCOUNT_UPDATE.action,
    [API_CALL]: {
        request: {
            url: `/accounts/update`,
            body: {
              ...body,
            },
        },
        onsuccess: ({ dispatch, payload }) => {
            if (!payload.confirm_mailbox && !payload.create_password) {
                notify(dispatch, payload, onDone)
            }
        },
    }
})

export const onConfirmPassword = (history, body, onDone) => ({
    type: ACCOUNT_UPDATE.action,
    [API_CALL]: {
        request: {
            url: `/accounts/update/confirm_password`,
            body,
        },
        onsuccess: ({ dispatch, payload }) => {
            dispatch({
                type: DISPLAY_NOTIFICATION,
                message: `Adresse mail ${payload.account.email} confirmée`,
            })
            onDone()
        },
    }
})

export const onCreatePassword = (history, body, onDone) => ({
    type: ACCOUNT_UPDATE.action,
    [API_CALL]: {
        request: {
            url: `/accounts/update/password`,
            body,
        },
        onsuccess: ({ dispatch, payload }) => {
            dispatch({
                type: DISPLAY_NOTIFICATION,
                message: `Adresse mail ${payload.account.email} confirmée`,
            })
          onDone()
        }
    }
})

export const checkKey = (history, params, onDone) => ({
      type: ACCOUNT_UPDATE.action,
      [API_CALL]: {
          request: {
              url: `/accounts/confirm`,
              body: {
                  key: params.key,
              }
          },
          onsuccess: ({ dispatch, payload }) => {
              history.push('/account/edit')
              if (!payload.create_password && !payload.confirm_mailbox) {
                  dispatch({
                      type: DISPLAY_NOTIFICATION,
                      message: `Adresse ${payload.account.email} confirmée`
                  })
              }
              onDone()
          },
          onfailure: ({ dispatch }) => {
              history.replace('/account/edit')
              dispatch({
                  type: DISPLAY_NOTIFICATION,
                  message: "Lien de confirmation invalide",
                  severity: "warning",
              })
              onDone()
          }
      },
})
