import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ActionShoppingCart } from 'components/icons'
import { Icon, List, ListItem, RaisedButton } from 'components'
import { IconButton, Alert } from 'components'
import { NavigationExpandLess, NavigationExpandMore } from 'components/icons'
import colors from 'components/colors'
import { humanSize } from 'components/quota'
import { Popup } from 'containers'
import { CART_ADD, API_CALL, DISPLAY_NOTIFICATION } from 'actions'

const Quantity = ({ initial, min, max, value, unit, diff, onUpdate, readonly=false }) =>
    <ListItem
        style={{
            flexGrow: 1
        }}
        textComponent="div"
        icon={
            !readonly &&
                <IconButton
                    edge="end"
                    disabled={(!!max && value + 1 > max)}
                    onClick={
                        () =>
                            onUpdate(
                                max ? Math.min(value + 1, max) : value + 1
                            )
                    }>
                    <NavigationExpandLess />
                </IconButton>
        }
        primaryText={
            <div
                style={{
                    textAlign: 'right',
                    whiteSpace: 'nowrap',
                }}>
                <span>
                    {`${value} ${unit(value)}`}
                </span>
                {diff !== 0 &&
                    <span
                        style={{
                            color: diff > 0 ?
                                colors.greenA400 :
                                colors.deepOrangeA400,
                        }}>
                        &nbsp;({diff > 0 ? `+${diff}` : diff})
                    </span>}
            </div>
        }
        secondaryText={
            value !== initial ?
            <div
                style={{
                    textAlign: 'right',
                    fontStyle: 'italic',
                }}>
                {`${initial} ${unit(value)}`}
            </div> : null
        }
        action={
            !readonly &&
                <IconButton
                    edge="end"
                    disabled={(!!min && value - 1 < min)}
                    onClick={
                        () => onUpdate(
                            min ? Math.max(value - 1, min) : value - 1,
                        )
                    }>
                    <NavigationExpandMore />
                </IconButton>
        } />

class StorageAdd extends React.Component {
    state = { disabled: false }
    static getDerivedStateFromProps(props, state) {
        let adding = props.cart.find(item => item.product.slug === 'Storage')
        if (typeof(adding) === 'undefined') {
            adding = 0
        } else {
            adding = adding.count
        }
        let removing = props.cart.find(item => item.product.slug === 'StorageRedeem')
        if (typeof(removing) === 'undefined') {
            removing = 0
        } else {
            removing = -Math.abs(removing.count)
        }
        state.initial = Math.floor(props.account.quota / Math.pow(1024, 3)) - removing
        state.initialTotal = state.initial + adding + removing
        state.used = Math.ceil(props.account.used / Math.pow(1024, 3))
        state.minimum = Math.max(10, Math.ceil(props.account.allocated / Math.pow(1024, 3)))
        if (typeof(state.value) === 'undefined') {
            state.value = state.initialTotal
        }
        let mailboxAdding = props.cart.find(item => item.product.slug === 'Mailbox')
        if (typeof(mailboxAdding) === 'undefined') {
            mailboxAdding = 0
        } else {
            mailboxAdding = mailboxAdding.count
        }
        let mailboxRemoving = props.cart.find(item => item.product.slug === 'MailboxRedeem')
        if (typeof(mailboxRemoving) === 'undefined') {
            mailboxRemoving = 0
        } else {
            mailboxRemoving = -Math.abs(mailboxRemoving.count)
        }
        state.mailboxInitial = props.account.mailboxes - mailboxRemoving
        state.mailboxInitialTotal = state.mailboxInitial + mailboxAdding + mailboxRemoving
        state.mailboxUsed = props.quotas[0].usage.mails.count
        state.mailboxMinimum = state.mailboxUsed
        if (typeof(state.mailboxValue) === 'undefined') {
            state.mailboxValue = state.mailboxInitialTotal
        }
        return state
    }

    render() {
        const { onClose, error, notify, cartAdd, account, history } = this.props
        const {
            value, initial, minimum, initialTotal,
            mailboxValue, mailboxInitial, mailboxMinimum, mailboxInitialTotal,
            disabled,
        } = this.state
        const fmt = new Intl.NumberFormat(
            undefined, {maximumFractionDigits: 2}
        ).format
        const diff = value - initial
        const mailboxDiff = mailboxValue - mailboxInitial
        const ttc = {
            initial: 1.20 * (initial + mailboxInitial + account.domains + account.websites),
            value: 1.20 * (value + mailboxValue + account.domains + account.websites),
        }
        const ttcdiff = ttc.value - ttc.initial
        return (
            <Popup
                title='Adapter mon usage'
                icon={<Icon>data_usage</Icon>}
                onClose={() => onClose(history)}
                color='#297ee6'>
                {error &&
                <Alert
                    severity="error">
                    {error}
                </Alert>}
                <List
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                    <ListItem
                        iconStyle={{
                            minWidth: '30px',
                        }}
                        textComponent="div"
                        icon={<Icon>storage</Icon>}
                        primaryText={
                            'Stockage'
                        }
                        secondaryText={
                            initial === value ?
                            null : 'Précédemment'} />
                    <Quantity
                        initial={initial}
                        min={minimum}
                        max={100}
                        unit={() => 'Go'}
                        value={value}
                        diff={diff}
                        onUpdate={value => this.setState({
                            value,
                        })} />
                </List>
                <List
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                    <ListItem
                        iconStyle={{
                            minWidth: '30px',
                        }}
                        icon={<Icon>mail</Icon>}
                        primaryText={
                            'Boîtes mails'
                        }
                        secondaryText={
                            mailboxInitial === mailboxValue ?
                            null : 'Précédemment'} />
                    <Quantity
                        initial={mailboxInitial}
                        min={mailboxMinimum}
                        max={Infinity}
                        unit={value => value > 1 ? 'Boîtes' : 'Boîte'}
                        value={mailboxValue}
                        diff={mailboxDiff}
                        onUpdate={mailboxValue => this.setState({
                            mailboxValue,
                        })} />
                </List>
                <List
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                    <ListItem
                        iconStyle={{
                            minWidth: '30px',
                        }}
                        icon={<Icon>web</Icon>}
                        primaryText={
                            'Sites web'
                        } />
                    <Quantity
                        initial={account.websites}
                        min={account.websites}
                        max={account.websites}
                        unit={value => value > 1 ? 'Sites' : 'Site'}
                        value={account.websites}
                        diff={0}
                        readonly />
                </List>
                <List
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                    <ListItem
                        iconStyle={{
                            minWidth: '30px',
                        }}
                        icon={<Icon>domain</Icon>}
                        primaryText={
                            'Domaines'
                        } />
                    <Quantity
                        initial={account.domains}
                        min={account.domains}
                        max={account.domains}
                        unit={value => value > 1 ? 'Domaines' : 'Domaine'}
                        value={account.domains}
                        diff={0}
                        readonly />
                </List>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                    <ListItem
                        primaryText={
                            ttc.initial === ttc.value ?
                            'Coût / an actuel' :
                            'Nouveau coût / an'
                        } />
                    <ListItem
                        textComponent="div"
                        primaryText={
                            <div
                                style={{
                                    textAlign: 'right',
                                }}>
                                <span>
                                    {fmt(ttc.value)} €
                                </span>
                                {ttc.initial !== ttc.value &&
                                <span
                                    style={{
                                        color:
                                            ttcdiff > 0 ?
                                             colors.deepOrangeA400 : colors.greenA400,
                                    }}>
                                    {ttcdiff > 0 ?
                                        ` (+${fmt(ttcdiff)} €) ` :
                                        ` (-${fmt(-ttcdiff)} €) `}
                                </span>}
                            </div>} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '8px',
                    }}>
                    <RaisedButton
                        onClick={() => {
                            const storage = value - initialTotal
                            const mailboxes = mailboxValue - mailboxInitialTotal
                            if (storage === 0 && mailboxes === 0) {
                                history.push('/usage')
                                notify("Usage inchangé")
                            } else {
                                this.setState({
                                    disabled: true,
                                })
                                cartAdd(history, {
                                    storage, mailboxes,
                                }, () => this.setState({ disabled: false }))
                            }
                        }}
                        startIcon={<ActionShoppingCart />}
                        secondary={true}
                        disabled={disabled}
                        label={"Mettre à jour"} />
                </div>
            </Popup>
        )
    }
}

const ConnectedStorageAdd = connect(
    state => ({...state.storage, account: state.app.user, cart: state.cart.items}), {
        onClose: history => () => history.push('/usage'),
        notify: message => ({
            type: DISPLAY_NOTIFICATION,
            message,
            severity: 'info',
        }),
        cartAdd: (history, count, done) => ({
            type: CART_ADD.action,
            [API_CALL]: {
                request: {
                    url: '/billing/cart/add',
                    body: [
                        { product: 'Storage', count: count.storage },
                        { product: 'Mailbox', count: count.mailboxes },
                    ],
                },
                onsuccess: ({ payload, dispatch }) => {
                    if (payload.cart && payload.cart.items.length > 0) {
                        history.push('/cart')
                    } else {
                        let message = ''
                        if (count.storage !== 0) {
                            message = `Stockage configuré à ${humanSize(payload.account.quota)}`
                        }
                        if (count.mailboxes !== 0) {
                            if (message.length > 0) {
                                message = `${message}\n`
                            }
                            message = `${message}Nombre de boîtes mails configuré à ${payload.account.mailboxes}`
                        }
                        history.push('/usage')
                        if (message.length > 0) {
                            dispatch({
                                type: DISPLAY_NOTIFICATION,
                                message,
                            })
                        }
                    }
                },
                onfailure: ({ payload, dispatch }) => {
                    if (payload.redirect) {
                        history.push('/account')
                    }
                    if (payload.message) {
                        dispatch({
                            type: DISPLAY_NOTIFICATION,
                            severity: 'error',
                            message: payload.message,
                        })
                        done()
                    }
                }
            },
        })
    }
)(withRouter(StorageAdd))

export const path = '/usage/add'

export const route = () =>
    <Route
        path={path}>
        <ConnectedStorageAdd />
    </Route>
