import React from 'react'
import { connect } from 'react-redux'
import { List, ListItem, Collapse } from 'components'
import { withTheme, IconButton } from 'components'
import { NavigationExpandLess, NavigationExpandMore, ContentAdd } from 'components/icons'
import css from './menu.css'

export const AppMenuItem = ({
    label, icon, active, path, push, closeMenu, disabled, theme, history, closeParent
}) =>
    <ListItem
        className={`${css.item} ${active && !disabled ? css.active : css.inactive}`}
        style={{
            color: active && !disabled ?
                theme.palette.primary.main :
                theme.palette.text.primary
        }}
        onClick={!disabled ?
            event => {
                event.stopPropagation()
                event.preventDefault()
                history.push(path)
                !!closeParent && closeParent()
                closeMenu()
            } : null}
        button={!disabled}
        primaryText={label}
        disabled={disabled}
        icon={
            React.cloneElement(
                icon, {
                    className: css.icon,
                    style: {
                        color: active && !disabled ?
                            theme.palette.primary.main :
                            theme.palette.text.primary,
                    },
                })} />

class AppMenu extends React.Component {
    constructor(props) {
    	super(props)
    	this.state = { opened: false }
    }

    componentDidUpdate(props) {
        if (props.active && !this.props.active) {
            this.setState({ opened: false })
        }
    }

    render() {
        const {
            history, theme, active, path, children, push, closeMenu, icon, name,
            indicator, add=false, expand=false,
        } = this.props
        const { opened } = this.state
        return (
          <List dense>
            <ListItem
                style={{
                    color: active ?
                        theme.palette.primary.main :
                        theme.palette.text.secondary,
                }}
                button
                onClick={event => {
                    event.stopPropagation()
                    event.preventDefault()
                    history.push(path)
                    closeMenu()
                }}
                open={opened && expand}
                icon={
                    React.cloneElement(icon, {
                        style: {
                            color: active ?
                                theme.palette.primary.main :
                                theme.palette.text.secondary
                        }
                    })
                }
                primaryText={name}
                action={
                    !!children && children.length > 0 && expand ?
                    <IconButton
                        onClick={event => {
                            event.stopPropagation()
                            event.preventDefault()
                            this.setState({ opened: !opened })
                        }}>
                        {React.cloneElement(
                            opened ?
                                <NavigationExpandLess /> :
                                <NavigationExpandMore />, {
                                        style: {
                                            color: active ?
                                                theme.palette.primary.main :
                                                theme.palette.text.secondary,
                                        }
                                    }
                        )}
                    </IconButton> : indicator ? indicator :
                    add ?
                    <IconButton
                        onClick={event => {
                            event.stopPropagation()
                            event.preventDefault()
                            add()
                        }}>
                        <ContentAdd />
                    </IconButton> : null} />
            <Collapse in={opened || !expand}>
                {React.Children.map(
                    children,
                    child =>
                        React.cloneElement(
                            child, {
                                push, closeMenu, theme, history,
                                closeParent: () => this.setState({ opened: false }),
                            }
                        ))}
              </Collapse>
              </List>
        )
    }
}


const ThemedAppMenu = withTheme(AppMenu)

const ConnectedAppMenu = connect(
    undefined,
)(ThemedAppMenu)

export { ConnectedAppMenu as AppMenu }
