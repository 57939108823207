import React from 'react'
import { Dialog } from 'components'
import { FlatButton } from 'components'
import { connect } from 'react-redux'
import { VERSION_CLOSE, VERSION_OPEN } from 'actions'

export const reducer = (state={open: false}, action) => {
    switch(action.type) {
        case VERSION_OPEN:
            return {open: true}
        case VERSION_CLOSE:
            return {open: false}
        default:
            return state
    }
}

const Version = ({ onClose, open }) =>
    <Dialog
        open={open}
        title="soeasyto.com"
        onClose={onClose}
        actions={
            <FlatButton
                autoFocus
                primary
                label="Fermer"
                onClick={onClose} />}>
      <div>
          <div>Version: {process.env.REACT_APP_VERSION}</div>
          <div>Copyright © {new Date().getFullYear()} Soeasyto</div>
      </div>
    </Dialog>

export default connect(
    state => ({open: state.version.open}),
    dispatch => ({onClose: () => dispatch({type: VERSION_CLOSE})})
)(Version)
