import React from 'react'
import { connect } from 'react-redux'
import { RaisedButton, List, ListItem, CircularProgress } from 'components'
import { ResendButton, withTheme } from 'components'
import { Popup } from 'containers'
import { NavigationCheck } from 'components/icons'
import { Form, TextField } from 'components/form'
import { ContentForward } from 'components/icons'
import Steps from '../steps'
import * as creators from './creators'

const AliasActivate = ({
    onValidate, onClose, match: { params },
    aliases, validate, onResend, errors, loading, history, theme
}) => {
    const alias = aliases.find(alias => alias.from_field === params.alias)
    if (typeof(alias) === 'undefined') {
        return null
    }
    const children = typeof(params.key) === 'undefined' ? [
        <List key='information'>
            {alias.active ?
                <ListItem
                    className='text-center'
                    primaryText={
                        <>
                            La redirection vers&nbsp;
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: theme.palette.primary.main,
                                }}>{alias.to}</span>
                            &nbsp;est déjà activée.
                        </>
                    } /> :
                <ListItem
                    className='text-center'
                    primaryText={
                        <>
                            Un mail vous a été envoyé à{' '}
                            <span style={{fontWeight: "bold", color: theme.palette.primary.main}}>
                                {alias.to}
                            </span><br /> afin de valider
                            votre redirection.<br />
                            Cliquez sur le lien contenu dans ce mail
                            pour continuer.
                        </>} />}
        </List>,
        <ResendButton
            key='resend-button'
            wait={!alias.active && alias.activation_sent}
            disabled={loading}
            onClick={() => {
                if (alias.active) {
                    validate(history, params.domain, params.alias)
                } else {
                    onResend(history, params.domain, params.alias)
                }
            }} />
    ] : [
        <Form
            key='activate-form'
            errors={errors}
            validated={!loading}
            defaults={
                typeof(params.key) !== 'undefined' ?
                {key: params.key} :
                {}
            }
            onSubmit={alias => onValidate(history, params.domain, params.alias, alias)}>
            <TextField
                key='activation-key'
                name='key'
                disabled={true}
                label={'Clé d\'activation'}
                check={/^[a-f0-9]+$/i} />
            <div
                key='activation-button'
                style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <RaisedButton
                    startIcon={
                        loading ?
                        <CircularProgress
                            size={24}
                            mode='indeterminate' /> :
                        <NavigationCheck
                            size={24} />
                    }
                    label='Valider'
                    primary={true}
                    disabled={loading}
                    type='submit' />
            </div>
        </Form>
    ]
    return (
        <Popup
            title={`${params.alias}@${params.domain}`}
            onClose={() => onClose(history, params.domain)}
            icon={<ContentForward />}
            color='#297ee6'>
            <Steps step='validate' />
            {children}
        </Popup>
    )
}

const ConnectedAliasActivate = connect(
       state => state.domains.mail.alias.activate,
       creators,
   )(withTheme(AliasActivate))

export default ConnectedAliasActivate
