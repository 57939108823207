import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { Popup } from 'containers'
import { ListItem, List, Divider } from 'components'
import {
    CommunicationEmail,
    ActionLock,
    DeviceStorage,
    AlertWarning,
    ActionDelete,
} from 'components/icons'
import SoEasyToIcon from 'root/icon'
import colors  from 'components/colors'
import { QuotaBar } from 'components'
import { update, remove } from './actions'
import css from './details.module.css'

const Details = ({ match, mailboxes, onEdit, onRemove, onClose, history }) => {
    const { params } = match
    const mailbox = mailboxes.find(some => some.username === params.mailbox)
    return (
        typeof(mailbox) !== 'undefined' &&
        <Popup
            title={`${mailbox.username}@${params.domain}`}
            icon={<CommunicationEmail />}
            color='#297ee6'
            onClose={() => history.push(`/${params.domain}`)}>
            <List id={css.popup}>
                <div style={{width: '100%', padding: '8px', paddingTop: '0px'}}>
                    <QuotaBar
                        label={{value: 'Espace utilisé', max: 'alloué'}}
                        value={mailbox.used}
                        max={mailbox.quota} />
                </div>
                <Divider />
                {!!mailbox.linked_account &&
                <div className={css.item}>
                    <ListItem
                        icon={
                            <SoEasyToIcon
                                size={40}
                                disabled={true}
                                iconStyle={{
                                    position: 'absolute',
                                    margin: '16px',
                                    top: '0px',
                                    left: '4px',
                                    display: 'block',
                                }} />}
                        primaryText={
                            "C'est votre identifiant"
                        }
                        secondaryText={
                            "Ne peut pas être supprimée"
                        } />
                </div>}
                {mailbox.installed ?
                    <div className={css.item}>
                        <ListItem
                            primaryText='Installée' />
                        <ListItem
                            primaryText={
                                mailbox.install_date ?
                                'le ' + new Intl.DateTimeFormat(undefined, {
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric',
                                }).format(new Date(mailbox.install_date)) :
                                'inconnue'} />
                    </div> :
                    <div className={css.item}>
                        <ListItem
                            icon={
                                <AlertWarning
                                    style={{color: colors.orange600}} />
                            }
                            primaryText='En attente depuis' />
                        <ListItem
                            primaryText={
                                mailbox.creation_date ?
                                'le ' +
                                new Intl.DateTimeFormat(undefined, {
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric',
                                }).format(new Date(mailbox.creation_date)) :
                                'inconnue'} />
                    </div>}
                <Divider />
                <div style={{width: '100%'}}>
                    <ListItem
                        primaryText={'Modifier l\'allocation'}
                        icon={<DeviceStorage />}
                        button
                        onClick={event => {
                            event.preventDefault()
                            event.stopPropagation()
                            onEdit(history, params.domain, mailbox, 'quota')
                        }} />
                </div>
                <div style={{width: '100%'}}>
                    <ListItem
                        primaryText={'Modifier le mot de passe'}
                        icon={<ActionLock />}
                        button
                        onClick={event => {
                            event.preventDefault()
                            event.stopPropagation()
                            onEdit(history, params.domain, mailbox, 'password')
                        }} />
                </div>
                {!mailbox.linked_account &&
                <div style={{width: '100%'}}>
                    <ListItem
                        style={{width: '100%'}}
                        primaryText={'Supprimer'}
                        icon={<ActionDelete />}
                        button
                        onClick={event => {
                            event.preventDefault()
                            event.stopPropagation()
                            history.push(`/${params.domain}`)
                            onRemove(history, params.domain, mailbox)
                        }} />
                </div>}
            </List>
        </Popup>
    )
}

const ConnectedDetails = connect(undefined, {
    onRemove: remove,
    onEdit: update,
})(Details)

export const route = () =>
    <Route
        key="mailbox-details-route"
        exact
        path=':mailbox'
        component={ConnectedDetails} />

export const path = mailbox =>
    `${mailbox.username}`
