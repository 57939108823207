import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MaterialCheckbox from '@material-ui/core/Checkbox'

export const Checkbox = ({ label='', ...props }) => {
    return (
        <FormGroup
            row>
            <FormControlLabel
                control={
                    <MaterialCheckbox
                        {...props} />
                }
                label={label} />
        </FormGroup>
    )
}
