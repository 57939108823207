import React from 'react'
//import muiThemeable from 'material-ui/styles/muiThemeable'

const HiddenField = ({ value, name, error, muiTheme, invalid }) =>
    <div>
        {error &&
            <div style={{
                color: muiTheme.textField.errorColor,
                fontWeight: 'bold'
            }}>
                {invalid}
            </div>}
        <input type='hidden' value={value} name={name} />
    </div>

//const ThemedHiddenField = muiThemeable()(HiddenField)

export default HiddenField
