import red from '@material-ui/core/colors/red'
import orange from '@material-ui/core/colors/orange'
import grey from '@material-ui/core/colors/grey'
import green from '@material-ui/core/colors/green'
import deepOrange from '@material-ui/core/colors/deepOrange'

const flatten = color => {
    const name = Object.keys(color)[0]
    const colors = color[name]
    return Object.keys(colors).reduce((keys, key) => ({
        ...keys,
        [`${name}${key}`]: colors[key],
    }), {})
}

const colors = {
    ...flatten({ red }),
    ...flatten({ orange }),
    ...flatten({ grey }),
    ...flatten({ green }),
    ...flatten({ deepOrange }),
}

export default colors
