import * as mail from './mail'
import * as web from './web'

const handlers = { web, mail }

const types = Object.keys(handlers).reduce((types, handler) => ({
    ...types,
    ...Object.keys(handlers[handler].types).reduce((types, type) => ({
        ...types,
        [type]: {
            ...handlers[handler].types[type], handler,
        },
    }), {}),
}), {})

export const actions = (domain, item) =>
    !!types[item.type].actions ?
    types[item.type].actions.map(action => ({
        ...action,
        action: () => action.action(domain, item),
    })) : []

export const filters = domain =>
    Object.keys(handlers).reduce((filters, handler) => ([
        ...filters,
        ...Object.keys(handlers[handler].types).filter(
            type => !!types[type].search && !!types[type].search.filter
        ).map(
            type => ({
                label: types[type].search.filter,
                icon: types[type].icon,
                text: `type:${type}`,
            }))
        ,
    ]), [])

class Hooks {
    hook = name => () =>
        Object.keys(handlers).forEach(handler =>
            handlers[handler].hooks &&
            handlers[handler].hooks[name] &&
            handlers[handler].hooks[name]()
        )

    mount = this.hook('mount')
    unmount = this.hook('unmount')
}

export const hooks = new Hooks()

export const icon = item => types[item.type].icon
export const name = item => types[item.type].name(item)
export const indicator = item =>
    types[item.type].indicator ? types[item.type].indicator(item) : null
export const details = item =>
    !!types[item.type].details && (
        `${types[item.type].handler}/` +
        types[item.type].details(item)
    )
export const selected = (params, item) =>
    !!types[item.type].selected && types[item.type].selected(params, item)
export const info = item =>
    types[item.type].info ? types[item.type].info(item) : null

export const add = (history, params) => ({
    items: Object.keys(handlers).reduce(
        (add, handler) => {
            const adds = Object.keys(handlers[handler].types).reduce(
                (adds, type) =>
                    !!types[type].add ? [
                        ...adds, {
                            action: () => types[type].add(history, params),
                            text: types[type].label,
                            icon: types[type].icon,
                    }] : adds, [])
            return [
                ...add,
                ...adds.length && add.length ? ['divider'] : [],
                ...adds,
            ]
        }, []),
    text: 'Créer',
})

export const reducers = {}
export const routes = {}
export const selectors = {}
export const matches = {}
export const names = {}

Object.keys(handlers).forEach(handler => {
    if (!!handlers[handler].name)
        names[handler] = handlers[handler].name
    if (!!handlers[handler].reducer)
        reducers[handler] = handlers[handler].reducer
    if (!!handlers[handler].route)
        routes[handler] = handlers[handler].route
    selectors[handler] =
        dataset => Object.keys(handlers[handler].types)
            .reduce((result, type) => [
                ...result,
                ...types[type].selector(dataset).map(item => ({
                    ...item,
                    type,
                })),
            ], [])
    matches[handler] = (item, search, filter) =>
           !types[item.type].search || (
               ( !filter || filter.text.includes(`type:${item.type}`)) &&
               ( !search || types[item.type].search.matches(
                   item, search.split(/\s+/),
               ))
           )
})
