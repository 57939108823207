import {
    API_CALL, DISPLAY_NOTIFICATION, ALIAS_ACTIVATE, ALIAS_RESEND,
} from 'actions'

export const onClose = (history, domain) => () => history.push(`/${domain}`)

export const onValidate = (history, domain, alias, key) => dispatch => dispatch({
    type: ALIAS_ACTIVATE.action,
    [API_CALL]: {
        request: {
            url: `/mails/${domain}/alias/activate`,
            body: {
                from_field: alias,
                key: key.key,
            }
        },
        onsuccess: ({ dispatch }) => {
            history.push(`/${domain}`)
            dispatch({
                type: DISPLAY_NOTIFICATION,
                message: `Redirection ${alias}@${domain} ajoutée avec succès`
            })
        }
    }
})

export const onResend = (history, domain, alias) => dispatch => {
    dispatch({
        type: ALIAS_RESEND.action,
        [API_CALL]: {
            request: {
                url: `/mails/${domain}/alias/resend`,
                body: {
                    from_field: alias,
                }
            },
            onsuccess: () => {
                history.push(`/${domain}`)
                dispatch({
                    type: DISPLAY_NOTIFICATION,
                    message: `Mail d'activation de ${alias}@${domain} renvoyé`
                })
            }
        }
    })
}

export const validate = (history, domain, alias) => dispatch => {
    history.push(`/${domain}`)
    dispatch({
        type: DISPLAY_NOTIFICATION,
        message: `Redirection ${alias}@${domain} ajoutée avec succès`
    })
}
