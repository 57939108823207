import {
    LOGIN, LOGOUT, MAIL_ADD, MAIL_REMOVE, MAIL_UPDATE, CART_ADD,
    PAYMENT_CONFIRM, CART_UPDATE, QUOTA_REQUEST
} from 'actions'

const init = {
    quotas: [],
    error: null,
}

export const reducer = (state=init, action) => {
    switch (action.type) {
        case LOGIN.SUCCESS:
        case QUOTA_REQUEST.SUCCESS:
            return {
                ...state,
                quotas: action.payload.quotas,
            }
        case MAIL_ADD.SUCCESS:
        case MAIL_UPDATE.SUCCESS:
            return {
                ...state,
                quotas: state.quotas.map(quota =>
                    quota.id === action.payload.user.id ?
                    action.payload.user :
                    quota
                ),
            }
        case MAIL_REMOVE.SUCCESS:
            return {
                ...state,
                quotas: state.quotas.map(quota =>
                    quota.id === action.payload.user.id ?
                    action.payload.user :
                    quota
                ),
            }
        case LOGOUT.REQUEST:
            return {
                ...init,
            }
        case CART_ADD.SUCCESS:
        case CART_UPDATE.SUCCESS:
        case PAYMENT_CONFIRM.SUCCESS:
            return {
                ...state,
                error: null,
                quotas: state.quotas.map(old => {
                    const quota = action.payload.quotas && action.payload.quotas.find(quota =>
                        old.id === quota.id,
                    )
                    return typeof(quota) === 'undefined' ?
                        old : quota
                }),
            }
        case CART_ADD.FAILURE:
            return {
                ...state,
                error: action.error.message || (
                    action.error.errors && action.error.errors.reduce(
                    (_, errors) => Object.keys(errors).reduce(
                        (_, field) => errors[field][0], null,
                    ),
                    null,
                )),
            }
        default:
            return state
    }
}
