import React from 'react'
import MaterialButton from '@material-ui/core/Button'

export const Button = ({
    label,
    primary=false, secondary=false,
    raised=false, outlined=false,
    ...props
}) =>
    <MaterialButton
        color={
            primary ? 'primary' : secondary ? 'secondary' : 'default'
        }
        variant={
            raised ? 'contained' : outlined ? 'outlined' : 'text'
        }
        {...props}>
        {label}
    </MaterialButton>

export const RaisedButton = ({ ...props }) =>
    <Button
        raised={true}
        {...props} />

export const FlatButton = Button

export default Button
