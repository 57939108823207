import { useSelector } from 'react-redux'

export const useCart = () =>
    useSelector(state => state.cart)

export const useDisplay = () =>
    useSelector(state => state.app.display)

export const useAccount = () =>
    useSelector(state => state.app.user)

export const useAuthenticated = () =>
    useSelector(state =>
        typeof(state.app.user) !== 'undefined' && state.app.user !== null
    )
