import React from 'react'
import { combineReducers } from 'redux'
import { connect } from 'react-redux'
import { Route, withRouter, Switch } from 'react-router-dom'
import { Typography } from 'components'
import colors from 'components/colors'
import { SocialPages, SocialDomain } from 'components/icons'
import { AppMenu, AppMenuItem } from 'containers/menu'
import Dialog from 'components/dialog'
import Button from 'components/button'
import { Loading } from 'containers'
import ResponsiveTable from 'containers/table'
import * as handlers from './handlers'
import * as Add from './add'
import { DOMAIN_LOADED, DOMAIN_ADD, LOADING_START, LOADING_END } from 'actions'

export const name = 'Mes domaines'

export const icon = <SocialDomain />

const Domains = ({ domains, history }) =>
    <>
        <ResponsiveTable
            primaryText={domain => domain.name}
            headers={['Nom']}
            icon={() => <SocialDomain style={{color: colors.grey800}} />}
            onClick={domain => history.push(`/${domain.name}`)}
            dataset={domains} />
        <Add.Component />
    </>

const ConnectedDomains = connect(
    state => ({
        domains: state.app.domains,
    }),
)(withRouter(Domains))

const DomainsMenu = ({ match, location, closeMenu, domains, history, onAdd }) => {
    const { params } = match
    return (
        <AppMenu
            path='/'
            active={
                domains.some(domain => domain.name === params.domain)
                || location.pathname === '/'
            }
            closeMenu={closeMenu}
            icon={<SocialPages />}
            name={name}
            expand
            history={history}
            /*add={onAdd}*/>
            {domains.map(domain =>
                <AppMenuItem
                    key={`app-menu-${domain.name}`}
                    label={domain.name}
                    icon={<SocialDomain />}
                    active={domain.name === params.domain}
                    path={`/${domain.name}`} />)}
        </AppMenu>
    )
}

const ConnectedDomainsMenu = connect(
    state => ({ domains: state.app.domains }),
    { onAdd: () => ({ type: DOMAIN_ADD, displayed: true })}
)(withRouter(DomainsMenu))

const routes = Object.keys(handlers.routes).map(route =>
    !!handlers.routes[route].route &&
        handlers.routes[route].route().map((item, index) => {
            const { path, component, ...rest } = item.props
            return (
                <Route
                    path={`/:domain/${route}/${path}`}
                    key={`handler-${route}-${path}`}
                    {...rest}>
                    {React.createElement(
                        withRouter(
                            handlers.routes[route].selector ?
                            connect(state =>
                                handlers.routes[route].selector(state)
                            )(item.props.component) :
                            item.props.component)
                        )}
                </Route>
            )
        }))

class DomainsApp extends React.Component {
    static selector(state) {
        return state.domains.app
    }

    static reducer(state={loading: true, add: {}}, action) {
        const newState = {...state}
        switch(action.type) {
            case DOMAIN_LOADED:
                newState.loading = false
                break
            default:
                break
        }
        newState.add = Add.reducer(newState.add, action)
        return newState
    }

    onEnter = () => {
        this.props.act({type: LOADING_START})
        Object.keys(handlers.routes).map(route => handlers.hooks.mount())
        Promise.all(
            Object.keys(handlers.routes).filter(
                route => !!handlers.routes[route].enter
            ).map(route => this.props.act(
                    handlers.routes[route].enter({
                        params: this.props.match.params,
                    })
                )
            )
        ).then(result => {
            this.props.act({type: LOADING_END})
            this.props.act({ type: DOMAIN_LOADED })
        })
    }

    componentDidMount = () => {
        this.onEnter()
    }

    componentDidUpdate(props) {
        if (props.match.params.domain !== this.props.match.params.domain) {
            this.onEnter()
        }
    }

    componentWillUnmount = () => {
        handlers.hooks.unmount()
    }

    render() {
        const {
            history, domains, datasets, act, query, filter, loading
        } = this.props
        const { params } = this.props.match
        return (
            <>
                {domains.some(domain => params.domain === domain.name) ?
                <Loading>
                    {!loading &&
                        <>
                            <ResponsiveTable
                                primaryText={
                                    item => handlers.name(item)
                                }
                                headers={
                                    query === '' && !filter ?
                                    ['Nom'] :
                                    ['Résultats de la recherche']
                                }
                                icon={item => handlers.icon(item)}
                                actions={item => handlers.actions(params.domain, item)
                                    .map(action => ({
                                        ...action,
                                        action: () => act(action.action())
                                    }))}
                                onClick={item =>
                                    handlers.details(item) &&
                                    history.push(
                                        `/${params.domain}/` +
                                        handlers.details(item))}
                                info={item => handlers.info(item)}
                                cols={1}
                                selected={item =>
                                    handlers.selected &&
                                    handlers.selected(params, item)}
                                dataset={Object.keys(handlers.reducers)
                                    .reduce((dataset, handler) => [
                                        ...dataset,
                                        ...handlers.selectors[handler](
                                            datasets[handler]
                                        ).map(
                                            item => ({...item, handler }),
                                        ),
                                    ], [])
                                    .filter(item =>
                                        handlers.matches[item.handler](
                                            item, query, filter
                                        ),
                                    )
                                }
                                indicators={item => handlers.indicator(item)} />
                            <Switch>
                                {routes}
                            </Switch>
                        </>}
                </Loading> :
                <Dialog
                    title="Oooops..."
                    open={true}
                    actions={[
                        <Button
                            key="accueil"
                            primary
                            label="Accueil"
                            onClick={() => history.push('/')} />
                    ]}>
                    <Typography>
                        Le domaine {params.domain} n'a pas été trouvé.
                    </Typography>
                </Dialog>}
                <Add.Component />
            </>
        )
    }
}

const ConnectedDomainsApp = connect(
    state => ({
        domains: state.app.domains,
        datasets: state.domains,
        query: state.search.query,
        filter: state.search.filter,
        loading: state.domains.app.loading,
    }),
    { act: action => action },
)(withRouter(DomainsApp))

export const index = () =>
    <Route key="index" path="/" exact>
        <ConnectedDomains />
    </Route>

export const crumb = {
    name,
    child: info => ({
        exists: true,
        displayed: !!info.params.domain,
        crumb: {
            name: info.params.domain,
            child: info => ({
                exists: true,
                displayed: !!info.params.app && !!handlers.names[info.params.app],
                crumb: {
                    name: handlers.names[info.params.app],
                    child: () => ({
                        exists: false,
                    }),
                    path: `/${info.params.domain}/${info.params.app}`,
                }
            }),
            path: `/${info.params.domain}`,
        },
    }),
    path: '/',
}

export const filters = ({ params }) =>
    !!params.domain && handlers.filters(params.domain)

export const searches = ({ params }) =>
    !!params.domain && handlers.matches

export const reducer = combineReducers(
    Object.keys(handlers.reducers).reduce((reducers, handler) => ({
        ...reducers,
        [handler]: handlers.reducers[handler],
    }), { app: DomainsApp.reducer })
)

export const add = ({ params, location, history }) =>
    !!params.domain && handlers.add(history, params)

export const dataset = state => state.domains

export const route = () =>
    <Route
        key="domains"
        path='/:domain'>
        <ConnectedDomainsApp />
    </Route>

export const menu = ConnectedDomainsMenu
