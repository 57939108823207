/* API */
export const LOADING_START = 'LOADING_START'
export const LOADING_END = 'LOADING_END'

export class API_CALL {
    constructor(name) {
        this.action = name
        this.REQUEST = `${name}_REQUEST`
        this.SUCCESS = `${name}_SUCCESS`
        this.FAILURE = `${name}_FAILURE`
    }
}

/* Login actions */
export const LOGIN_REMEMBER_ME = 'LOGIN_REMEMBER_ME'
export const LOGIN = new API_CALL('LOGIN')
export const RECALL = new API_CALL('RECALL')
export const RESET = new API_CALL('RESET')
export const RESET_PASSWORD = new API_CALL('RESET_PASSWORD')
export const LOGOUT = new API_CALL('LOGOUT')
export const ERROR_REPORT = new API_CALL('ERROR_REPORT')

/* Account actions */
export const ACCOUNT_UPDATE = new API_CALL('ACCOUNT_UPDATE')
export const EDIT_PROFILE = 'EDIT_PROFILE'
export const EDIT_PASSWORD = 'EDIT_PASSWORD'
export const UPDATE_PASSWORD = new API_CALL('UPDATE_PASSWORD')
export const BILLS_RECEIVE = new API_CALL('BILLS_RECEIVE')
export const BILL_RECEIVE = new API_CALL('BILL_RECEIVE')
export const SESSIONS_LIST = new API_CALL('SESSIONS_LIST')
export const OUTLAY_DOWNLOAD = new API_CALL('OUTLAY_DOWNLOAD')

/* First actions */
export const FIRST_ACCOUNT_DATA = 'FIRST_ACCOUNT_DATA'
export const FIRST_ACCOUNT = new API_CALL('FIRST_ACCOUNT')
export const FIRST_CONFIRM = new API_CALL('FIRST_CONFIRM')
export const FIRST_CHECK = new API_CALL('FIRST_CHECK')
export const FIRST_PASSWORD = new API_CALL('FIRST_PASSWORD')
export const FIRST_IDENTITY = new API_CALL('FIRST_IDENTITY')
export const FIRST_RESEND = new API_CALL('FIRST_RESEND')
export const FIRST_BILLING = new API_CALL('FIRST_BILLING')
export const FIRST_SERVICES = new API_CALL('FIRST_SERVICES')

/* Cart actions */
export const CART_LOAD = new API_CALL('CART_LOAD')
export const CART_UPDATE = new API_CALL('CART_UPDATE')
export const CART_ADD = new API_CALL('CART_ADD')
export const CART_STEP = 'CART_STEP'
export const CART_EMPTY = 'CART_EMPTY'
export const PAYMENT_METHODS = new API_CALL('PAYMENT_METHODS')
export const PAYMENT_METHOD_UPDATE = 'PAYMENT_METHOD_UPDATE'
export const PAYMENT_METHOD_REMOVE = new API_CALL('PAYMENT_METHOD_REMOVE')
export const PAYMENT_CONFIRM = new API_CALL('PAYMENT_CONFIRM')
export const PAYMENT_CONFIRM_CARD = 'PAYMENT_CONFIRM_CARD'
export const PAYMENT_VALIDATE = 'PAYMENT_VALIDATE'
export const RIB = new API_CALL('RIB')
export const ACCEPT_CGV = 'ACCEPT_CGV'
export const REDEEM = new API_CALL('REDEEM')

/* Domain actions */
export const DOMAIN_DELETE = 'DOMAIN_DELETE'
export const DOMAIN_LOADED = 'DOMAIN_LOADED'
export const DOMAIN_ADD = 'DOMAIN_ADD'
export const DOMAIN_CHECK = new API_CALL('DOMAIN_CHECK')

/* Mail actions */
export const MAIL_RECEIVE = new API_CALL('MAIL_RECEIVE')
export const MAIL_ADD = new API_CALL('MAIL_ADD')
export const MAIL_UPDATE = new API_CALL('MAIL_UPDATE')
export const MAIL_REMOVE = new API_CALL('MAIL_REMOVE')
export const MAIL_CHECK = new API_CALL('MAIL_CHECK')
export const ALIAS_ADD = new API_CALL('ALIAS_ADD')
export const ALIAS_ACTIVATE = new API_CALL('ALIAS_ACTIVATE')
export const ALIAS_REMOVE = new API_CALL('ALIAS_REMOVE')
export const ALIAS_PASSWORD = new API_CALL('ALIAS_PASSWORD')
export const ALIAS_GENPASS = new API_CALL('ALIAS_GENPASS')
export const ALIAS_RESEND = new API_CALL('ALIAS_RESEND')

/* Web actions */
export const WEB_RECEIVE = new API_CALL('WEB_RECEIVE')

/* Search actions */
export const SEARCH_FILTER = 'SEARCH_FILTER'
export const SEARCH_QUERY = 'SEARCH_QUERY'
export const SEARCH_INIT = 'SEARCH_INIT'

/* Quota actions */
export const QUOTA_REQUEST = new API_CALL('QUOTA_REQUEST')

/* Utils */
export const INTERNAL_ERROR = 'INTERNAL_ERROR'
export const DISMISS_ERROR = 'DISMISS_ERROR'
export const DISPLAY_NOTIFICATION = 'DISPLAY_NOTIFICATION'
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION'
export const DISPLAY_CHANGE = '@@DISPLAY_CHANGE'
export const GRECAPTCHA_READY = 'GRECAPTCHA_READY'

/* Version */
export const VERSION_OPEN = 'VERSION_OPEN'
export const VERSION_CLOSE = 'VERSION_CLOSE'
