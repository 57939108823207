import React from 'react'
import PropTypes from 'prop-types'
import { SelectField, MenuItem, Slider, Input } from 'components'
import { FormControl, FormHelperText, InputLabel } from 'components'
import { IconButton, InputAdornment } from 'components'
import { withTheme } from 'components'
import { NavigationExpandLess, NavigationExpandMore } from 'components/icons'
import QuotaBar, { Size, humanSize } from '../quota'

export class QuotaEntry extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            text: props.init ?
                QuotaEntry.humanSize(props.init) : "",
            error: "",
        }
    }

    static humanSize = value =>
        value < 1024 ? value.toString() : humanSize(value)

    handleSliderChange = value => {
        const { min, max } = this.props
        const floor = !!min ? min : 0
        value = Math.floor(floor + value * (max - floor) / 100.0)
        this.setState({
            text: QuotaEntry.humanSize(value)
        })
        this.props.onChange(value)
    }

    handleTextChange = value => {
        const parseSize = size => {
            let value = null
            const match = /^([0-9]*)([,.]([0-9]*)|)$/.exec(size)
            if (match !== null) {
                if (!!match[1]) {
                    if (!!match[2]) {
                        if (!!match[3]) {
                            value = Number(match[1] + '.' + match[3])
                        } else {
                            value = match[1] + '.'
                        }
                    } else {
                        value = Number(match[1])
                    }
                } else {
                    value = ''
                }
            } else {
                value = null
            }
            return value
        }

        const size = parseSize(value)

        this.setState({
            text: size === null ? this.state.text : value + ' Go',
            error: QuotaEntry.error(this.props, size),
        })

        if (size !== null &&
            size + ' Go' !== QuotaEntry.humanSize(this.props.value))
            this.props.onChange(size * Math.pow(1024, 3))
    }

    static error = (props, value) => {
        const { min, max, error } = props
        return !!min && value < min ?
            `Entrez une valeur plus grande que ${QuotaEntry.humanSize(min)}` :
            !!max && value > max ?
            `Entrez une valeur plus petite que ${QuotaEntry.humanSize(max)}` :
            !!error ? error : ""
    }

    static getDerivedStateFromProps = props => {
        return {
            text: QuotaEntry.humanSize(props.value),
            error: QuotaEntry.error(props, props.value),
        }
    }

    render() {
        const { value, min, max, theme, name } = this.props
        const { text, error } = this.state
        const floor = !!min ? min : 0
        const valueG = value / Math.pow(1024, 3)
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflowX: 'hidden',
                }}>
                <div
                    style={{
                        display: 'inline-flex',
                        width: '100%',
                    }}>
                    <Input
                        name={`${name}-input`}
                        id={`${name}-input`}
                        type='text'
                        style={{
                            width: !!max ? '40%' : '100%',
                            textAlign: 'center',
                        }}
                        value={text.split(' ')[0]}
                        endAdornment={
                            <InputAdornment
                                position="end">
                                <span>{text.split(' ')[1]}</span>
                                <IconButton
                                    disabled={
                                        value >= max
                                    }
                                    onClick={() =>
                                        this.handleTextChange(
                                            `${Math.ceil(valueG) > valueG ?
                                                Math.ceil(valueG) :
                                                Math.min(valueG + 1, max / Math.pow(1024, 3))}`
                                        )
                                    }>
                                    <NavigationExpandLess />
                                </IconButton>
                                <IconButton
                                    disabled={
                                        value <= Math.pow(1024, 3)
                                    }
                                    onClick={() =>
                                        this.handleTextChange(
                                            `${Math.floor(valueG) < valueG ?
                                                Math.floor(valueG) :
                                                Math.max(valueG - 1, floor / Math.pow(1024, 3))}`
                                        )
                                    }>
                                    <NavigationExpandMore />
                                </IconButton>
                            </InputAdornment>
                        }
                        onChange={event =>
                            this.handleTextChange(event.target.value)} />
                    {!!max &&
                    <div
                        style={{
                            width: '60%',
                            paddingLeft: '8px',
                            paddingRight: '8px',
                        }}>
                        <Slider
                            style={{
                                marginBottom: '14px',
                                marginTop: '14px',
                            }}
                            name={`${name}-slider`}
                            id={`${name}-slider`}
                            min={0}
                            max={100}
                            step={1}
                            value={
                                value > max ? 100 :
                                value < floor ? 0 :
                                Math.floor((value - floor) * 100 / (max - floor))
                            }
                            onChange={(event, value) =>
                                this.handleSliderChange(value)
                            } />
                    </div>}
                </div>
                <div
                    style={{
                        width: '100%',
                        textAlign: 'center',
                        color: theme.palette.error.main,
                        paddingBottom: '5px',
                    }}>
                    {error}
                </div>
            </div>
        )
    }
}

export class QuotaField extends React.Component {
    handleChange = (event, key, payload) => {
        const user = this.props.users.find(user => user.id === payload)
        if (this.props.value.user !== user.id) {
            const value =
                user.quota === 0 ?
                10 * Math.pow(1024, 3) :
                user.available
            this.props.onChange({user: payload, value})
        }
    }

    render() {
        const {
            users, name, label, value, error, theme, color=null, min=null,
        } = this.props
        const selectedUser = users.find(user => user.id === value.user)
        return (
            <div>
                {users.length > 1 &&
                <FormControl
                  disabled={users.length <= 1}
                  fullWidth={true}>
                  <InputLabel id={`label-${name}`}>{label}</InputLabel>
                  <SelectField
                      labelId={`label-${name}`}
                      name={name}
                      id={name}
                      value={value.user}
                      onChange={this.handleChange}>
                    {users.map(user => (
                        <MenuItem
                            key={name + '-' + user.id.toString()}
                            value={user.id}
                            disabled={user.quota !== 0 && user.available <= 0}>
                                <div style={{
                                        width: '100%',
                                        display: 'inline-flex',
                                        alignItems: 'flex-start',
                                        flexGrow: 1,
                                        whiteSpace: 'pre',
                                    }}>
                                    <span style={{
                                            fontStyle: 'italic'
                                        }}>
                                        {user.login}
                                    </span>
                                    {' - '}
                                    <span>
                                        {user.quota === 0 ?
                                            'Illimité' :
                                            <Size
                                                value={user.available}
                                                text={'disponible'} />
                                        }
                                    </span>
                                </div>
                              </MenuItem>))}
                </SelectField>
                <FormHelperText>{
                  typeof(error.user) !== 'undefined' ?
                  error.user[0] : ''
                }</FormHelperText>
              </FormControl>}
                {!min ||
                    selectedUser.quota === 0 ||
                    selectedUser.available > min ?
                    <div>
                        {selectedUser.quota !== 0 &&
                        <QuotaBar
                            label={{value: label, max: 'disponible'}}
                            color={color}
                            max={selectedUser.available}
                            value={value.value} />}
                        <QuotaEntry
                            theme={theme}
                            name={`${name}-quota`}
                            error={
                                typeof(error.quota) !== 'undefined' ?
                                error.quota[0] : ''
                            }
                            init={value.value}
                            min={min}
                            max={selectedUser.available}
                            onChange={value =>
                                this.props.onChange({ value })
                            }
                            value={value.value} />
                    </div> :
                    <div
                        style={{
                            color: theme.palette.error.main,
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}>
                        {users.length > 1 ?
                            `Le groupe "${selectedUser.id}" n'a pas assez d'espace disponible ` :
                            `Vous n'avez plus assez d'espace disponible `}
                        par rapport à l'utilisation de la boîte mail.
                    </div>}
            </div>
        )
    }
}

QuotaField.propTypes = {
    users: PropTypes.arrayOf(
        PropTypes.shape({
            quota: PropTypes.number.isRequired,
            available: PropTypes.number,
            id: PropTypes.number.isRequired,
            login: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    value: PropTypes.shape({
        user: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    }),
    error: PropTypes.shape({
        user: PropTypes.arrayOf(PropTypes.string.isRequired),
        value: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
    onChange: PropTypes.func,
    label: PropTypes.string.isRequired,
    name:  PropTypes.string.isRequired,
    min: PropTypes.number,
    theme: PropTypes.object.isRequired,
}

export default withTheme(QuotaField)
