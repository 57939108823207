import React from 'react'
import PropTypes from 'prop-types'
import { Button as RaisedButton } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import TextField from './textfield'
import QuotaField from './quotafield'
import HiddenField from './hiddenfield'
export { HiddenField, QuotaField, TextField }

export class FormComponent extends React.Component {
    constructor(props) {
        super(props)
        const state = {
            error: '',
            reset: false,
            validating: false,
            fields: {},
            captcha: null,
        }

        this.state = FormComponent.handleDefaults(props, state)
    }

    static handleDefaults = (props, state) => {
        const defaults =
            typeof(props.defaults) !== 'undefined' &&
            props.defaults !== null ?
            props.defaults :
            {}
        return props.children.reduce((state, field) => {
            if (!field) return state
            if (typeof(field.props.name) !== 'undefined') {
                const value =
                    !state.fields[field.props.name] ||
                    state.fields[field.props.name].pristine ?
                    typeof(defaults[field.props.name]) !== 'undefined' ?
                    defaults[field.props.name] : '' :
                    state.fields[field.props.name].value
                const def = defaults[field.props.name]
                const errors = props.errors ?
                    props.errors[field.props.name] :
                    undefined
                const pristine = (
                    typeof(def) === 'undefined' && (
                        (
                            !state.fields[field.props.name] ||
                            state.fields[field.props.name].pristine
                        ) && !errors
                    )
                ) || (
                    (
                        field.type === QuotaField ?
                        value.user === def.user &&
                        value.value === def.value :
                        value === def
                    ) && (
                        field.type === QuotaField ?
                        !errors || !errors.value || !errors.user :
                        !errors
                    )
                )
                return {
                    ...state,
                    fields: {
                        ...state.fields,
                        [field.props.name]: {
                            ...state.fields[field.props.name],
                            value,
                            pristine,
                            error: pristine || !state.fields[field.props.name] ?
                                field.type === QuotaField ? {} : errors ? errors[0] : '' :
                                state.fields[field.props.name].error,
                        }
                    },
                }
            } else {
                return state
            }
        }, state)
    }

    renderCaptcha = () => {
        if (this.props.captcha && !this.state.captcha) {
            try {
                this.setState({ captcha: window.grecaptcha.render(this.captchaRef, {
                    sitekey: this.props.captcha,
                    size: 'invisible',
                    callback: token => {
                        this.submit(token)
                    }
                }) })
            } catch (except) {
                console.error("exception rendering reCAPTCHA", except)
            }
        }
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (typeof(nextProps.errors) !== 'undefined' &&
            nextProps.errors !== null) {
            const newState = {}
            if (state.validating && nextProps.validated) {
                if (typeof(nextProps.errors) === 'object') {
                    Object.keys(state.fields).forEach(field => {
                        const error = nextProps.errors[field]
                        if (typeof(error) !== 'undefined') {
                            if (error instanceof Array) {
                                newState.fields = {
                                    ...newState.fields,
                                    [field] : {
                                        ...state.fields[field],
                                        error: nextProps.errors[field][0]
                                    }
                                }
                            } else {
                                newState.fields = {
                                    ...newState.fields,
                                    [field] : {
                                        ...state.fields[field],
                                        error: nextProps.errors[field]
                                    }
                                }
                            }
                            if (!!newState.fields[field].error) {
                                newState.fields[field].pristine = false
                            }
                        }
                    })
                    newState.fields = {
                        ...state.fields,
                        ...newState.fields,
                    }
                } else {
                    newState.error = nextProps.errors
                }
                newState.validating = false
            }

            return FormComponent.handleDefaults(nextProps, {...state, ...newState})
        } else {
          return null
        }
    }

    handleChange = (field, newValue) => {
        let { value, pristine } = this.state.fields[field.props.name]
        let justChecking = typeof(newValue) === 'undefined'

        if (justChecking) {
            newValue = value
            pristine = false
        }

        const transform =
            typeof(field.props.transform) === 'undefined' ?
            value => value :
            field.props.transform
        newValue = transform(newValue)

        let checks = field.props.check
        if (!(checks instanceof Array)) {
            checks = [field.props.check]
        }

        let newError = ''
        checks.forEach(check => {
            if (newError === '') {
                if (field.type === QuotaField) {
                    if (newValue.user === 0) {
                        newError = 'Choisissez un groupe de quota'
                    }
                } else if (typeof(check) === 'function') {
                    const fields = this.state.fields
                    newError = check(
                        newValue,
                        Object.keys(fields).reduce(
                            (acc, name) => ({
                                ...acc,
                                [name]:
                                    fields[name].error === '' ?
                                    fields[name].value :
                                    null
                            }), {}
                        )
                    )
                } else if (check instanceof RegExp) {
                    if (!check.test(newValue)) {
                        newError = 'Valeur invalide'
                    }
                } else if (typeof(check) === 'string') {
                    if (check !== newValue) {
                        newError = 'Valeur différente'
                    }
                }
            }
        })

        if (newError === '') {
            value = newValue
        }

        if (field.type === QuotaField) {
            pristine = newValue.user === (
                (
                    this.props.defaults &&
                    this.props.defaults[field.props.name] &&
                    this.props.defaults[field.props.name].user
                ) || 0
            ) && newValue.value === (
                (
                    this.props.defaults &&
                    this.props.defaults[field.props.name] &&
                    this.props.defaults[field.props.name].value
                ) || 0
            )
        } else {
            pristine = newValue === (
                (
                    this.props.defaults &&
                    this.props.defaults[field.props.name]
                ) || ''
            )
        }

        if (pristine && !justChecking && newError !== '') {
            newError = ''
        }

        const newState = {
            ...this.state.fields[field.props.name],
            pristine,
            error: field.type === QuotaField ? {user: [newError]} : newError,
        }
        if (!justChecking) {
            if (field.type === QuotaField) {
                newState.value = {
                    ...this.state.fields[field.props.name].value,
                    ...newValue,
                }
            } else {
                newState.value = newValue
            }
        }
        this.setState((state, props) => ({
            ...state,
            fields: {
                ...state.fields,
                [field.props.name]: {
                    ...state.fields[field.props.name],
                    ...newState
                },
            },
            error: '',
        }))
        return newError
    }

    submit = captcha => {
        const data = Object.keys(this.state.fields).reduce((acc, name) => ({
            ...acc,
            [name]: this.state.fields[name].value
        }), this.props.captcha ? { captcha } : {})
        if (this.props.captcha) {
            this.props.onSubmit(data, this.captcha)
        } else {
            this.props.onSubmit(data)
        }
    }

    handleSubmit = event => {
        event.preventDefault()
        const valid = Object.keys(this.state.fields).reduce((acc, name) => {
            const child = this.props.children.find(
                child => child.props && child.props.name === name
            )
            const valid = this.handleChange(child) === ''
            return acc && valid
        }, true)

        if (valid) {
            this.setState(state => ({...state, validating: true}))
            if (this.props.captcha) {
                window.grecaptcha.execute(this.captcha)
            } else {
                this.submit()
            }
        } else {
            this.setState(state => ({
                ...state,
                error: 'Veuillez corriger les erreurs'
            }))
        }
    }

    componentDidMount() {
        if(this.state.captcha===null&&this.props.grecaptcha.ready)
            this.renderCaptcha()
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.grecaptcha.ready && !prevProps.grecaptcha.ready)||(this.props.grecaptcha.ready&&this.state.captcha===null)) {
            this.renderCaptcha()
        }
    }

    componentWillUnmount() {
        if (this.props.captcha && this.props.grecaptcha.ready && this.state.captcha!==null) {
            window.grecaptcha.reset(this.state.captcha)
        }
    }

    render() {
        const {
            children, disabled=false, captcha=false, theme
        } = this.props
        const child =
            <div>
                {this.state.error !== '' &&
                    <div style={{
                            color: theme.palette.error.main,
                            width: '100%',
                            textAlign: 'center',
                            fontWeight: 'bold'
                        }}>
                        {this.state.error}
                    </div>}
                {captcha && <div ref={ref => {this.captchaRef = ref}} />}
                <fieldset
                    style={{
                        display: 'block',
                        padding: '5px',
                    }}
                    disabled={disabled}>
                    {React.Children.map(children, child => {
                        if (!child) return child
                        const field = child.props
                        const { value, error, pristine } =
                            typeof(field.name) === 'undefined' ?
                            {} :
                            this.state.fields[field.name]
                        const key = `form-${
                            typeof(field.name) === 'undefined' ?
                            child : field.name
                        }`
                        return [ QuotaField, TextField, HiddenField ].indexOf(
                            child.type
                        ) !== -1 ?
                            React.cloneElement(child, {
                                onChange: newValue => {
                                    this.handleChange(
                                        child, newValue,
                                    )
                                },
                                value,
                                pristine,
                                error: this.state.validating ?
                                    child.type === QuotaField ?
                                    {} : '' : error,
                                key,
                            }) : child.type === RaisedButton ?
                            React.cloneElement(child, {
                                disabled: child.props.disabled ||
                                    (captcha && (
                                        !this.props.grecaptcha.ready ||
                                        this.state.validating
                                    ))
                            }) : child
                    })}
                </fieldset>
            </div>
        return (
            <form onSubmit={this.handleSubmit}>
                {child}
            </form>
        )
    }
}

FormComponent.propTypes = {
    captcha: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    theme: PropTypes.object.isRequired,
    defaults: PropTypes.object,
    validated: PropTypes.bool.isRequired,
    errors: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    noaction: PropTypes.bool,
}

const ConnectedForm = connect(
    state => ({grecaptcha: state.grecaptcha})
)(FormComponent)

const ThemedForm = withTheme(ConnectedForm)

export { ThemedForm as Form }

export default ThemedForm
