import React, { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ACCEPT_CGV } from 'actions'
import { Grid, Paper, List, ListItem } from 'components'
import { Icon, Button, Checkbox } from 'components'
import { D, fmt } from './utils'
import { useAccount, useDisplay, useCart } from './hooks'
import PaymentMethods from './methods'
import { AddCard, ConfirmCvc, ValidatePayment } from './card'
import css from './payment.module.css'

export const useActions = () => {
    const dispatch = useDispatch()
    return {
        setCgv: useCallback(accepted => dispatch({
            type: ACCEPT_CGV,
            accepted,
        }), [dispatch]),
    }
}

export const CartContent = ({ items, title, total, amount }) =>
    <Paper elevation={3}>
        <List dense>
            <ListItem
                classes={{root: css.headerRoot}}
                textClasses={{primary: css.header}}
                primaryText={title || "Votre panier"} />
            {items.sort((a, b) => a.renewal + a.redeem * 2 + a.outlay * 4 - b.renewal - b.redeem * 2 - b.outlay * 4).map(item =>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                    }}
                    key={item.id}>
                    <div style={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                        <ListItem
                            component="div"
                            icon={<Icon>{item.product.icon}</Icon>}
                            primaryText={
                                item.product.name
                            }
                            secondaryText={
                                !!item.description ? item.description :
                                `du ${D(item.from_field)} au ${D(item.to)}`
                            } />
                        <ListItem
                            component="div"
                            primaryText={!item.outlay ? `x ${Math.abs(item.count)}`: ''} />
                    </div>
                    <div>
                        <ListItem
                            component="div"
                            primaryText={
                                fmt(item.amount / 100.0)
                            } />
                    </div>
                </div>
            )}
            <ListItem
                key="total"
                textClasses={{primary: css.total}}
                primaryText={total || "Total du panier"}
                action={
                    <ListItem
                        component='div'
                        textClasses={{primary: css.total}}
                        primaryText={fmt(amount / 100)} />
                } />

        </List>
    </Paper>

export const Payment = ({ setDisabled, setUpdating, actions: { confirmCvc, changeStep, validatePayment } }) => {
    const cart = useCart()
    const account = useAccount()
    const methods = cart.methods
    const billing = {
        name: `${account.firstname} ${account.lastname}`,
        email: account.email,
        ...account.billing
    }
    const display = useDisplay()
    const { setCgv } = useActions()
    const history = useHistory()
    useEffect(() => setDisabled(!cart.cgv), [setDisabled, cart.cgv])
    useEffect(() => () => setCgv(false), [setCgv])
    return (
        <Grid container spacing={3} style={
                display === 'desktop' ? {marginTop: '10px'} : {}
            }>
            <Grid item xs={12} sm={6}>
                <CartContent items={cart.items} amount={cart.amount} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Paper elevation={3}>
                    <List dense>
                        <ListItem
                            classes={{root: css.headerRoot}}
                            textClasses={{primary: css.header}}
                            primaryText={"Informations de facturation"}
                            action={
                                <Button
                                    label="Modifier"
                                    primary
                                    onClick={() => history.push('/account/edit')}
                                    startIcon={<Icon>edit</Icon>} />
                            } />
                        <ListItem
                            textClasses={{primary: css.header}}
                            icon={<Icon>{billing.company ? 'domain' : 'person'}</Icon>}
                            primaryText={billing.company || billing.name} />
                        <ListItem
                            icon={<Icon>location_city</Icon>}
                            primaryText={
                                <span>
                                    {billing.first_line}<br/>
                                    {billing.second_line &&
                                        <span>
                                            {billing.second_line}<br/>
                                        </span>}
                                    {`${billing.zipcode} ${billing.town}`}
                                </span>} />
                        <ListItem
                            icon={<Icon>mail</Icon>}
                            primaryText={billing.email} />
                        <ListItem
                            icon={<Icon>phone</Icon>}
                            primaryText={billing.phone_number} />
                    </List>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={3}>
                    <ListItem
                        classes={{root: css.headerRoot}}
                        textClasses={{primary: css.header}}
                        primaryText={"Choisissez votre moyen de paiement"}
                        />
                    <PaymentMethods />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Checkbox
                    checked={cart.cgv}
                    label={
                        <span>
                            En cochant cette case, je reconnais avoir lu et j'accepte les <a href="https://soeasyto.com/cgv.pdf" rel="noopener noreferrer" title="CGV soeasyto.com" target="_blank">conditions générales de ventes</a> de soeasyto
                        </span>
                    }
                    onClick={() => setCgv(!cart.cgv)} />
            </Grid>
            {cart.addCard &&
            <AddCard
                open={cart.addCard}
                validatePayment={validatePayment} />}
            {cart.confirmCvc &&
            <ConfirmCvc
                open={cart.confirmCvc}
                cgvDate={cart.cgvDate}
                changeStep={changeStep}
                method={methods.find(method => method.selected)}
                amount={fmt(cart.amount / 100)}
                onClose={() => {
                    confirmCvc(false)
                }}
                validatePayment={validatePayment} />}
            {cart.validation &&
                <ValidatePayment
                    changeStep={changeStep} />}
        </Grid>
    )
}

export default Payment
