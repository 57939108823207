import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Popup } from 'containers'
import { Form, TextField, HiddenField } from 'components/form'
import { RaisedButton, CircularProgress, Dialog } from 'components'
import { NavigationCheck, ActionAccountCircle } from 'components/icons'
import { EDIT_PROFILE } from 'actions'
import {
    checkKey, onUpdate, onConfirmPassword, onCreatePassword
} from './actions'
export { default as reducer } from './reducer'

const Account = ({ user, account, onUpdate, onConfirmPassword, onCreatePassword, display, history, checking }) => {
    const onDone = () => history.push('/account/profile')
    let defaults = {...user}
    let errors = {}
    if (typeof user.billing !== 'undefined' && user.billing !== null) {
        defaults = {
            ...defaults,
            ...user.billing,
        }
    } else if (Object.keys(account.errors).length === 0) {
        errors.phone_number = ["Ce champ est requis"]
        errors.first_line = ["Ce champ est requis"]
        errors.zipcode = ["Ce champ est requis"]
        errors.town = ["Ce champ est requis"]
    }
    if (typeof user.to_confirm !== 'undefined' && user.to_confirm !== null) {
       defaults.email = user.to_confirm
       errors.email = ["En cours de confirmation, revalidez pour renvoyer la confirmation"]
    }
    errors = {...errors, ...account.errors}
    return <>
        {!checking && !account.create_password && !account.confirm_mailbox &&
            <Popup
                icon={<ActionAccountCircle />}
                color='#297ee6'
                onClose={onDone}
                title={"Compte"}>
                <Form
                    validated={!account.loading}
                    defaults={defaults}
                    onSubmit={userInfo => onUpdate(userInfo, onDone)}
                    errors={errors}>
                    <TextField
                        name='firstname'
                        autoComplete='given-name'
                        required
                        label='Prénom' />
                    <TextField
                        name='lastname'
                        required
                        autoComplete='family-name'
                        label='Nom' />
                    <TextField
                        name='email'
                        required
                        autoComplete='home email'
                        label='Email'
                        type='email' />
                    <TextField
                        name='company'
                        autoComplete='billing organization'
                        label='Société (optionnel)' />
                    <TextField
                        name='phone_number'
                        required
                        autoComplete='home tel'
                        label='Téléphone (0123456789)'
                        check={value => !!value ? /^(\+[1-9][0-9]{1,2}|0)[12345679][0-9]+$/.test(value) ? "" : "Numéro de téléphone invalide" : "Ce champ est requis"} />
                    <TextField
                        name='first_line'
                        required
                        autoComplete='billing address-line1'
                        label={'Première ligne d\'adresse'}
                        check={value => !!value ? "" : "Ce champ est requis"} />
                    <TextField
                        name='second_line'
                        autoComplete='billing address-line2'
                        label={'Seconde ligne d\'adresse'} />
                    <TextField
                        name='zipcode'
                        required
                        autoComplete='billing postal-code'
                        label='Code postal (00000)'
                        check={value => !!value ? /^[0-9]{5}$/.test(value) ? "" : "Code postal invalide" : "Ce champ est requis"} />
                    <TextField
                        name='town'
                        label='Ville'
                        required
                        autoComplete='billing address-level2'
                        check={value => !!value ? /^[-a-z ]+$/i.test(value) ? "" : "Nom de ville incorrect" : "Ce champ est requis"} />
                    <RaisedButton
                        label={'Mettre à jour'}
                        startIcon={
                            account.loading ?
                            <CircularProgress
                                size={24}
                                mode='indeterminate' /> :
                            <NavigationCheck
                                size={24} />
                        }
                        disabled={account.loading}
                        primary
                        fullWidth
                        type='submit' />
                </Form>
            </Popup>}
        {!!account.confirm_mailbox &&
            <Dialog
                title="Confirmation de mot de passe"
                open={true}>
                <div>
                    Vous avez choisi l'une de vos boîtes mails comme identifiant.<br/>
                    Votre mot de passe pour accéder à la gestion de votre compte soeasyto deviendra alors identique à celui de votre boîte mail.<br/>
                    Veuillez-donc le confirmer ou bien annuler la modification:
                </div>
                <Form
                    validated={!account.loading}
                    defaults={{confirm_mailbox: account.confirm_mailbox}}
                    errors={errors}
                    onSubmit={data => onConfirmPassword(history, data, onDone)}>
                <TextField
                    required
                    name='confirm_password'
                    label={'Confirmez le mot de passe'}
                    type='password'
                    fullwidth
                    />
                <HiddenField
                    name='confirm_mailbox' />
                <RaisedButton
                    label={'Confirmer'}
                    startIcon={
                        account.loading ?
                        <CircularProgress
                            size={24}
                            mode='indeterminate' /> :
                        <NavigationCheck
                            size={24} />}
                    disabled={account.loading}
                    primary
                    fullWidth
                    type='submit' />
                </Form>
            </Dialog>}
        {!!account.create_password &&
            <Dialog
                title="Création de mot de passe"
                open={true}>
                <div>
                Vous avez choisi une nouvelle adresse mail non gérée par votre compte ({user.to_confirm}).<br/>
                Vous devez donc définir un nouveau mot de passe associé à votre compte soeasyto.
                </div>
                <Form
                    validated={!account.loading}
                    defaults={{confirm_mailbox: account.confirm_mailbox}}
                    errors={errors}
                    onSubmit={data => onCreatePassword(history, data, onDone)}>
                <TextField
                    name='confirm_password'
                    label={'Mot de passe actuel'}
                    required
                    type='password'
                    fullwidth />
                <TextField
                    name='new_password'
                    label={'Nouveau mot de passe'}
                    required
                    type='password'
                    fullwidth />
                <TextField
                    name='new_password_confirmation'
                    required
                    label={'Confirmez le nouveau mot de passe'}
                    type='password'
                    fullwidth />
                <RaisedButton
                    label={'Créer le mot de passe'}
                    startIcon={
                        account.loading ?
                        <CircularProgress
                            size={24}
                            mode='indeterminate' /> :
                        <NavigationCheck
                            size={24} />
                    }
                    disabled={account.loading}
                    primary
                    fullWidth
                    type='submit' />
                </Form>
            </Dialog>}
    </>
}

class AccountWrapper extends React.Component {
    state = { checking: true }

    componentDidMount() {
        const { match: { params }, checkKey, history } = this.props
        this.props.onEditProfile()
        if (!!params.key) {
            checkKey(history, params, () => this.setState({ checking: false }))
        } else {
            this.setState({ checking: false })
        }
    }

    componentWillUnmount() {
        this.props.onEditProfileEnd()
    }

    render() {
        const { checking } = this.state
        return <Account checking={checking} {...this.props} />
    }
}

const ConnectedAccount = connect(
    state => ({
        user: state.app.user,
        display: state.app.display,
        account: state.account.edit,
    }), {
        onUpdate, onConfirmPassword, onCreatePassword, checkKey,
        onEditProfile: () => ({ type: EDIT_PROFILE, editing: true }),
        onEditProfileEnd: () => ({ type: EDIT_PROFILE, editing: false }),
    },
)(withRouter(AccountWrapper))

export default ConnectedAccount
