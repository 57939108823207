import {
    LOGOUT, MAIL_ADD, ALIAS_ADD, MAIL_RECEIVE, MAIL_REMOVE, ALIAS_REMOVE,
} from 'actions'

export const init = {
    existing: [],
}

export const reducer = (state=init, action) => {
    switch(action.type) {
        case MAIL_RECEIVE.SUCCESS:
            return {
                ...state,
                existing: [
                    ...action.payload.mailboxes.map(
                        mailbox => mailbox.username.replace(/\./g, '')
                    ),
                    ...action.payload.mailaliases.map(
                        alias => alias.from_field.replace(/\./g, '')
                    )
                ]
            }
        case MAIL_ADD.SUCCESS:
            return {
                ...state,
                existing: [
                    ...state.existing,
                    action.payload.username.replace(/\./g, '').toLowerCase(),
                ]
            }
        case MAIL_REMOVE.SUCCESS:
            return {
                ...state,
                existing: state.existing.filter(
                    existing => existing !== action.payload.username,
                )
            }
        case ALIAS_ADD.SUCCESS:
            return {
                ...state,
                existing: [
                    ...state.existing,
                    action.payload.from_field.replace(/\./g, '').toLowerCase(),
                ]
            }
        case ALIAS_REMOVE.SUCCESS:
            return {
                ...state,
                existing: state.existing.filter(
                    existing => existing !== action.payload.from_field,
                )
            }
        case LOGOUT.REQUEST:
        case MAIL_RECEIVE.FAILURE:
            return {
                ...init,
            }
        default:
            return state
    }
}
