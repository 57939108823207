import {
    API_CALL, DISPLAY_NOTIFICATION, MAIL_ADD, MAIL_UPDATE, CART_ADD,
} from 'actions'
import { pendingCheck } from '../../actions'

export const onClose = (history, domain) => () => history.push(`/${domain}`)

export const onStorage = history => () => history.push('/usage/add')

export const onAdd = (history, domain, mailbox) => ({
    type: MAIL_ADD.action,
    [API_CALL]: {
        request: {
            url: `/mails/${domain}/add`,
            body: mailbox,
        },
        onsuccess: ({ dispatch, payload }) => {
            pendingCheck.add([payload], dispatch, domain)
            history.push(`/${domain}`)
            dispatch({
                type: DISPLAY_NOTIFICATION,
                message:
                    `Mailbox ${mailbox.username}@${domain} créée avec succès`
            })
        }
    }
})

export const onCartAdd = (history, domain) => ({
    type: CART_ADD.action,
    [API_CALL]: {
        request: {
            url: '/billing/cart/add',
            body: [{ product: 'Mailbox', count: 1 }],
        },
        onsuccess: () => history.push(`/cart`)
    }
})

export const onUpdate = (history, domain, mailbox, changes) => ({
    type: MAIL_UPDATE.action,
    [API_CALL]: {
        request: {
            url: `/mails/${domain}/update`,
            body: {
                username: mailbox.username,
                ...changes,
            },
        },
        onsuccess: ({ dispatch }) => {
            history.push(`/${domain}`)
            dispatch({
                type: DISPLAY_NOTIFICATION,
                message: `Mailbox ${mailbox.username}@${domain}` +
                    ' mise à jour avec succès'
            })
        }
    }
})
