import React from 'react'
import { connect } from 'react-redux'
import { RaisedButton } from 'components'
import { Form, TextField } from 'components/form'
import {
    API_CALL, FIRST_BILLING, FIRST_ACCOUNT_DATA,
} from 'actions'

const init = {
    errors: null,
    loading: false,
    email: null,
    confirmation_key: null,
}

export const reducer = (state=init, action) => {
    switch (action.type) {
        case FIRST_BILLING.SUCCESS:
            return init
        case FIRST_ACCOUNT_DATA:
            return {
                ...state,
                email: action.account.email,
                confirmation_key: action.key,
            }
        case FIRST_BILLING.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FIRST_BILLING.FAILURE:
            return {
                ...state,
                errors: action.error.message,
                loading: false,
            }
        default:
            return state
    }
}

const onSubmit = (email, key, data, history) => ({
    type: FIRST_BILLING.action,
    [API_CALL]: {
        request: {
            url: `/accounts/billing`,
            body: {
                ...data,
                key,
                email,
            }
        },
        onsuccess: ({ dispatch, payload }, success) => {
            history.push('/login/first/storage')
            dispatch({
                type: FIRST_ACCOUNT_DATA,
                account: payload.account,
                key: key,
            })
            success()
        },
    }
})

const BillingForm = ({ errors, loading, onSubmit, email, confirmation_key, history }) =>
    <Form
        errors={errors}
        disabled={loading}
        validated={!loading}
        onSubmit={data => onSubmit(email, confirmation_key, data, history)}>
        <div className='text-center'>
            Veuillez renseigner vos informations de facturation.
        </div>
        <TextField
            name='company'
            autoComplete='billing organization'
            label='Société (optionnel)' />
        <TextField
            name='phone_number'
            label='Téléphone (0123456789)'
            autoComplete='home tel'
            check={value => !!value ? /^(\+[1-9][0-9]{1,2}|0)[12345679][0-9]+$/.test(value) ? "" : "Numéro de téléphone invalide" : "Ce champ est requis"} />
        <TextField
            name='first_line'
            label={'Première ligne d\'adresse'}
            autoComplete='billing address-line1'
            check={value => !!value ? "" : "Ce champ est requis"} />
        <TextField
            name='second_line'
            autoComplete='billing address-line2'
            label={'Seconde ligne d\'adresse'} />
        <TextField
            name='zipcode'
            autoComplete='billing postal-code'
            label='Code postal (00000)'
            check={value => !!value ? /^[0-9]{5}$/.test(value) ? "" : "Code postal invalide" : "Ce champ est requis"} />
        <TextField
            name='town'
            label='Ville'
            autoComplete='billing address-level2'
            check={value => !!value ? /^[-a-z ]+$/i.test(value) ? "" : "Nom de ville incorrect" : "Ce champ est requis"} />
        <br/>
        <RaisedButton
            type='submit'
            label='Suivant'
            disabled={loading}
            primary
            fullWidth />
    </Form>

const ConnectedBillingForm = connect(
    state => state.login.first.identity,
    { onSubmit },
)(BillingForm)

export default ConnectedBillingForm
