import React from 'react'
import { Step, Stepper, StepLabel } from 'components'

const steps = ['add', 'validate']

const AliasSteps = ({ step }) =>
    <Stepper activeStep={steps.indexOf(step)}>
        <Step>
            <StepLabel>Ajout</StepLabel>
        </Step>
        <Step>
            <StepLabel>Validation</StepLabel>
        </Step>
    </Stepper>

export default AliasSteps
