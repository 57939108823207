import React from 'react'
import { connect } from 'react-redux'
import { Dialog, Button, RaisedButton, CircularProgress, withTheme } from 'components'
import { NavigationCheck } from 'components/icons'
import colors from 'components/colors'
import { Form, TextField } from 'components/form'
import { API_CALL, DISPLAY_NOTIFICATION, MAIL_REMOVE } from 'actions'
import { pendingCheck } from '../actions'

const prevent = cb => event => {
    event.preventDefault()
    event.stopPropagation()
    cb()
}

const onRemove = (history, domain, mailbox, confirmation) => ({
    type: MAIL_REMOVE.action,
    [API_CALL]: {
        request: {
            url: `/mails/${domain}/remove`,
            body: {
                mailbox,
                confirmation,
            }
        },
        onsuccess: ({ dispatch, payload }) => {
            history.push(`/${domain}`)
            dispatch({
                type: DISPLAY_NOTIFICATION,
                message: `Boîte mail ${payload.username}@${domain} supprimée ` +
                    'avec succès'
            })
            pendingCheck.remove(mailbox)
        }
    }
})

const init = {
    loading: false,
    errors: {},
}

export const reducer = (state=init, action) => {
    switch (action.type) {
        case MAIL_REMOVE.FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.error.errors,
            }
        case MAIL_REMOVE.SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case MAIL_REMOVE.REQUEST:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
export const creators = {
    remove: (history, domain, mailbox) => dispatch =>
        mailbox.installed ?
        history.push(`/${domain}/mail/${mailbox.username}/remove`) :
        dispatch(onRemove(history, domain, mailbox.id, `${mailbox.username}@${domain}`))
}

const MailboxRemove = ({
    match: { params }, onRemove, onCancel, theme, loading, history, mailboxes, errors
}) =>
    <Dialog
        open
        title="Suppression d'une boîte mail"
        className='text-center'>
        <div>
        {'Êtes-vous sûr de vouloir supprimer la boîte mail '}
        <span style={{
                fontWeight: 'bold',
                color: theme.palette.primary.main,
            }}>{`${params.mailbox}@${params.domain}`}</span>{' ?'}
        </div>
        <div style={{fontWeight: 'bold', color: colors.red400, marginTop: '1em'}}>
            Cette opération est irréversible.<br/>
            Toutes les données associées seront supprimées.
        </div>
        <div style={{fontStyle: 'italic', marginTop: '1em'}}>
            Si vous en êtes sûr, veuillez recopier l'addresse de la boîte mail
            à supprimer dans le champ ci-dessous.
        </div>
        <Form
            errors={errors}
            validated={!loading}
            theme={theme}
            onSubmit={data => onRemove(history, params.domain, (mailboxes.find(mbox => mbox.username === params.mailbox) || {}).id, data.confirmation)}>
            <TextField
                label="Confirmation du nom de la boîte mail"
                type='email'
                name="confirmation" />
            <div style={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    width: '100%',
                }}>
                <Button
                    label="Annuler"
                    primary
                    outlined
                    onClick={prevent(() =>
                        onCancel(history, params.domain)
                    )} />
                <RaisedButton
                    startIcon={
                        loading ?
                        <CircularProgress
                            size={24}
                            mode='indeterminate' /> :
                        <NavigationCheck
                            size={24} />
                    }
                    disabled={loading}
                    label="Supprimer"
                    secondary
                    type='submit' />
            </div>
        </Form>
    </Dialog>

const ConnectedMailboxRemove = connect(
    state => ({
        ...state.domains.mail.mailbox.remove,
        mailboxes: state.domains.mail.mailbox.root.mailboxes,
    }), {
        onCancel: (history, domain) => () =>
            history.push(`/${domain}`),
        onRemove,
    }
)(MailboxRemove)

const ThemedMailboxRemove = withTheme(ConnectedMailboxRemove)

export default ThemedMailboxRemove
