import React from 'react'
import { connect } from 'react-redux'
import { RaisedButton } from 'components'
import { Form, TextField } from 'components/form'
import {
    API_CALL, DISPLAY_NOTIFICATION, FIRST_IDENTITY, FIRST_ACCOUNT_DATA, LOGIN,
} from 'actions'

const init = {
    errors: null,
    loading: false,
    email: null,
    confirmation_key: null,
}

export const reducer = (state=init, action) => {
    switch (action.type) {
        case FIRST_IDENTITY.SUCCESS:
            return init
        case FIRST_ACCOUNT_DATA:
            return {
                ...state,
                email: action.account.email,
                confirmation_key: action.key,
            }
        case FIRST_IDENTITY.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FIRST_IDENTITY.FAILURE:
            return {
                ...state,
                errors: action.error.message,
                loading: false,
            }
        default:
            return state
    }
}

const onSubmit = (email, key, data, history) => ({
    type: FIRST_IDENTITY.action,
    [API_CALL]: {
        request: {
            url: `/accounts/identity`,
            body: {
                ...data,
                key,
                email,
            }
        },
        onsuccess: ({ dispatch, payload }, success) => {
            dispatch({
                type: LOGIN.SUCCESS,
                payload,
            })
            history.push('/')
            success()
            dispatch({
                type: DISPLAY_NOTIFICATION,
                message: 'Compte créé avec succès',
            })
        },
    }
})

const IdentityForm = ({ errors, loading, onSubmit, email, confirmation_key, history }) =>
    <Form
        errors={errors}
        disabled={loading}
        validated={!loading}
        onSubmit={data => onSubmit(email, confirmation_key, data, history)}>
        <div className='text-center'>
            Merci d'indiquer vos nom et prénom afin
            de finaliser votre inscription.
        </div>
        <TextField
            fullwidth
            name='firstname'
            autoComplete='given-name'
            label='Prénom' />
        <TextField
            fullwidth
            name='lastname'
            autoComplete='family-name'
            label='Nom' />
        <br/>
        <RaisedButton
            type='submit'
            label='Terminé'
            disabled={loading}
            primary
            fullWidth />
    </Form>

const ConnectedIdentityForm = connect(
    state => state.login.first.identity,
    { onSubmit },
)(IdentityForm)

export default ConnectedIdentityForm
