import React from 'react'
import { connect } from 'react-redux'
import { IconButton } from 'components'
import { VERSION_OPEN } from 'actions'
import logo from './icon.svg'
import css from './icon.module.css'

const Icon = ({
    onClick, loading, size=72, disabled=false, iconStyle=null, style={}
}) => {
    const icon =
        <img
            src={logo}
            alt="Logo"
            style={{
                width: `${size}px`,
                height: `${size}px`,
                ...iconStyle === null ? style : iconStyle,
            }}
            className={!disabled && loading ? css.loading : ''} />
    if (disabled) {
        return icon
    } else {
        return (
            <div className={css.container}>
                <IconButton
                    style={{
                        padding: 0,
                        width: `${size}px`,
                        height: `${size}px`,
                        fontSize: 'inherit',
                    }}
                    disabled={disabled}
                    onClick={() => onClick()}
                    /*iconStyle={{
                        width: `${size}px`,
                        height: `${size}px`,
                    }}*/>
                    {icon}
                </IconButton>
            </div>
        )
    }
}

const ConnectedIcon = connect(
    state => ({ loading: state.loading.displayed }),
    { onClick: () => ({ type: VERSION_OPEN })}
)(Icon)

export default ConnectedIcon
