import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { Dialog, Paper, List, ListItem } from 'components'
import { Icon, Button, IconButton, Transition } from 'components'
import Table from 'containers/table'
import { NavigationExpandLess, NavigationExpandMore } from 'components/icons'
import { API_CALL, CART_UPDATE, DISPLAY_NOTIFICATION } from 'actions'
import { useCart, useDisplay } from './hooks'
import { D, fmt } from './utils'
import css from './basket.module.css'

const useActions = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    return {
        onItemUpdate: useCallback((body, done) => dispatch({
            type: CART_UPDATE.action,
            [API_CALL]: {
                request: {
                    url: '/billing/cart/update',
                    body,
                },
                onsuccess: ({ payload, dispatch }) => {
                    if (payload.cart.items.length === 0) {
                        dispatch({
                            type: DISPLAY_NOTIFICATION,
                            message: 'Votre panier est vide',
                        })
                        history.push('/')
                    }
                    typeof(done) !== 'undefined' && done()
                }
            },
        }), [dispatch, history]),
    }
}

const Quantity = ({ item, update, onItemUpdate, updating, count, display, max=Infinity, current }) =>
    <>
        {update && display === 'mobile' &&
        <IconButton
            disabled={updating}
            onClick={() =>
                onItemUpdate(0)
            }>
            <Icon>delete</Icon>
        </IconButton>}
        {display === 'mobile' && <div style={{flexGrow: 1}}></div>}
        <ListItem
            dense={!update}
            icon={
                update &&
                <IconButton
                    edge="end"
                    disabled={(!!max && count + 1 >= max) || updating}
                    onClick={
                        () =>
                            onItemUpdate(
                                max ? Math.min(count + 1, max) : count + 1
                            )
                    }>
                    <NavigationExpandLess />
                </IconButton>
            }
            textStyle={!update ? { textAlign: 'center'} : {}}
            primaryText={
                `${update ? '+ ' : ''}${count}${item.unit ? ` ${item.unit}`: ''}`}
            secondaryText={
                typeof(current) !== 'undefined' && !!current &&
                    <i>{`Total: ${current+count} ${item.unit ? ` ${item.unit}` : ''}`}</i>
            }
            action={
                update &&
                <IconButton
                    edge="end"
                    disabled={updating}
                    onClick={
                        () => onItemUpdate(Math.max(count - 1, 0))
                    }>
                    <NavigationExpandMore />
                </IconButton>
            } />
    </>

export const Basket = ({ updating }) => {
    const cart = useCart()
    const display = useDisplay()
    const { onItemUpdate } = useActions()
    const [ confirm, setConfirm ] = useState(false)
    const [ remove, setRemove ] = useState(null)
    const updateCount = id => count => {
        if (count === 0) {
            setRemove(id)
            setConfirm(true)
        } else {
            onItemUpdate(
                { id, count },
            )
        }
    }
    return <>
        <Table
            cols={1}
            headers={['Produit', 'Prix unitaire', 'Quantité', 'Total'].concat(!cart.readonly ? [''] : [])}
            icon={
                ({ product }, index) =>
                    index === 0 ? <Icon>{product.icon}</Icon> : null
            }
            indicators={
                ({ renewal, product }, index) =>
                    index === 4 && !renewal && product.slug !== 'Redeem' && !cart.readonly ?
                    <Icon>delete</Icon> :
                    null
            }
            onClick={
                (item, index ) =>
                    index === 4 && !item.renewal && item.product.slug !== 'Redeem' && !cart.readonly ?
                    updateCount(item.id)(0) :
                    null
            }
            primaryText={
                ({ id, count, product, max, amount, from_field, to, current, renewal, redeem }, index) =>
                    typeof(index) === 'undefined' ?
                    <Paper
                        elevation={3}>
                        <List dense={renewal || redeem}>
                            <ListItem
                                primaryText={product.name}
                                secondaryText={
                                    `${fmt(product.price)}${
                                        product.unit ? ` / ${product.unit}`: ''
                                    }${typeof(to) !== undefined && to !== null ? ' / an' : ''}`
                                }
                                action={
                                    <IconButton>
                                        <Icon>{product.icon}</Icon>
                                    </IconButton>
                                    }
                                />
                            {typeof(to) !== 'undefined' && to !== null &&
                            <ListItem
                                primaryText={
                                    `du ${D(from_field)} au ${D(to)}`
                                } />}
                            <List
                                dense={renewal || redeem}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}>
                                <Quantity
                                    update={!renewal && !redeem && !cart.readonly}
                                    display={display}
                                    item={product}
                                    count={Math.abs(count)}
                                    current={current}
                                    updating={updating}
                                    onItemUpdate={updateCount(id)}
                                    max={max} />
                                <div style={{flexGrow: 1}} />
                                <ListItem
                                    style={{width: 'initial'}}
                                    textStyle={{textAlign: 'right'}}
                                    primaryText={fmt(amount / 100.0)} />
                            </List>
                        </List>
                    </Paper>
                    : index === 0 ?
                    product.name : index === 1 ?
                    `${fmt(product.price)}${
                        product.unit ? ` / ${product.unit}`: ''
                    }${typeof(to) !== 'undefined' && to !== null ? ' / an' : ''}` : index === 2 ?
                    <div
                        style={{display:'flex', justifyContent: 'center', padding: 'auto'}}>
                        <Quantity
                            update={!renewal && !redeem && !cart.readonly}
                            item={product}
                            count={Math.abs(count)}
                            current={current}
                            display={display}
                            updating={updating}
                            onItemUpdate={updateCount(id)}
                            max={max} />
                    </div> : index === 3 ?
                    fmt(amount / 100.0) : null
                }
            styles={{
                0: { textAlign: 'center' },
                1: { textAlign: 'center' },
                2: { textAlign: 'center' },
                3: { textAlign: 'center' },
            }}
            secondaryText={
                ({ from_field, to, description }, index ) =>
                        index === 0 ?
                            !description ?
                                typeof(to) !== 'undefined' && to !== null ?
                                `du ${D(from_field)} au ${D(to)}` : null :
                                description : null}
            itemClass={display === 'mobile' && css.noMargin}
            dataset={cart.items.sort((a, b) => (a.renewal + a.redeem * 2) - (b.renewal + b.redeem * 2)).filter(a => !a.outlay)} />
        {cart.items.some(a => a.outlay) &&
            <Table
            cols={1}
            headers={['Débours', ' ', 'Facture', 'Coût']}
            icon={
                ({ product }, index) =>
                    index === 0 ? <Icon>{product.icon}</Icon> : null
            }
            primaryText={
                ({ id, product, amount, external_bill_id, description }, index) =>
                    typeof(index) === 'undefined' ?
                    <Paper
                        elevation={3}>
                        <List dense>
                            <ListItem
                                primaryText={product.name}
                                secondaryText={
                                    fmt(amount / 100.0)
                                }
                                action={
                                    <IconButton>
                                        <Icon>{product.icon}</Icon>
                                    </IconButton>
                                    }
                                />
                            <ListItem
                                primaryText={
                                    <Link to={`/account/outlay/${external_bill_id}`}>{description}</Link>
                                } />
                            <div style={{flexGrow: 1}} />
                            <ListItem
                                style={{width: 'initial'}}
                                textStyle={{textAlign: 'right'}}
                                primaryText={fmt(amount / 100.0)} />
                        </List>
                    </Paper>
                    : index === 0 ?
                    product.name : index === 2 ?
                        <div
                            style={{display:'flex', justifyContent: 'center', padding: 'auto'}}>
                            <Link to={`/account/outlay/${external_bill_id}`}>{external_bill_id}</Link>
                        </div> : index === 3 ?
                    fmt(amount / 100.0) : ' '
                }
            styles={{
                0: { textAlign: 'center' },
                1: { textAlign: 'center' },
                2: { textAlign: 'center' },
                3: { textAlign: 'center' },
            }}
            secondaryText={
                ({ from_field, to, description }, index ) =>
                        index === 0 ?
                            !description ?
                                typeof(to) !== 'undefined' && to !== null ?
                                `du ${D(from_field)} au ${D(to)}` : null :
                                description : null}
            itemClass={display === 'mobile' && css.noMargin}
            dataset={cart.items.filter(a => a.outlay).sort((a, b) => a.id - b.id)} />}
        <Dialog
            open={confirm}
            title="Êtes-vous sûr ?"
            TransitionComponent={Transition}
            actions={
                <>
                    <Button
                        label="Annuler"
                        disabled={!confirm}
                        secondary
                        onClick={() => setConfirm(false)} />
                    <Button
                        raised
                        primary
                        disabled={!confirm || updating}
                        label="Supprimer"
                        onClick={() => {
                            onItemUpdate(
                                { id: remove, count: 0 },
                                () => {
                                    setRemove(null)
                                    setConfirm(false)
                                },
                            )
                        }} />
                </>
            }>
            Confirmez-vous la suppression de cet item de votre panier ?
        </Dialog>
    </>
}

export default Basket
