import {
    LOGOUT, WEB_RECEIVE,
} from 'actions'

export const init = {
    websites: [],
}

export const reducer = (state=init, action) => {
    switch(action.type) {
        case WEB_RECEIVE.SUCCESS:
            return {
                ...state,
                websites: action.payload.websites,
            }
        case LOGOUT.REQUEST:
        case WEB_RECEIVE.FAILURE:
            return {
                ...init,
            }
        default:
            return state
    }
}
