import React from 'react'
import { connect } from 'react-redux'
import { CircularProgress, RaisedButton } from 'components'
import { NavigationCheck, ContentForward } from 'components/icons'
import { Form, TextField } from 'components/form'
import { Popup } from 'containers'
import Steps from '../steps'
import * as creators from './creators'

const AliasAdd = ({
    onAdd, onClose, match: { params }, aliases, existing, errors, loading, history
}) =>
    <Popup
        onClose={() => onClose(history, params.domain)}
        title="Ajout d'une redirection"
        icon={<ContentForward />}
        color='#297ee6'>
        <Form
            title="Redirection"
            validated={!loading}
            errors={errors}
            onClose={() => onClose(history, params.domain)}
            onSubmit={alias => onAdd(history, params.domain, alias)}>
            <Steps step='add' />
            <TextField
                name='from_field'
                label={`Depuis (@${params.domain})`}
                transform={value => value.trim().toLowerCase()}
                check={[/^(\*|[-_a-z0-9.]+)$/i, value =>
                    existing.some(
                        identifier =>
                            value.replace(/\./g, '').replace('*', '') ===
                            identifier
                    ) ? 'Identifiant déjà existant' : '']} />
            <TextField
                name='to'
                label='Vers'
                transform={value => value.trim().toLowerCase()}
                check={/^[-_a-z0-9.]+@[-_a-z0-9.]+\.[a-z]+$/i}
                />
            <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                }}>
                <RaisedButton
                    label='Suivant'
                    startIcon={
                        loading ?
                        <CircularProgress
                            size={24}
                            mode='indeterminate' /> :
                        <NavigationCheck
                            size={24} />
                    }
                    disabled={loading}
                    primary={true}
                    type='submit' />
            </div>
        </Form>
    </Popup>

const ConnectedAliasAdd = connect(
       state => state.domains.mail.alias.add,
       creators,
   )(AliasAdd)

export default ConnectedAliasAdd
