import React from 'react'
import { connect } from 'react-redux'
import { RaisedButton, CircularProgress } from 'components'
import { ActionLock, NavigationCheck } from 'components/icons'
import {
    API_CALL, ALIAS_PASSWORD, DISPLAY_NOTIFICATION, ALIAS_GENPASS,
} from 'actions'
import { Popup } from 'containers'
import { Form, TextField } from 'components/form'
import css from './password.module.css'

const init = {
    errors: null,
    loading: false,
}

export const reducer = (state=init, action) => {
    switch (action.type) {
        case ALIAS_PASSWORD.SUCCESS:
            return init
        case ALIAS_PASSWORD.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ALIAS_PASSWORD.FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.error.message,
            }
        default:
            return state
    }
}

export const creators = {
    password: (history, domain, alias) => () => history.push(`/${domain}/mail/alias/${alias.from_field}/password`)
}

class AliasPassword extends React.Component {
    state={ generated: null }

    componentDidMount() {
        const { params } = this.props.match
        this.props.generate(
            this.props.history,
            params.domain,
            params.alias,
            generated => {
                this.setState({ generated })
            }
        )
    }

    handleSubmit = data => {
        const { params } = this.props.match
        this.setState({
            generated: !!data.password ?
                null : this.state.generated,
        })
        this.props.onPassword(
            this.props.history,
            params.domain, {
            ...data,
            alias: params.alias,
            generated: this.state.generated,
        })
    }

    render() {
        const {
            match, onClose, loading, errors, history
        } = this.props
        const { params } = match
        const { generated } = this.state
        return (
            <Popup
                onClose={() => onClose(history, params.domain)}
                title={`Identification via ${params.alias}@${params.domain}`}
                icon={<ActionLock />}
                color='#297ee6'>
                <Form
                    validated={!loading}
                    errors={errors}
                    onClose={() => onClose(history, params.domain)}
                    onSubmit={this.handleSubmit}>
                    <div className={css.header}>
                        Vous souhaitez pouvoir envoyer des mails à l'aide de cette
                        redirection.
                    </div>
                    <div className={css.header}>
                        Veuillez soit renseigner le mot de passe à utiliser ci-après
                    </div>
                    <TextField
                        name='password'
                        type='password'
                        label='Entrez un mot de passe'
                        autoComplete='new-password'
                        check={value =>
                                value.length && value.length < 6 ?
                                'Mot de passe trop court' :
                                ''} />
                    <TextField
                        type='password'
                        name='passwordcheck'
                        label='Vérifiez le mot de passe'
                        autoComplete='new-password'
                        check={(value, state) =>
                                !!state.password && state.password !== value ?
                                'Mots de passe différents' :
                                ''} />
                    <div id={css.divider}>
                        <span>Soit</span>
                    </div>
                    <div className={css.header}>
                        Utiliser le mot de passe généré ci-dessous.
                    </div>
                    <div id={css.generated}>
                        {generated || 'Génération...'}
                    </div>
                    <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                        }}>
                        <RaisedButton
                            label='Valider'
                            icon={
                                loading ?
                                <CircularProgress
                                    size={24}
                                    mode='indeterminate' /> :
                                <NavigationCheck
                                    size={24} />
                            }
                            disabled={loading}
                            primary={true}
                            type='submit' />
                    </div>
                </Form>
            </Popup>
        )
    }
}

const ConnectedAliasPassword = connect(
    state => ({
        ...state.domains.mail.alias.password,
    }), {
        generate: (history, domain, alias, cb) => ({
            type: ALIAS_GENPASS.action,
            [API_CALL]: {
                request: {
                    url: `/mails/${domain}/alias/password/generate`,
                    body: {
                        alias,
                    },
                },
                onsuccess: ({ payload }) =>
                    cb(payload.password)
            },
        }),
        onClose: (history, domain) => () =>
            history.push(`/${domain}`),
        onPassword: (history, domain, body) => ({
            type: ALIAS_PASSWORD.action,
            [API_CALL]: {
                request: {
                    url: `/mails/${domain}/alias/password`,
                    body,
                },
                onsuccess: ({ dispatch }) => {
                    history.push(`/${domain}`)
                    dispatch({
                        type: DISPLAY_NOTIFICATION,
                        message: `Identification avec ${body.alias}@${domain} activée`
                    })
                }
            }
        })
    }
)(AliasPassword)

export default ConnectedAliasPassword
