import React from 'react'
import { connect } from 'react-redux'
import { Paper, IconButton } from 'components'
import { Toolbar } from 'components'
import { ActionClear } from 'components/icons'
import css from './popup.module.css'

class Popup extends React.Component {
    timeout=null
    state={ opening: true, closing: false }

    constructor(props) {
        super(props)
        this.handleClose = this.handleClose.bind(this)
    }

    componentDidMount() {
        this.timeout = setTimeout(() => {
            this.timeout = null
            this.setState({ opening: false })
        })
    }

    componentWillUnmount() {
        if (this.timeout !== null) {
            clearTimeout(this.timeout)
        }
    }

    handleClose(event) {
        event.stopPropagation()
        event.preventDefault()
        this.setState({
            closing: true,
        })
        this.timeout = setTimeout(() => {
            this.timeout = null
            this.setState({ closing: false })
            this.props.onClose()
        }, 200)
    }

    render() {
        const { children, title, display, icon, color } = this.props
        const content =
            <Paper
                elevation={3}
                className={
                    css['popup-holder'] + (
                        this.state.opening || this.state.closing ?
                        ` ${css['popup-holder-opening']}` :
                        ''
                    )
                }
                onClick={event => event.stopPropagation()}>
                <Toolbar
                    className={css['popup-title']}
                    style={{
                        backgroundColor:
                            !!color ? color : 'rgb(42, 47, 58)',
                    }}>
                    <div
                        style={{
                            padding: '12px',
                            width: '48px',
                            height: '48px',
                        }}>
                        {React.cloneElement(icon, {style: {color: 'white'}})}
                    </div>
                    <div
                        style={{color: 'white', flexGrow: '1', textAlign: 'center'}}>
                        {title}
                    </div>
                    <IconButton onClick={this.handleClose}>
                        <ActionClear style={{color: 'white'}} />
                    </IconButton>
                </Toolbar>
                <div className={css['popup-content']}>
                    {children}
                </div>
            </Paper>
        return (
            display === 'mobile' ?
            <div
                className={
                    css['popup-layer'] + (
                        this.state.opening || this.state.closing ?
                        '' : ` ${css['popup-layer-opened']}`
                    )
                }
                onClick={this.handleClose}>
                {content}
            </div> : content
        )
    }
}

const ConnectedPopup = connect(
    state => ({ display: state.app.display })
)(Popup)

export default ConnectedPopup
