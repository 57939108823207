import {
    LOGOUT,
    ACCOUNT_UPDATE,
} from 'actions'

const init = {
    errors: {},
    loading: false,
    confirm_mailbox: false,
}

const reducer = (state=init, action) => {
    switch(action.type) {
        case LOGOUT.REQUEST:
            return init
        case ACCOUNT_UPDATE.SUCCESS:
            if (!!action.payload.confirm_mailbox || !!action.payload.create_password) {
                return {
                    ...state,
                    loading: false,
                    confirm_mailbox: action.payload.confirm_mailbox,
                    create_password: action.payload.create_password,
                }
            } else {
                return init
            }
        case ACCOUNT_UPDATE.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ACCOUNT_UPDATE.FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.error.errors,
                confirm_mailbox: action.error.confirm_mailbox,
                create_password: action.error.create_password,
            }
        default:
            return state
    }
}

export default reducer
