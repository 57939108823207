import React from 'react'
import PropTypes from 'prop-types'
import { LinearProgress, ListItem, withTheme, makeStyles } from 'components'
import { SocialSentimentSatisfied } from 'components/icons'
import colors from 'components/colors'

const unlimited =
    <div
        style={{
            display: 'inline-flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
        <span>Illimité</span>
        <SocialSentimentSatisfied
            style={{marginLeft: '8px'}} />
    </div>

export function humanSize(value) {
    const fmt = new Intl.NumberFormat(undefined,
                                      {maximumFractionDigits: 2}).format
    if (value < 1024) {
        return value.toString() + ' o'
    } else if (value < 1024 * 1024) {
        return (fmt(value / 1024) + " Ko")
    } else if (value < 1024 * 1024 * 1024) {
        return (fmt(value / 1024 / 1024) + " Mo")
    } else {
        return (fmt(value / 1024 / 1024 / 1024) + " Go")
    }
}

export const Size = ({value, style, text=''}) => {
    return (
        <span style={style}>
            {`${humanSize(value)}${text !== '' ? ` ${text}` : ''}`}
        </span>
    )
}

const useStyles = makeStyles({
  bar: props => ({backgroundColor: props.color})
})

const QuotaBar = ({
    theme, value, max, text=true, label={}, color=null,
}) => {
    let percent = Math.floor(value * 100.0 / max)
    const themeTextColor = theme.palette.text.primary
    const themeColor = theme.palette.primary.main
    let textColor = themeTextColor
    if (!color || color === null) {
        color = themeColor
    }
    let fontWeight = 'normal'
    if (max > 0) {
        if (percent >= 100) {
            percent = 100
            color = colors.red500
            textColor = colors.red700
            fontWeight = 'bold'
        } else if (percent >= 80) {
            color = colors.orange500
            textColor = colors.orange700
        }
    }
    const classes = useStyles({ color })
    return (
        <div>
            {text ?
            <ListItem
                style={{
                    textAlign: 'center',
                    padding: label.max ? '8px 0px' : '0px',
                }}
                textStyle={{
                    margin: '0px',
                }}
                primaryText={
                    <span
                        className="quota-text"
                        style={{
                            fontWeight: label.max ? 'normal' : fontWeight,
                            color: label.max ? themeTextColor : textColor
                        }}>
                        {!!label.value &&
                            <span style={{
                                paddingRight: '8px',
                            }}>
                                {label.value}
                            </span>}
                            <span
                                style={{
                                    fontWeight,
                                    color: textColor
                                }}>
                                <Size value={value} />
                            </span>
                        {label.max ? ' sur ' : ' / '}
                        {max <= 0 ? unlimited : (
                            <span>
                                <Size value={max} />
                                {!!label.max &&
                                    <span
                                        style={{
                                            paddingLeft: '8px',
                                        }}>
                                        {label.max}
                                    </span>}
                            </span>)}
                    </span>}
                    secondaryText={
                        !!label.max && ' ('+(label.max?'soit ':'')+percent+'% de l\'espace '+label.max+')'
                    } /> : null}
            {max > 0 ?
                <LinearProgress
                    variant="determinate"
                    style={{height: '20px'}}
                    value={percent}
                    classes={classes} /> : null}
        </div>
    )
}

QuotaBar.propTypes = {
    value: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    text: PropTypes.bool
}

export default withTheme(QuotaBar)
