import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { RaisedButton, CircularProgress } from 'components'
import { ActionInput } from 'components/icons'
import { Form, TextField } from 'components/form'
import { DISPLAY_NOTIFICATION, API_CALL, RECALL } from 'actions'
/* globals grecaptcha */

const init = {
    errors: null,
    loading: false,
}

export const reducer = (state=init, action) => {
    switch (action.type) {
        case RECALL.SUCCESS:
            return init
        case RECALL.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case RECALL.FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.error.message,
            }
        default:
            return state
    }
}

const onRecall = (history, body, captcha) => ({
    type: RECALL.action,
    [API_CALL] : {
        request: {
            url: '/accounts/recall',
            body: {
                ...body,
            }
        },
        onsuccess: ({ dispatch }, success) => {
            history.replace('/login')
            success()
            dispatch({
                type: DISPLAY_NOTIFICATION,
                message: 'Instructions de réinitialisation envoyées à ' +
                    body.login,
            })
        },
        onfailure: () => grecaptcha.reset(captcha),
    }
})

const RecallForm = ({ errors, recaptcha, onRecall, loading, history }) =>
    <Form
        errors={errors}
        captcha={recaptcha}
        validated={!loading}
        onSubmit={(body, captcha) => onRecall(history, body, captcha)}>
        <TextField
            fullwidth
            type='email'
            label="Entrez votre email"
            autoComplete='home email'
            name="login" /><br/>
        <RaisedButton
            type="submit"
            startIcon={
                loading ?
                <CircularProgress
                    size={24}
                    mode='indeterminate' /> :
                <ActionInput
                    size={24} />
            }
            label="Réinitialiser le mot de passe"
            disabled={loading}
            secondary
            fullWidth />
    </Form>

const ConnectedRecallForm = connect(
    state => ({...state.login.recall, recaptcha: state.app.keys.recaptcha}),
    { onRecall },
)(RecallForm)

export const route = () =>
    <Route
        path="/login/reset"
        exact
        component={ConnectedRecallForm} />

export default ConnectedRecallForm
