import React from 'react'
import { connect } from 'react-redux'
import { List, ListItem, GridList, Popover, Table, IconButton, GridListTile } from 'components'
import { SocialSentimentDissatisfied } from 'components/icons'
import css from './table.module.css'

const ContextMenu = ({ state, menu, onClose }) =>
    <Popover
        open={state.open}
        onRequestClose={() => onClose()}
        anchorEl={{
            getBoundingClientRect: () => ({
                left: state.x,
                top: state.y,
            }),
            offsetWidth: 1,
            offsetHeight: 1,
        }}>
        {!!state.data && React.cloneElement(
            menu(state.data),
            { onClose })}
    </Popover>

class ResponsiveTable extends React.Component {
    state = { open: false, x: null, y: null, data: null }
    render() {
        const {
            display, dataset, primaryText, headers, icon=() => null,
            onClick, loading, menu,
            indicators=() => null, selected=() => false,
            info=() => null, add=true, styles={}, cols=2,
            itemClass=null, secondaryText=() => null,
        } = this.props
        const handleClick = (data, index) => event => {
            event.preventDefault()
            event.stopPropagation()
            !!onClick && onClick(data, index)
        }
        return (
            display === 'desktop' ?
            <div>
                <Table
                    hoverable={false}
                    selectable={false}
                    keys={
                        headers.map((header, index) => ({
                            [header]: data =>
                            <div
                                key={`header-${index}`}
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    cursor: 'pointer',
                                }}>
                                <List
                                    style={{flexGrow: 1}}>
                                    <ListItem
                                        component={'div'}
                                        ContainerComponent={'div'}
                                        textComponent={'div'}
                                        icon={icon(data, index)}
                                        action={
                                            indicators(data, index) &&
                                            <IconButton
                                                onClick={handleClick(data, index)}>
                                                {indicators(data, index)}
                                            </IconButton>}
                                        onClick={handleClick(data, index)}
                                        primaryText={primaryText(data, index)}
                                        secondaryText={
                                            secondaryText(data, index)
                                        }
                                        textStyle={
                                            typeof(styles) === 'function' ?
                                            styles(data, index) :
                                            styles[index] ? styles[index] : {}
                                        } />
                                </List>
                                {info(data, index) &&
                                    <div
                                        style={{
                                            backgroundColor:
                                                selected(data, index) ?
                                                '#efefef' :
                                                'white',
                                            cursor: 'pointer',
                                            display: 'grid',
                                            padding: '0px 24px',
                                            width: '40%',
                                            alignItems: 'center',
                                        }}
                                        onClick={handleClick(data, index)}>
                                        {info(data, index)}
                                    </div>}
                            </div>}))}
                    dataset={dataset} />
                {menu ?
                    <ContextMenu
                        onClose={() =>
                            this.setState({
                                open: false, data: null,
                            })}
                        menu={menu}
                        state={this.state} /> : null}
            </div> :
            dataset.length > 0 ?
            <GridList
                cols={cols}
                className={css.gridlist}
                rowHeight='auto'>{
                    dataset.map((data, index) =>
                        <GridListTile
                            component="div"
                            key={`item-${index}`}>
                            <ListItem
                                component="div"
                                key={`item-${index}`}
                                classes={{root: css.listitem}}
                                className={`${itemClass ? ` ${itemClass}` : ''}`}
                                iconStyle={{
                                    minWidth: '30px',
                                }}
                                textStyle={
                                    typeof(primaryText(data)) === 'string' ? {
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        wordBreak: 'keep-all',
                                        ...(
                                            typeof(styles) === 'function' ?
                                            styles(data) : {}
                                        ),
                                    } : typeof(styles) === 'function' ?
                                    styles(data) : {}
                                }
                                disabled={loading}
                                icon={icon(data) && React.cloneElement(
                                    icon(data), {
                                        style: {
                                            marginLeft: 0,
                                            marginRight: 0,
                                            ...icon(data).props.style,
                                        }
                                    })}
                                action={indicators(data) && React.cloneElement(
                                    indicators(data), {
                                        className: css.noMargin,
                                        onClick: handleClick(data),
                                    }
                                )}
                                primaryText={
                                    primaryText(data)
                                }
                                secondaryText={
                                    secondaryText(data)
                                }
                                textComponent="div"
                                onClick={handleClick(data)} />
                        </GridListTile>
                    )}
            </GridList> :
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#a8a8a8',
                    fontStyle: 'italic',
                }}>
                <div>
                    <SocialSentimentDissatisfied
                        style={{
                            color:'#a8a8a8',
                            width: '48px',
                            height: '48px'
                        }} />
                </div>
                <div>
                    Pas d'éléments trouvés.
                </div>
                {add &&
                    <div>
                        Ajoutez-en à l'aide du bouton ci-dessous.
                    </div>}
            </div>
        )
    }
}

const ConnectedResponsiveTable = connect(
    state => ({
        display: state.app.display,
        loading: state.loading.displayed,
    }),
)(ResponsiveTable)

export default ConnectedResponsiveTable
