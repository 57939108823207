import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import {
    RaisedButton, IconButton,
    Menu, Divider, Chip, MenuItem,
    ListItemIcon, ListItemText,
    MaterialAppBar, Toolbar,
} from 'components'
import {
    NavigationArrowBack, NavigationMenu , ActionSearch, ActionClear,
} from 'components/icons'
import { withTheme } from 'components'
import { SEARCH_FILTER, SEARCH_INIT } from 'actions'
import { SearchInput } from './search'
import Breadcrumb from './breadcrumb'
import { app } from '../apps'
import css from './bar.module.css'
import { CartButton } from './cart'

class AppBar extends React.Component {
    state={
        open: false, createOpen: false, createTarget: null,
        searching: false,
    }

    render() {
        const {
            display, match, location, history,
            openMenu, user, act,
            filter, query,
        } = this.props
        const { params } = match
        const { push } = history
        const {
            searching, createOpen, createTarget,
        } = this.state
        const breadcrumb = new Breadcrumb({
            params, location, push
        })
        const leaf = breadcrumb.leaf
        const { add, search } = app({ params, location, history })
        const Search =
            <SearchInput
                params={params}
                location={location}
                onStart={() => {
                    this.setState({ empty: false, searching: true })
                }}
                onStop={() => {
                    this.setState({ empty: true })
                }} />
        return (!!user &&
            <MaterialAppBar
                elevation={1}
                className={display === 'desktop' ? css.bar : undefined}
                color={"transparent"}
                position="relative">
                {!!search && display === 'desktop' &&
                    <Toolbar>
                        <ActionSearch />
                        {Search}
                        <IconButton
                            onClick={() =>
                                act({ type: SEARCH_INIT })}>
                            <ActionClear />
                        </IconButton>
                    </Toolbar>}
                {display === "desktop" ?
                    <Toolbar>
                        {breadcrumb.component}
                        {!!add &&
                            <RaisedButton
                                onClick={event => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    if (!!add.items) {
                                        this.setState({
                                            createOpen: true,
                                            createTarget:
                                                event.currentTarget,
                                         })
                                     } else {
                                         act(add.action())
                                     }
                                }}
                                secondary
                                startIcon={add.icon}
                                label={add.text} />}
                        {!!add.items &&
                            <Menu
                                anchorEl={createTarget}
                                open={createOpen}
                                onClose={() =>
                                    this.setState({
                                        createOpen: false,
                                    })
                                }>
                                {add.items.map((add, index) =>
                                    add === 'divider' ?
                                    <Divider
                                        key={`add-divider-${index}`} /> :
                                    <MenuItem
                                        key={`add-${add.text.replace(' ', '-')}`}
                                        onClick={() => {
                                            this.setState({
                                                createOpen: false,
                                            })
                                            act(
                                                add.action()
                                            )
                                        }}>
                                        <ListItemIcon>
                                            {add.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={add.text} />
                                    </MenuItem>)}
                            </Menu>}
                        <CartButton />
                    </Toolbar> :
                    <Toolbar>
                        <IconButton
                            onClick={() =>
                                searching ?
                                this.setState({ searching: false }) :
                                leaf.props.first ?
                                openMenu() :
                                leaf.props.back()
                            }>
                            {leaf.props.first && !searching ?
                                <NavigationMenu /> :
                                <NavigationArrowBack />}
                        </IconButton>
                        <div
                            style={{
                                flexGrow: 1,
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            {searching ?
                                <>
                                    {Search}
                                    <IconButton
                                        onClick={() => {
                                            act({ type: SEARCH_INIT })
                                            this.setState({ searching: false })
                                        }}>
                                        <ActionClear />
                                    </IconButton>
                                </> :
                                leaf.props.crumb.name}
                        </div>
                        <div>
                            {!!search && (
                                !searching ?
                                    <IconButton
                                        onClick={event => {
                                            event.preventDefault()
                                            event.stopPropagation()
                                            this.setState({
                                                searching: true,
                                            })
                                        }}>
                                        <ActionSearch />
                                    </IconButton> :
                                    null)}
                        </div>
                        <div>
                            <CartButton />
                        </div>
                    </Toolbar>}
                {!!search && display === 'mobile' && !!filter &&
                    <Toolbar>
                        <Chip
                            icon={filter.icon}
                            onDelete={() => {
                                if (query === '') {
                                    act({ type: SEARCH_INIT })
                                } else {
                                    act({ type: SEARCH_FILTER, filter: null })
                                }
                                document.getElementById('search').focus()
                            }}
                            label={filter.label} />
                    </Toolbar>}
            </MaterialAppBar>
        )
    }
}

const ConnectedAppBar = connect(
    state => ({
        display: state.app.display,
        user: state.app.user,
        filter: state.search.filter,
        query: state.search.query,
    }),
    { act: action => action },
)(withRouter(AppBar))

const ThemedAppBar = withTheme(ConnectedAppBar)

export default ThemedAppBar
