import React from 'react'
import { Stepper as UiStepper, MobileStepper } from 'components'
import { StepLabel, Step as UiStep } from 'components'
import { DISPLAY_NOTIFICATION } from 'actions'
import PaymentComponent from './payment'
import BasketComponent from './basket'
import FinalizationComponent from './finalization'
import { fmt } from './utils'

export const steps = {
    'basket': {
        label: 'Votre panier',
        Component: BasketComponent,
        action: amount =>
            `Valider ` + ( amount < 0 ? 'votre avoir' : 'le panier' ) +
            ` de ${fmt(Math.abs(amount) / 100)}`,
        icon: 'shop',
        click: ({ cart, actions, dispatch }) => {
            if (cart.amount > 0) {
                actions.changeStep('payment')
            } else if (cart.amount === 0) {
                actions.changeStep('finalized')
            } else {
                actions.confirmRedeem({ amount: -cart.amount }, () => {
                    actions.changeStep('finalized')
                }, ({ payload, dispatch }) => {
                    dispatch({
                        type: DISPLAY_NOTIFICATION,
                        message: payload.message,
                        severity: 'error',
                    })
                })
            }
        }
    },
    'payment': {
        label: 'Informations de paiement',
        Component: PaymentComponent,
        action: amount => `Procéder au paiement de ${fmt(amount / 100)}`,
        icon: 'payment',
        click: ({ cart, actions, dispatch }) => {
            const method = cart.methods.find(method => method.selected)
            if ((typeof(method) !== 'undefined') && method) {
                if (method.saved===true) {
                    actions.confirmCvc(true)
                } else {
                    actions.validatePayment({
                        id: method.id,
                        cgv_date: cart.cgvDate,
                        saved: false,
                        save: method.save
                    })
                }
            } else {
                dispatch({
                    type: DISPLAY_NOTIFICATION,
                    message: 'Veuillez sélectionner une méthode de paiement',
                    severity: 'warning'
                })
            }
        }
    },
    'finalized': {
        label: 'Commande validée',
        Component: FinalizationComponent,
        action: amount => 'Terminer',
        icon: 'done',
        click: ({ actions, history }) => {
            actions.emptyCart()
            history.push('/account/bills')
        }
    },
}

export const Step = ({ step, ...rest }) => {
    if (typeof(step) !== 'undefined' && Object.keys(steps).indexOf(step) !== -1) {
        const { Component } = steps[step]
        return <Component {...rest} />
    } else {
        return null
    }
}

export const Stepper = ({ display, step, style, ...rest }) => {
    const activeStep = Object.keys(steps).indexOf(step)
    if (display === 'mobile') {
        return (
            <>
                <div
                    style={style}>
                    {steps[step].label}
                </div>
                <MobileStepper
                    activeStep={activeStep}
                    position='static'
                    steps={Object.keys(steps).length}
                    {...rest} />
            </>
        )
    } else {
        return (
            <UiStepper
                activeStep={activeStep}
                style={style}
                {...rest}>
                {Object.values(steps).map(
                    ({ label }) =>
                        <UiStep
                            key={label}>
                            <StepLabel>{label}</StepLabel>
                        </UiStep>
                )}
            </UiStepper>
        )
    }
}
