import React from 'react'
import { combineReducers } from 'redux'
import * as Mailbox from './mailbox'
import * as Alias from './alias'
import { reducer as root } from './reducer'
import { select } from './actions'
import {
    ActionLock, ContentForward, CommunicationEmail,
    AlertWarning
} from 'components/icons'
import colors from 'components/colors'
import { QuotaBar, CircularProgress } from 'components'
import SoEasyToIcon from 'root/icon'

export const reducer = combineReducers({
    root,
    alias: Alias.reducer,
    mailbox: Mailbox.reducer,
})

const intervals = {}

export const resetInterval = id => {
    const timer = intervals[id]
    timer.timeout = setTimeout(() => timer.cb(), timer.interval)
}

export const addInterval = (cb, interval, onClear) => {
    const id = Object.keys(intervals).length
    const timer = { cb, interval, id, timeout: null, clear: onClear }
    intervals[id] = timer
    return id
}

export const removeInterval = id => {
    if (intervals[id]) {
        const timeout = intervals[id].timeout
        intervals[id].clear()
        delete intervals[id]
        clearTimeout(timeout)
    }
}

export const route = {
    route: () => [
        ...Mailbox.route(),
        ...Alias.route(),
        Alias.details.route(),
        Mailbox.details.route(),
    ],
    enter: select,
    selector: state => ({
        ...state.domains.mail.root,
        ...state.domains.mail.mailbox.root,
        ...state.domains.mail.alias.root,
        quotas: state.storage.quotas,
    })
}

export const name = 'Mails'
export const hooks = {
    unmount: () => {
        Object.keys(intervals).forEach(id =>
            removeInterval(id)
        )
    }
}

export const types = {
    mailbox: {
        icon: <CommunicationEmail style={{color: '#297ee6'}} />,
        label: 'Une boîte mail',
        add: Mailbox.actions.add,
        selector: dataset => dataset.mailbox.root.mailboxes,
        name: mailbox => mailbox.username,
        indicator: mailbox =>
            mailbox.installed ?
            mailbox.used * 100.0 / mailbox.quota > 90.0 ?
            <AlertWarning style={{color:colors.red600}} /> :
            mailbox.used * 100.0 / mailbox.quota > 80.0 ?
            <AlertWarning style={{color:colors.orange600}} /> :
            mailbox.linked_account ?
            <SoEasyToIcon size={24} disabled={true} /> :
            null :
            <CircularProgress
                size={24} />,
        info: mailbox => {
            const since = Math.floor((
                new Date().getTime() -
                new Date(mailbox.install_date).getTime()
            ) / 1000)
            return mailbox.installed ?
            <QuotaBar
                label={{value: 'Espace utilisé'}}
                value={mailbox.used}
                max={mailbox.quota} /> :
            <div style={{
                    fontStyle:'italic',
                }}>
                En cours de création depuis {since} seconde{
                    since > 1 ? 's' : ''
                }...
            </div>
        },
        details: mailbox =>
            `${mailbox.username}`,
        selected: (params, mailbox) => params.mailbox === mailbox.username,
        search: {
            select: Mailbox.actions.update,
            result: (domain, item) => item.username,
            filter: 'Boîtes mail',
            matches: (mailbox, query) =>
                query.some(q => mailbox.username.includes(q)),
        }
    },
    alias: {
        icon: <ContentForward style={{color: '#297ee6'}} />,
        label: 'Une redirection',
        add: Alias.actions.add,
        selector: dataset => dataset.alias.root.aliases,
        name: alias => alias.from_field,
        indicator: alias =>
            alias.active ?
                alias.password ?
                    <ActionLock style={{color: '#297ee6'}} /> :
                    null :
                <AlertWarning style={{color:colors.orange600}} />,
        details: alias =>
            alias.active ?
            `alias/${alias.from_field}` :
            `alias/${alias.from_field}/activate`,
        selected: (params, alias) => params.alias === alias.from_field,
        info: alias => alias.to,
        search: {
            select: Alias.actions.update,
            result: (domain, item) => item.from_field,
            filter: 'Redirections mail',
            matches: (alias, query) =>
                query.some(q => alias.from_field.includes(q)),
        }
    }
}
