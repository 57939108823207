import React from 'react'
import { connect } from 'react-redux'
import { Dialog, FlatButton, withTheme } from 'components'
import colors from 'components/colors'
import {
    API_CALL, ALIAS_REMOVE, DISPLAY_NOTIFICATION,
} from 'actions'

const prevent = cb => event => {
    event.preventDefault()
    event.stopPropagation()
    cb()
}

const init = {
    error: null,
    loading: false,
}

export const reducer = (state=init, action) => {
    switch (action.type) {
        case ALIAS_REMOVE.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ALIAS_REMOVE.SUCCESS:
            return init
        case ALIAS_REMOVE.FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error.message,
            }
        default:
            return state
    }
}

export const creators = {
    remove: (history, domain, alias) => () => history.push(`/${domain}/mail/alias/${alias.from_field}/remove`)
}

const AliasRemove = ({
    match: { params }, onRemove, onCancel, theme, error, loading, history
}) =>
    <Dialog
        open={true}
        title="Suppression d'une redirection"
        className='text-center'
        style={{lineHeight: '2.5em'}}
        actions={[
            <FlatButton
                label="Annuler"
                key="cancel"
                primary
                disabled={loading}
                onClick={prevent(() =>
                    onCancel(history, params.domain)
                )} />,
            <FlatButton
                key="remove"
                label="Supprimer"
                disabled={loading}
                primary
                onClick={prevent(() =>
                    onRemove(history, params.domain, params.alias))
                } />,
        ]}>
        <div>
            {'Êtes-vous sûr de vouloir supprimer la redirection '}
            <span style={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main
                }}>{`${params.alias}@${params.domain}`}</span>{' ?'}
        </div>
        {error !== null && <div style={{
            fontWeight: 'bold',
            color: colors.red400,
        }}>{error}</div>}
    </Dialog>

const ConnectedAliasRemove = connect(
    state => state.domains.mail.alias.remove, {
        onCancel: (history, domain) => () =>
            history.push(`/${domain}`),
        onRemove: (history, domain, alias) => ({
            type: ALIAS_REMOVE.action,
            [API_CALL]: {
                request: {
                    url: `/mails/${domain}/alias/remove`,
                    body: {
                        alias,
                    }
                },
                onsuccess: ({ dispatch }) => {
                    history.push(`/${domain}`)
                    dispatch({
                        type: DISPLAY_NOTIFICATION,
                        message: `Redirection ${alias}@${domain} supprimée ` +
                            'avec succès'
                    })
                }
            }
        })
    }
)(AliasRemove)

const ThemedAliasRemove = withTheme(ConnectedAliasRemove)

export default ThemedAliasRemove
