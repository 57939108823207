import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { apiMiddleware } from 'api'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import { reducers } from '../containers'
import { reducers as appReducers } from '../apps'
import { reducer as appReducer } from './app'

const rootReducer = combineReducers({
    ...reducers,
    ...appReducers,
    ...appReducer,
})

const composeEnhancers = composeWithDevTools({})

const enhancer = composeEnhancers(
    applyMiddleware(
        apiMiddleware,
        thunkMiddleware,
    ),
)

const initialState = {}

export const store = createStore(
    rootReducer,
    initialState,
    enhancer
)
