import React from 'react'
import { connect } from 'react-redux'
import { MaterialDrawer, IconButton } from 'components'
import { List, ListItem } from 'components'
import { Card, CardText } from 'components'
import { makeStyles } from 'components'
import { ActionExitToApp } from 'components/icons'
import Icon from './icon'
import { apps } from '../apps'
import background from './drawer-card.png'

const styles = {
  root: {
      backgroundImage: `url(${background})`,
      padding: '0',
      '&:last-child': {
          padding: '0',
      }
  },
  drawer: {
      width: '256px',
  },
}

const useStyles = makeStyles(styles)

const Drawer = ({
    display, open, openMenu, user, onLogout, params, location, history
}) => {
    const classes = useStyles()
    return (
        !!user &&
        <MaterialDrawer
            open={display === 'desktop' || open}
            variant={display === 'desktop' ? 'persistent' : 'temporary'}
            elevation={1}
            onClose={() => openMenu(false)}
            classes={{paper: classes.drawer}}>
            <Card square={true}>
              <CardText className={classes.root}>
                <Icon />
                <ListItem
                    ContainerComponent={"div"}
                    primaryText={
                      user.firstname[0].toUpperCase() +
                      user.firstname.slice(1).toLowerCase() +
                      ' ' +
                      user.lastname[0].toUpperCase() +
                      user.lastname.slice(1).toLowerCase()
                    }
                    secondaryText={
                      user.email.toLowerCase()
                    }
                    action={
                        <IconButton
                            onClick={onLogout}>
                            <ActionExitToApp />
                        </IconButton>
                    }
                    style={{width: '100%'}} />
              </CardText>
            </Card>
            <List>
                {Object.keys(apps).filter(
                    app => !!apps[app].menu
                ).map(app =>
                    React.createElement(
                        apps[app].menu, {
                            closeMenu: () => openMenu(false),
                            key: app
                        }))}
            </List>
        </MaterialDrawer>
    )
}

const ConnectedDrawer = connect(
    state => ({ user: state.app.user, display: state.app.display }),
)(Drawer)

export default ConnectedDrawer
