import React from 'react'
import { Route, withRouter, Switch } from 'react-router'
import { combineReducers } from 'redux'
import { connect } from 'react-redux'
import { RaisedButton, IconButton, withTheme, Tooltip, withStyles, Alert } from 'components'
import { AppMenu } from 'containers/menu'
import ResponsiveTable from 'containers/table'
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Typography, Icon } from 'components'
import { Dialog, Transition, AppBar, Toolbar, LinearProgress, Button } from 'components'
import { ActionAccountCircle, AlertWarning, NavigationExpandMore, ContentCreate, ActionCheckCircle, ActionReceipt, ActionClear } from 'components/icons'
import { List, ListItem } from 'components'
import { EDIT_PROFILE, EDIT_PASSWORD, BILLS_RECEIVE, API_CALL, DISPLAY_NOTIFICATION, OUTLAY_DOWNLOAD } from 'actions'
import Edit, { reducer as edit } from './edit'
import Password, { reducer as password } from './password'
import Bill, { reducer as bill } from './bill'
import { SessionList, reducer as sessions } from './security'

export const name = 'Mon compte'

export const path = '/account'

export const crumb = {
    name,
    child: () => ({ exists: false }),
    path,
}

const init = { editing: false, password: false, bills: [] }

export const reducer = combineReducers({
    edit,
    password,
    bill,
    sessions,
    app: (state=init, action) => {
        switch(action.type) {
            case EDIT_PROFILE:
                return {
                    ...state,
                    editing: action.editing,
                }
            case EDIT_PASSWORD:
                return {
                    ...state,
                    password: action.editing,
                }
            case BILLS_RECEIVE.SUCCESS:
                return {
                    ...state,
                    bills: [
                        ...action.payload.bills
                    ],
                }
            default:
                return {
                    ...state,
                }
        }
    }
})

const AccountMenu = connect(
    state => ({user: state.app.user, display: state.app.display}),
)(withTheme(withRouter(({ location, closeMenu, history, theme, user, display }) =>
        <AppMenu
            path={path}
            active={location.pathname === path}
            closeMenu={closeMenu}
            icon={<ActionAccountCircle />}
            name={name}
            history={history}
            indicator={
                (!location.pathname.startsWith('/account') || display === 'mobile') && (
                    typeof(user.billing) === 'undefined' || user.billing === null ?
                    <Tooltip title="Profil incomplet">
                        <IconButton
                            onClick={() => history.push('/account/edit')}>
                            <AlertWarning
                                style={{color: theme.palette.warning.main}} />
                        </IconButton>
                    </Tooltip> :
                    user.to_confirm ?
                    <Tooltip
                        title={
                            `${user.to_confirm} en attente de confirmation`
                        }>
                        <IconButton
                            onClick={() => history.push('/account/edit')}>
                            <AlertWarning
                                style={{color: theme.palette.warning.main}} />
                        </IconButton>
                    </Tooltip> :
                    user.pending_bill ?
                    <Tooltip
                        title={
                            "Facture en attente"
                        }>
                        <IconButton
                            onClick={() => history.push('/account/bills')}>
                            <AlertWarning
                                style={{color: theme.palette.warning.main}} />
                        </IconButton>
                    </Tooltip> : null
                )
            } />
    ))
)

export { AccountMenu as menu }

const Component = connect(
    state => ({
        user: state.app.user,
        display: state.app.display,
        ...state.account.app,
    })
)
(withTheme(withRouter(withStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        padding: `0 ${theme.spacing(2)}px`,
    },
    alertHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },

}))(({ user, children, classes, display, editing, password, history, theme, bills, location }) => {
    const expanded = location.pathname.split('/')[2]
    const handleExpansion = panel => (event, isExpanded) =>
        history.push(`/account${isExpanded ? '/' + panel : ''}`)
    const infos = [
        bill => bill.isBill ? bill.reference : bill.bc,
        bill => new Intl.DateTimeFormat(undefined, display === 'desktop' ? {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        } : {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
        }).format(new Date(bill.creation_date)),
        bill => new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(bill.amount / 100),
        bill => bill.status === 'paid' ? bill.amount < 0 ? 'Créditée' : 'Payée' : 'En attente',
    ]
    const style = {
        1: { textAlign: 'center' },
        2: { textAlign: 'center' },
        3: { textAlign: 'center' }
    }
    const indicators = {
        3: bill =>
            bill.status === 'paid' ?
            <ActionCheckCircle
                style={{
                    color: theme.palette.success.main,
                }}/> :
            <Icon style={{
                    color: theme.palette.warning.main,
                }}>
                hourglass_bottom
            </Icon>,
    }
    return (
        <div className={classes.root}>
            <Accordion
                onChange={handleExpansion('profile')}
                expanded={expanded === 'profile' || editing}>
                <AccordionSummary
                    expandIcon={<NavigationExpandMore />}>
                    <Typography className={classes.heading}>
                        Mes infos
                    </Typography>
                    {expanded !== 'profile' && !editing &&
                        <>
                            <Typography className={classes.secondaryHeading}>
                                {user.firstname}&nbsp;{user.lastname}
                            </Typography>
                            {display === 'desktop' ?
                                <Typography
                                    style={{flexGrow: 1}}
                                    className={classes.secondaryHeading}>
                                    {user.email}
                                </Typography> : <Typography style={{flexGrow: 1}} />}
                            {(!user.billing || !!user.to_confirm) &&
                                <AlertWarning
                                    style={{
                                        color: theme.palette.warning.main
                                    }} />}
                        </>
                    }
                </AccordionSummary>
                <AccordionDetails
                    style={{width: '100%'}}>
                    <List
                        style={{width: '100%'}}>
                        <ListItem
                            primaryText={"Identité"}
                            secondaryText={`${user.firstname} ${user.lastname}`} />
                        <ListItem
                            primaryText={"Société"}
                            secondaryText={
                                !!user.billing && !!user.billing.company && user.billing.company.length > 0 ?
                                user.billing.company :
                                <i>Non définie</i>
                            } />
                        <ListItem
                            primaryText={"Adresse mail"}
                            secondaryText={user.email}
                            action={
                                !!user.to_confirm &&
                                    <AlertWarning
                                        style={{
                                            color: theme.palette.warning.main
                                        }} />} />
                        <ListItem
                            primaryText={"Adresse de facturation"}
                            secondaryText={
                                !!user.billing ?
                                <>
                                    {user.billing.first_line}<br/>
                                    {!!user.billing.second_line && user.billing.second_line.length > 0 &&
                                        <>
                                            {user.billing.second_line}<br/>
                                        </>}
                                    {user.billing.zipcode}&nbsp;{user.billing.town}
                                </> :
                                <i>Non définie</i>
                            }
                            action={
                                !user.billing &&
                                    <AlertWarning
                                        style={{
                                            color: theme.palette.warning.main
                                        }} />} />
                    </List>
                </AccordionDetails>
                <AccordionActions>
                    <RaisedButton
                        label={"Modifier"}
                        startIcon={<ContentCreate />}
                        secondary
                        onClick={() => history.push('/account/edit')}
                        disabled={editing} />
                </AccordionActions>
            </Accordion>
            <Accordion
                onChange={handleExpansion('security')}
                expanded={expanded === 'security' || password}>
                <AccordionSummary
                    expandIcon={<NavigationExpandMore />}>
                    <Typography className={classes.heading}>
                        Sécurité
                    </Typography>
                    {expanded !== 'security' && !password &&
                        <Typography className={classes.secondaryHeading}>
                            Mot de passe
                        </Typography>}
                </AccordionSummary>
                <AccordionDetails
                    style={{
                        width: '100%',
                        flexDirection: 'column'
                    }}>
                    <List
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>
                        <ListItem
                            style={{flexGrow: 1}}
                            primaryText={"Mot de passe"}
                            secondaryText="********" />
                        <RaisedButton
                            label={"Modifier"}
                            onClick={() => history.push('/account/password')}
                            disabled={password}
                            startIcon={<ContentCreate />}
                            secondary />
                    </List>
                    <SessionList
                        displayed={expanded === 'security'} />
                </AccordionDetails>
            </Accordion>
            {user.credit > 0 &&
                <Accordion
                    onChange={handleExpansion('credit')}
                    expanded={expanded === 'credit'}>
                    <AccordionSummary
                        expandIcon={<NavigationExpandMore />}>
                        <Typography className={classes.heading}>
                            Mon avoir
                        </Typography>
                        {expanded !== 'credit' &&
                            <Typography className={classes.secondaryHeading}>
                                Avoir de {
                                    new Intl.NumberFormat(undefined, {
                                        style: 'currency',
                                        currency: 'EUR',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(user.credit / 100)}
                            </Typography>}
                    </AccordionSummary>
                    <AccordionDetails
                        style={{width: '100%'}}>
                        <List
                            style={{width: '100%'}}>
                            <ListItem
                                primaryText={"Montant total d'avoir"}
                                secondaryText={
                                    new Intl.NumberFormat(undefined, {
                                        style: 'currency',
                                        currency: 'EUR',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(user.credit / 100)
                                } />
                        </List>
                    </AccordionDetails>
                </Accordion>}
            <Accordion
                onChange={handleExpansion('bills')}
                expanded={expanded === 'bills'}>
                <AccordionSummary
                    expandIcon={<NavigationExpandMore />}>
                    <Typography
                        className={classes.heading}
                        style={
                            bills.some(bill => bill.status === 'pending') ?
                            { display: 'flex', alignItems: 'center' }:
                            {}
                        }>
                        Mes Factures
                    </Typography>
                    {expanded !== 'bills' && bills && bills.length > 0 &&
                        ( bills.some(bill => bill.status === 'pending') ?
                        <Alert severity="warning" className={classes.alertHeading}>
                            {`${bills.filter(bill => bill.status === 'pending').length} facture en attente`}
                        </Alert> :
                        <Typography className={classes.secondaryHeading}>
                            Le {infos[1](bills[0])} de {
                                new Intl.NumberFormat(undefined, {
                                    style: 'currency',
                                    currency: 'EUR',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(bills[0].amount / 100)
                            }
                        </Typography> )}
                </AccordionSummary>
                <AccordionDetails
                    style={{backgroundColor: theme.palette.grey[200]}}>
                    <div
                        style={{width: '100%'}}>
                        <ResponsiveTable
                            dataset={bills}
                            styles={style}
                            add={false}
                            primaryText={
                                (bill, index) =>
                                    typeof(index) === 'undefined' ?
                                    infos[0](bill) : infos[index](bill)
                            }
                            secondaryText={
                                (bill, index) =>
                                    typeof(index) === 'undefined' ?
                                    infos[1](bill) : null
                            }
                            icon={(bill, index) => index === 0 || typeof(index) === 'undefined' ? <ActionReceipt style={{}} /> : null}
                            headers={['Référence', 'Date', 'Montant', 'Statut']}
                            indicators={
                                (bill, index) =>
                                    typeof(index) === 'undefined' ?
                                    <>{`${infos[2](bill)} `}{indicators[3](bill)}</> :
                                    indicators[index] ?
                                    indicators[index](bill) :
                                    null
                            }
                            onClick={bill => history.push('/account/bill/' + bill.reference)}
                            cols={1} />
                    </div>
                </AccordionDetails>
            </Accordion>
            {children}
        </div>
    )
}))))

class BillLoader extends React.Component {
    state = { loading: true }
    unmounted = true

    componentDidMount() {
        this.unmounted = false
        this.props.loadBills(() => !this.unmounted && this.setState({ loading: false }))
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    render() {
        const { bills, children } = this.props
        const { loading } = this.state
        return !loading && React.cloneElement(children, { bills })
    }
}

const ConnectedBillLoader = connect(
    state => ({ bills: state.account.bills }), {
        loadBills: onsuccess => ({
            type: BILLS_RECEIVE.action,
            [API_CALL]: {
                request: {
                    url: '/billing/all',
                },
                onsuccess,
            },
        }),
    },
)(BillLoader)

class Outlay extends React.Component {
    state = { loading: true, blob: null, id: null, filename: null, disabled: false }

    constructor(props) {
        super(props)
        this.downloadLink = React.createRef()
        this.timeout = null
    }

    componentDidMount() {
        const { match: { params }, loadOutlay } = this.props
        loadOutlay(params.outlay, ({ payload }) => {
            this.setState({
                loading: false,
                blob: `data:application/pdf;base64,${payload.blob}`,
                filename: payload.filename,
                id: payload.reference,
            })
        })
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout)
            this.timeout = null
        }
    }

    render() {
        const { loading, blob, filename, id, disabled } = this.state
        const { notify, history } = this.props
        const Loading =
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    width: '94%',
                    margin: '0px 3%',
                    minWidth: '300px',
                }}>
                <div style={{
                        textAlign: 'center',
                        paddingBottom: '10px',
                    }}>
                    Merci de patienter pendant le chargement de votre document...
                </div>
                <LinearProgress
                    style={{
                        height: '10px',
                        borderRadius: '5px'
                    }}
                    color="secondary" />
            </div>
        return (
            <Dialog
                fullScreen
                open
                TransitionComponent={Transition}
                dense>
                <div style={{
                        height:"calc(var(--vh, 1vh) * 100)",
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <AppBar
                        position="relative">
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={
                                    () => history.goBack()
                                }>
                                <ActionClear />
                            </IconButton>
                            <Typography variant="h6">
                                {loading ? "Chargement de votre document..." : "Document"}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div
                        style={{
                            flexGrow: 1,
                        }}>
                        {loading ?
                            Loading :
                            <object
                                type="application/pdf"
                                data={blob}
                                name={"Document-soeasyto-" + id + "-" + filename}
                                onLoad={() => {
                                    window.URL.revokeObjectURL(blob)
                                }}
                                width="100%"
                                height="100%">
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <a
                                        ref={this.downloadLink}
                                        style={{display: 'none'}}
                                        href={blob}
                                        download={"Document-soeasyto-" + id + "-" + filename}>
                                        {"Document-soeasyto-" + id + "-" + filename}
                                    </a>
                                    <Button
                                        secondary
                                        raised
                                        disabled={disabled}
                                        label="Télécharger"
                                        onClick={
                                            () => {
                                                this.setState({ disabled: true })
                                                this.downloadLink.current.click()
                                                notify("Téléchargement du document démarré...")
                                                window.URL.revokeObjectURL(blob)
                                                this.timeout = setTimeout(
                                                    () => this.setState({ disabled: false }),
                                                    2000,
                                                )
                                            }
                                        } />
                                </div>
                            </object>}
                    </div>
                </div>
            </Dialog>
        )
    }
}

const ConnectedOutlay = connect(
    undefined, {
        loadOutlay: (outlay, onsuccess) => ({
            type: OUTLAY_DOWNLOAD.action,
            [API_CALL]: {
                request: {
                    url: `/billing/outlay/${outlay}`,
                },
                onsuccess,
            },
        }),
        notify: message => ({
            type: DISPLAY_NOTIFICATION,
            message,
        }),
    },
)(withRouter(Outlay))

export const route = () =>
    <Route
        path={["/account", "/account/credit", "/account/bills", "/account/security", "/account/profile", "/account/outlay"]}>
        <ConnectedBillLoader>
            <Component>
                <Switch>
                    <Route
                        exact
                        path={["/account/edit", "/account/confirm/:key"]}>
                        <Edit />
                    </Route>
                    <Route
                        exact
                        path={"/account/password"}>
                        <Password />
                    </Route>
                    <Route
                        exact
                        path={["/account/bill/:bill"]}>
                        <Bill />
                    </Route>
                    <Route
                        exact
                        path={["/account/outlay/:outlay"]}>
                        <ConnectedOutlay />
                    </Route>
                </Switch>
            </Component>
        </ConnectedBillLoader>
    </Route>
